import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Byline from '../templates/work/Byline'
import Quote from '../templates/work/Quote'
import Gallery from '../templates/work/Gallery'

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const frontmatter = data.markdownRemark.frontmatter
        
    return (
      <Layout>
        <div className="container content">
          <Byline byline={frontmatter.byline}/>
          <Quote quote={frontmatter.quote}/>
          <Gallery posts={posts}/>
        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
query IndexQuery {
  markdownRemark(frontmatter: {templateKey: {eq: "../pages/index"}}) {
    frontmatter {
      title
      byline
      quote
    }
  }
  allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "projects/index"}}}) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          templateKey
          date(formatString: "MMMM DD, YYYY")
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 64) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 64) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
}
`
