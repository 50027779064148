import React from 'react'
import { throttle } from 'lodash'
import Sprite from './sprite'
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage'
import BezierEasing from './easing'

class Loader extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();

    this.state = {
      scale: 1,
      cols: 7,
      rows: 15,
      x: Math.floor(Math.random() * 7),
      y: Math.floor(Math.random() * 15),
      isCorner: false,
      isMobile: false,
      pos: 0,
      lastAdvance: 0,
      lastScrollTop: 0,
      hasEnded: false
    }
  }

  componentDidMount() {  
    window.addEventListener('resize', throttle(this.sizeFrame, 50))
    window.addEventListener('resize', this.isMobile())
    window.addEventListener('scroll', this.handleScroll)
    // window.addEventListener('scrollDown', this.handleScroll)
    // window.addEventListener('login', this.animateScrollDown)
    
    this.isMobile()
    
    const pageWidth = document.documentElement.clientWidth
    if(pageWidth < 768) {
      this.setState({
        isMobile: true
      })
    }
    
    // this.animateOnLinkHover()
    
    this.startAnimation(false)
    
    this.setState({
      lastScrollTop: window.pageYOffset
    }, () => {
      this.sizeFrame()
    })    
  }
  
  isMobile = () => {
    const pageWidth = document.documentElement.clientWidth
    
    if(pageWidth < 1025) {
      this.setState({
        isMobile: true
      })
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', throttle(this.sizeFrame, 50))
    window.removeEventListener('scroll', this.handleScroll)
    this.removeAnimateOnLinkHover()
    clearInterval(this.animationTimer)
    clearTimeout(this.animationTimeout)
  }
  
  scrollDown = (element, to, duration) => {
    const pageWidth = document.documentElement.clientWidth
    if(this.state.isMobile || !this.props.isFirstLoad) return

    const start = element.scrollTop
    const change = to - start
    const increment = 20
  
    if(change < 250) return
  
    const animateScroll = (elapsedTime) => {
      elapsedTime += increment
      const position = this.ease(elapsedTime, start, change, duration)

      window.scrollTo(0, position)
      // element.scrollTop = position
      if (elapsedTime < duration) {
        setTimeout(() => {
          animateScroll(elapsedTime)
        }, increment)
      }
    }

    animateScroll(0)
  }
  
  ease = (currentTime, start, change, duration) => {
    const easing = BezierEasing(0.35, 0, 0.17, 1)
    currentTime /= duration
        
    return easing(currentTime) * change
  }
  
  animateOnLinkHover = () => {
    const links = document.querySelectorAll('.animate-hover')
    
    for(let i = 0; i < links.length; i++) {
      links[i].addEventListener('mouseover', () => this.startAnimation(true))
    }
  }
  
  removeAnimateOnLinkHover = () => {
    const links = document.querySelectorAll('.animate-hover')
    
    for(let i = 0; i < links.length; i++) {
      links[i].removeEventListener('mouseover', () => this.startAnimation(true))
    }
  }
  
  startAnimation = (hover) => {
    let intervalCount = 0
    clearInterval(this.animationTimer)
    
    let timer = setInterval(() => {
      intervalCount++
      this.advanceFrame()
      
      this.checkAnimationProgress(intervalCount, hover, timer)
    }, 200);
  }
  
  checkAnimationProgress = (intervalCount, hover, timer) => {
    let animationLength = 10
    if(hover) animationLength = 5
    
    if(this.props.isFirstLoad === false && !this.state.hasEnded) {
      this.endAnimation()
    }
    
    if(intervalCount === animationLength) {
      clearInterval(timer)
      
      if(!hover) {
        if(!this.state.hasEnded) {
          this.endAnimation()
        }
        this.setState({hideLoader: false})
        // if(this.isLoggedIn()) this.animateScrollDown()
        this.scrollDown(document.documentElement, 250, 500)
      }
      
      this.animationTimeout = setTimeout(() => {
        this.setState({
          hideLoader: false
        })
      }, 500)
    }
  }

  // isLoggedIn = () => {
  //   return sessionStorage.getItem('authenticated')
  // }
  
  // animateScrollDown = () => {
  //   this.scrollDown(document.documentElement, 250, 500)
  // }
  
  endAnimation = () => {
    this.props.onAnimationEnd()
    
    this.setState({
      hasEnded: true
    })
    
    if(this.state.isMobile && this.state.hasEnded) {
      this.props.onCorner(true)  
    }
  }
  
  handleScroll = (evt) => {
    const scrollTop = window.pageYOffset
    const pageHeight = window.innerHeight
    const isCorner = scrollTop > pageHeight || (this.state.isMobile && this.state.hasEnded)
      
    this.setState({
      lastScrollTop: scrollTop,
      isCorner: isCorner,
      preCorner: true
    })
    
    this.props.onCorner(isCorner)
    
    let reverse = scrollTop < this.state.lastScrollTop
    this.advanceFrame(reverse, true)
  }
  
  advanceFrame = (reverse, throttle) => {
    const currentTime = Date.now()
    if(currentTime - this.state.lastAdvance < 200 && throttle) return
    
    let x = this.state.x
    let y = this.state.y
    let cols = this.state.cols
    let rows = this.state.rows
     
    if(reverse) {
      if(x > 0) {
        x = x - 1
      } else if (x === 0) {
        x = cols

        if(y > 0) {
          y = y - 1
        } else if (y === 0) {
          y = rows
        }
      }  
    } else {
      if(x < cols) {
        x++
      } else if (x === cols) {
        x = 0

        if(y < rows) {
          y++
        } else if (y === rows) {
          y = 0
        }
      }
    }
    
    this.setState({
      x: x,
      y: y,
      lastAdvance: currentTime
    })
  }
  
  
  sizeFrame = () => {
    const el = document.querySelector('#loader_wrapper').getBoundingClientRect()
    const width = el.width
    const height = width * 0.625
    const elHeight = el.height
          
    this.setState({scale: elHeight / height})
  }
  
  render() {
    const isCorner = this.state.isCorner || !this.props.isFirstLoad
    const loaderClass = isCorner ? 'corner animate-hover' : ''
    
    return ([
      <div id="header_image_wrapper" key="header">
        {this.props.headerImage && !isCorner ?
          <PreviewCompatibleImage imageInfo={this.props.headerImage} />
        : '' }
      </div>,
      <div id="loader_wrapper"
        className={loaderClass}
        key="loader"
      >  
        <div id="loader_logo">
          <span className="logo-studio">Studio</span>
          <span className="logo-louise">LOUISE</span>
        </div>
        
        <Sprite 
          corner={isCorner}
          scale={this.state.scale}
          x={this.state.x}
          y={this.state.y}
          cols={this.state.cols}
          rows={this.state.rows}
        />
      </div>
    ]);
  }
}

export default Loader