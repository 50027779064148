import React from 'react'
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class MasonryImage extends React.Component {
  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef()
    this.itemRef = React.createRef()
    
    this.state = {
      width: 'half',
      size: 's'
    }
  }
  
  componentDidMount() {
    const random = Math.random()
    let width, size
    const image = this.props.image
    
    if (!!image && !!image.childImageSharp) {    
      let aspectRatio = parseFloat(image.childImageSharp.fluid.aspectRatio)
          
      switch(true) { 
        case (random < .2 || aspectRatio > 1): {
          width = 'full'
          break
        } 
        case (random < .6): {
          size = 's'
          break
        } 
        case (random < .8): {
          size = 'm'
          break 
        } 
        default: { 
          size = 'l'
          break
        }
      }
      const randomYs = ['1.25vw', '2.5vw', '5vw', '7.5vw']
      const randomXs = ['2.5vw', '5vw',  '7.5vw']
      
      const randomY = randomYs[Math.floor(Math.random() * randomYs.length)]
      const randomX = randomXs[Math.floor(Math.random() * randomXs.length)]
          
      const float = Math.random() < .5 ? 'left' : 'right'
      
      this.setState({
        width: width || this.state.width,
        size: size || this.state.size,
        randomX: randomX,
        randomY: randomY,
        random: Math.random(),
        float: float
      })
    }
  }

  render() {
    return (
      <div
        className={`grid-item ${this.state.width} ${this.state.size}`}
        ref={this.itemRef}
      >
        <div 
          className='wrapper'
          ref={this.wrapperRef}
          style={{
            float: this.state.float,
            marginTop: this.props.isFirst ? '3rem' : this.state.randomX,
            marginBottom: this.state.randomY
          }}
        >

        {this.props.image ?
          (this.props.slug ? 
            <Link to={this.props.slug}>
              <PreviewCompatibleImage imageInfo={this.props.image} />
            </Link> 
          : 
            <PreviewCompatibleImage imageInfo={this.props.image} />
          ) : '' }

          {this.props.title ?
            <div className="work-meta">
              <Link to={this.props.slug}>
                <span className="work-title">{this.props.title}</span> 
              </Link>
                
              {this.props.length > 1 ? <span className="work-image-count">{this.props.length + ' images'}</span> : ''}
            </div>
          : "" }
        </div>
      </div>
    )
  }
}

export default MasonryImage
