import React from 'react'

const Quote = (props) => (
  <div id="quote">
    <div className="quote-inner">
      {props.quote}
    </div>
  </div>
)

export default Quote
