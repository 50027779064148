import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"

import Loader from '../templates/loader'

import Navbar from '../components/Navbar'
import './all.scss'

export default class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {loaded: false}
  }
  
  componentDidMount() {
    const currentPath = globalHistory.location.pathname
    
    this.setState({
      isFirstLoad: (window.locations === undefined && currentPath === '/')
    })
  }
  
  componentWillUnmount() {
    document.body.classList.remove('loaded')
  }

  handleLoad = () => {
    document.body.classList.add('loaded')
  }
  
  handleCorner = (isCorner) => {
    this.setState({
      isCorner: isCorner
    })
  }
  
  handleAnimationEnd = () => {
    this.setState({loaded: true}, this.handleLoad)
  }
  
  render() {
    const firstLoadClass = this.state.isFirstLoad ? 'first-load' : ''
    
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
              site {
                siteMetadata {
                  title,
                  description,
                }
              }
              markdownRemark(frontmatter: {templateKey: {eq: "../pages/index"}}) {
                frontmatter {
                  header {
                    childImageSharp {
                      fluid(maxWidth: 1600, quality: 64) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
        `}
        render={data => (
          <div>
            <Helmet>
              <html lang="en" />
              <title>{data.site.siteMetadata.title}</title>
              <meta name="description" content={data.site.siteMetadata.description} />
              
              <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
              <link rel="icon" type="image/png" href="/img/favicon-32x32.png" sizes="32x32" />
              <link rel="icon" type="image/png" href="/img/favicon-16x16.png" sizes="16x16" />
      
              <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
              <meta name="theme-color" content="#fff" />

              <meta property="og:type" content="business.business" />
              <meta property="og:title" content={data.site.siteMetadata.title} />
              <meta property="og:url" content="/" />
              <meta property="og:image" content="/img/og-image.jpg" />
            </Helmet>
            <Navbar
              visible={this.state.isCorner || !this.state.isFirstLoad}
              isFirstLoad={this.state.isFirstLoad}
            />
            <Loader 
              preAnimation={this.handlePreAnimation}
              onAnimationEnd={this.handleAnimationEnd}
              onCorner={this.handleCorner}
              isCorner={this.state.loaded}
              headerImage={data.markdownRemark.frontmatter.header}
              isFirstLoad={this.state.isFirstLoad}
            />
          
          <div className={`container-wrapper ${firstLoadClass}`}>
              {this.props.children}
            </div>
          </div>
        )}
      />
    )
  }
}