import React from 'react'

const Sprite = (props) => {
  const spriteStyle = {
    fill: 'none',
    stroke: props.corner ? '#000' : '#fff',
    strokeMiterlimit: 10,
    strokeWidth: props.corner ? '1px' : '2px',
    vectorEffect: 'non-scaling-stroke'
  }

  const scale = props.corner ? 1 : props.scale
  
  return (
  <svg id="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10240 12800" style={{overflow: 'visible'}}>
    <g 
      id="spriteWrapper"
      style={{
        transform: `scaleY(${scale}) translate(${props.x / (props.cols + 1) * -100}%, ${props.y / (props.rows + 1) * -100}%)`
      }}
    >
    <path
      style={spriteStyle}
      d="M7479.2258,6434s-22.5308,195.858-22.2227,210c1.1112,51,15.8081,200.2262,14.9969,207-2.7465,22.9348.4917,141.1931-2.7585,164-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,49.4414-5,51.7334-5,.3407,0,45.02,5.0175,64.7865,6l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78C7630.19,7074,7590,6863,7590,6863c2.2223-36,14.7842-401,18.1176-429Z"/><path
      style={spriteStyle}
      d="M3639.2258,7234s-22.5308,195.858-22.2227,210c1.1112,51,15.8081,200.2262,14.9969,207-2.7465,22.9348.4917,141.1931-2.7585,164-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,49.4414-5,51.7334-5,.3407,0,45.02,5.0175,64.7865,6l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78C3790.19,7874,3750,7663,3750,7663c2.2223-36,14.7842-401,18.1176-429Z"/><path
      style={spriteStyle}
      d="M493.1831,7948.3608s-75.8358,17.0872-166.4518,15c-89.8878-2.0872-277.5454-8.35-290.0666-12C31.2341,7949.5619,34.8919,7929,41.0115,7916c0,0,74.879-84.7432,96.1334-96.6392C155.34,7809.1773,152.1459,7775,152.1459,7775s-32.7379-108.9578-37.0425-114c-11.54-13.5181-65.2645-58.9036-66.0827-65-4.42-32.9227-23.0264-58-10.0115-88,3.6717-48-3.0035-116-3.0035-116L31,7299c17.02-34,74.1782-31.1587,109.4768-47.6392,34.2695-16,66.1783-15.67,100.3607-17,34.0287-1.324,36.1665,8.1979,58.9413,3.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,114.9474-7.3236,147.8262-3.1877,36.4213,4.5815,421.3664-6.6132,448.63,2.5221,0,0,31.0356-3,39.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-119.2606,116.3608-138.8432,121.3608-14.723,3.7592-30.1668,44.8494-29.7067,55,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287q8.2877,11.3746,14.3653,43.1174c4.0395,21.1654,7.3034,119.2963,7.3034,160.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0865,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-9.098-73.8614-8.8253q-9.9453.3933-19.89.3881C522.93,7949,493.1831,7948.3608,493.1831,7948.3608Z"/><path
      style={spriteStyle}
      d="M1089.2258,7238S1057.6919,7480.858,1058,7495c1.1111,51,14.8112,133.2262,14,140-2.7465,22.9348.4917,157.1931-2.7585,180-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,49.4414,3,51.7334,3,.3407,0,45.02-2.9825,64.7865-2l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78-5.7482-7-47.8209-172-47.8209-172,2.2222-36,16.667-447,20-475Z"/><path
      style={spriteStyle}
      d="M1773.1831,7946.3608s-75.8358,19.0872-166.4518,17c-89.8878-2.0872-277.5454-8.35-290.0666-12-5.4306-1.7989-1.7728-22.3608,4.3468-35.3608,0,0,80.879-84.7432,102.1334-96.6392,18.1947-10.1835,15.001-44.3608,15.001-44.3608s-26.7379-108.9578-31.0425-114c-11.5405-13.5181-77.2645-58.9036-78.0827-65-4.42-32.9227-23.0264-58-10.0115-88,3.6717-48-3.0035-116-3.0035-116L1311,7299c17.02-34,74.1782-29.1587,109.4768-45.6392,34.27-16,66.1783-15.67,100.3607-17,34.0287-1.324,36.1665,12.1979,58.9413,7.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,114.9474-7.3236,147.8262-3.1877,36.4213,4.5815,403.3664-16.6132,430.63-7.4779,0,0,39.0356-3,47.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-109.2606,120.3608-128.8432,125.3608-14.723,3.7592-30.1668,44.8494-29.7067,55,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287,5.5251,7.5831,10.3074,33.952,14.3653,55.1174,4.0395,21.1654,7.3034,107.2963,7.3034,148.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0865,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-11.098-73.8614-10.8253q-9.9453.3933-19.89.3881C1802.93,7947,1773.1831,7946.3608,1773.1831,7946.3608Z"/><path
      style={spriteStyle}
      d="M2353.2258,7234s-16.5308,195.858-16.2227,210c1.1112,51,15.8081,163.2262,14.9969,170-2.7465,22.9348.4917,178.1931-2.7585,201-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,49.4414,3,51.7334,3,.3407,0,45.02-2.9825,64.7865-2l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78C2510.19,7874,2470,7656,2470,7656c2.2223-36,6.7842-394,10.1176-422Z"/><path
      style={spriteStyle}
      d="M4333.1831,7956.3608a1311.2517,1311.2517,0,0,1-166.4518,7c-89.8878-2.0872-277.5454-8.35-290.0666-12-5.4306-1.7989-1.7728-22.3608,4.3468-35.3608,0,0,62.879-84.7432,84.1334-96.6392,18.1947-10.1835,15.001-44.3608,15.001-44.3608s-14.7379-108.9578-19.0425-114c-11.5405-13.5181-71.2645-58.9036-72.0827-65-4.42-32.9227-23.0264-58-10.0115-88,3.6717-48-3.0035-116-3.0035-116L3871,7299c17.0195-34,74.1782-31.1587,109.4768-47.6392,34.2695-16,66.1783-15.67,100.3607-17,34.0287-1.324,36.1665,8.1979,58.9413,3.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,114.9474-7.3236,147.8262-3.1877,36.4213,4.5815,377.3664-11.6132,404.63-2.4779,0,0,31.0356-3,39.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-75.2606,121.3608-94.8432,126.3608-14.723,3.7592-30.1668,44.8494-29.7067,55,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287q8.2877,11.3746,14.3653,43.1174c4.0395,21.1654,7.3034,119.2963,7.3034,160.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0864,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-1.098-73.8614-.8253q-9.9453.3933-19.8905.3881C4362.93,7957,4333.1831,7956.3608,4333.1831,7956.3608Z"/><path
      style={spriteStyle}
      d="M4889.2258,7233s-22.5308,195.858-22.2227,210c1.1112,51,16.6422,123.2262,15.831,130-2.7465,22.9348-.3424,218.1931-3.5926,241-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,79.4414,4,81.7334,4,.3407,0,45.02-2.9825,64.7865-2l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78-5.7482-7-47.8209-172-47.8209-172,2.2222-36,16.667-447,20-475l-61.1473-.3848-34.7089,2.4944a201.2916,201.2916,0,0,1-32.9079-.3321Z"/><path
      style={spriteStyle}
      d="M9422,7150s-44.6527,15.448-135.2687,13.3608c-89.8878-2.0872-277.5454-8.35-290.0666-12-5.4306-1.7989-1.7728-22.3608,4.3468-35.3608,0,0,72.879-84.7432,94.1334-96.6392,18.1947-10.1835,15.001-44.3608,15.001-44.3608s-32.7379-122.9578-37.0425-128c-11.54-13.5181-63.2645-58.9036-64.0827-65-4.42-32.9227-23.0264-58-10.0115-88,3.6717-48-4.0092-131-4.0092-131l-4-76c17.02-34,38.7014-15.52,74-32,34.27-16,101.6551-17.3092,135.8375-18.6392,34.0287-1.324,36.1665,8.1979,58.9413,3.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,200.4675-.8015,233.3464,3.3344,36.4213,4.5815,325.8462-19.1353,353.11-10,0,0,31.0356-3,39.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-65.468,82.6392-97.1556,104-12.6,8.4936-61.8544,66.21-61.3943,76.3608,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287q8.2876,11.3746,14.3653,43.1174c4.04,21.1654,7.3034,119.2963,7.3034,160.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0865,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-1.098-73.8614-.8253q-9.9453.3933-19.89.3881C9482.93,7157,9422,7150,9422,7150Z"/><path
      style={spriteStyle}
      d="M10039.2258,6434s-22.5308,195.858-22.2227,210c1.1112,51-4.1919,170.2262-5.0031,177-2.7465,22.9348,20.4917,171.1931,17.2415,194-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,49.4414,3,51.7334,3,.3407,0,45.02-2.9825,64.7865-2l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78-5.7482-7-42.9381-281-42.9381-281,2.2223-36,11.7842-338,15.1176-366Z"/><path
      style={spriteStyle}
      d="M6893.1831,7156.3608a1311.2517,1311.2517,0,0,1-166.4518,7c-89.8878-2.0872-277.5454-8.35-290.0666-12-5.4306-1.7989-1.7728-22.3608,4.3468-35.3608,0,0,72.879-84.7432,94.1334-96.6392,18.1947-10.1835,15.001-44.3608,15.001-44.3608s-32.7379-108.9578-37.0425-114c-11.5405-13.5181-63.2645-58.9036-64.0827-65-4.42-32.9227-23.0264-58-10.0115-88,3.6717-48-3.0035-116-3.0035-116L6431,6487c17.02-34,74.1782-31.1587,109.4768-47.6392,34.2695-16,66.1783-3.67,100.3607-5,34.0287-1.324,36.1665,8.1979,58.9413,3.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,114.9474-7.3236,147.8262-3.1877,36.4213,4.5815,411.3664-10.6132,438.63-1.4779,0,0,31.0356-3,39.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-97.1556,104-128.8432,125.3608-12.6,8.4936-30.1668,44.8494-29.7067,55,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287q8.2877,11.3746,14.3653,43.1174c4.0395,21.1654,7.3034,119.2963,7.3034,160.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0864,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-1.098-73.8614-.8253q-9.9453.3933-19.8905.3881C6922.93,7157,6893.1831,7156.3608,6893.1831,7156.3608Z"/><path
      style={spriteStyle}
      d="M5613.1831,7156.3608a1311.2517,1311.2517,0,0,1-166.4518,7c-89.8878-2.0872-277.5454-8.35-290.0666-12-5.4306-1.7989-1.7728-22.3608,4.3468-35.3608,0,0,80.879-84.7432,102.1334-96.6392,18.1947-10.1835,15.001-44.3608,15.001-44.3608s-32.7379-108.9578-37.0425-114c-11.5405-13.5181-71.2645-58.9036-72.0827-65-4.42-32.9227-23.0264-58-10.0115-88,3.6717-48-3.0035-116-3.0035-116L5151,6499c17.02-34,74.1782-31.1587,109.4768-47.6392,34.2695-16,66.1783-15.67,100.3607-17,34.0287-1.324,36.1665,8.1979,58.9413,3.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,114.9474-7.3236,147.8262-3.1877,36.4213,4.5815,411.3664-10.6132,438.63-1.4779,0,0,31.0356-3,39.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-109.2606,120.3608-128.8432,125.3608-14.723,3.7592-30.1668,44.8494-29.7067,55,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287q8.2877,11.3746,14.3653,43.1174c4.0395,21.1654,7.3034,119.2963,7.3034,160.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0864,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-1.098-73.8614-.8253q-9.9453.3933-19.8905.3881C5642.93,7157,5613.1831,7156.3608,5613.1831,7156.3608Z"/><path
      style={spriteStyle}
      d="M6199.2258,6434s-22.5308,195.858-22.2227,210c1.1112,51,16.6422,123.2262,15.831,130-2.7465,22.9348-.3424,218.1931-3.5926,241-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,49.4414,3,51.7334,3,.3407,0,45.02-2.9825,64.7865-2l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78-5.7482-7-47.8209-172-47.8209-172,2.2222-36,16.667-447,20-475Z"/><path
      style={spriteStyle}
      d="M3053.1831,7956.3608a1311.2517,1311.2517,0,0,1-166.4518,7c-89.8878-2.0872-277.5454-8.35-290.0666-12-5.4306-1.7989-1.7728-22.3608,4.3468-35.3608,0,0,80.879-84.7432,102.1334-96.6392,18.1947-10.1835,15.001-44.3608,15.001-44.3608s-32.7379-108.9578-37.0425-114c-11.5405-13.5181-71.2645-58.9036-72.0827-65-4.42-32.9227-23.0264-58-10.0115-88,3.6717-48-3.0035-116-3.0035-116L2591,7299c17.0195-34,74.1782-31.1587,109.4768-47.6392,34.2695-16,66.1783-15.67,100.3607-17,34.0287-1.324,36.1665,8.1979,58.9413,3.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,114.9474-7.3236,147.8262-3.1877,36.4213,4.5815,411.3664-10.6132,438.63-1.4779,0,0,31.0356-3,39.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-109.2606,120.3608-128.8432,125.3608-14.723,3.7592-30.1668,44.8494-29.7067,55,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287q8.2877,11.3746,14.3653,43.1174c4.0395,21.1654,7.3034,119.2963,7.3034,160.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0865,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-1.098-73.8614-.8253q-9.9453.3933-19.89.3881C3082.93,7957,3053.1831,7956.3608,3053.1831,7956.3608Z"/><path
      style={spriteStyle}
      d="M4333.1831,7156.3608a1311.2517,1311.2517,0,0,1-166.4518,7c-89.8878-2.0872-277.5454-8.35-290.0666-12-5.4306-1.7989-1.7728-22.3608,4.3468-35.3608,0,0,80.879-84.7432,102.1334-96.6392,18.1947-10.1835,15.001-44.3608,15.001-44.3608s-32.7379-108.9578-37.0425-114c-11.5405-13.5181-71.2645-58.9036-72.0827-65-4.42-32.9227-23.0264-58-10.0115-88,3.6717-48-3.0035-116-3.0035-116L3871,6499c17.0195-34,74.1782-31.1587,109.4768-47.6392,34.2695-16,66.1783-15.67,100.3607-17,34.0287-1.324,36.1665,8.1979,58.9413,3.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,114.9474-7.3236,147.8262-3.1877,36.4213,4.5815,411.3664-10.6132,438.63-1.4779,0,0,31.0356-3,39.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-109.2606,120.3608-128.8432,125.3608-14.723,3.7592-30.1668,44.8494-29.7067,55,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287q8.2877,11.3746,14.3653,43.1174c4.0395,21.1654,7.3034,119.2963,7.3034,160.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0864,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-1.098-73.8614-.8253q-9.9453.3933-19.8905.3881C4362.93,7157,4333.1831,7156.3608,4333.1831,7156.3608Z"/><path
      style={spriteStyle}
      d="M4919.2258,6434s-22.5308,195.858-22.2227,210c1.1112,51,16.6422,123.2262,15.831,130-2.7465,22.9348-.3424,218.1931-3.5926,241-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,49.4414,3,51.7334,3,.3407,0,45.02-2.9825,64.7865-2l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78-5.7482-7-47.8209-172-47.8209-172,2.2222-36,16.667-447,20-475Z"/><path
      style={spriteStyle}
      d="M8173.1831,7148.3608s-75.8358,17.0872-166.4518,15c-89.8878-2.0872-277.5454-8.35-290.0666-12-5.4306-1.7989,4.2272-28.3608,10.3468-41.3608,0,0,74.879-78.7432,96.1334-90.6392,18.1947-10.1835,15.001-44.3608,15.001-44.3608s-32.7379-108.9578-37.0425-114c-11.5405-13.5181-71.2645-58.9036-72.0827-65-4.42-32.9227-17.0264-58-4.0115-88,3.6717-48-3.0035-116-3.0035-116L7717,6499c17.02-34,74.1782-31.1587,109.4768-47.6392,34.2695-16,60.1783-15.67,94.3607-17,34.0287-1.324,36.1665,8.1979,58.9413,3.57,38.1717-7.756,47.775-11.9307,111.8748-4.2655,76.4671,9.1442,114.9474-7.3236,147.8262-3.1877,36.4213,4.5815,411.3664-10.6132,438.63-1.4779,0,0,31.0356-3,39.0448,10,8.9867,14.5866-.8467,44.5724,1.0011,66,1.8331,21.4381-98.573,97-118.1556,102-14.723,3.7592-40.8544,68.21-40.3943,78.3608,0,0,24.1053,20.2375,37.6083,32,15.4931,13.496,31.9369,23.8487,33.716,26.287q8.2876,11.3746,14.3653,43.1174c4.04,21.1654,7.3034,119.2963,7.3034,160.0643,0,6.272.17,13.0684.5507,20.3892q.5433,10.9813,1.6594,21.952,1.0865,14.8986,2.21,29.0106v63.4961c0,18.3021-7.0608,60.0792-12.9482,71.3226,0,0-47.81.2419-61.07,6-13.2641,5.7476-218.55-3.136-242.1175-1.5628-5.9058.514-67.2312-1.098-73.8614-.8253q-9.9453.3933-19.89.3881C8202.93,7157,8173.1831,7148.3608,8173.1831,7148.3608Z"/><path
      style={spriteStyle}
      d="M8759.2258,6434s-22.5308,195.858-22.2227,210c1.1112,51,15.8081,200.2262,14.9969,207-2.7465,22.9348.4917,141.1931-2.7585,164-.4193,2.9425-5.375,141.3911-4.3111,144,1.0876,2.6673,49.4414-5,51.7334-5,.3407,0,45.02,5.0175,64.7865,6l53.0507-1c4.4519-36.856,1.4371-78,1.4371-78C8910.19,7074,8870,6863,8870,6863c2.2223-36,14.7842-401,18.1176-429Z"/><path
      style={spriteStyle}
      d="M7925.6042,7956.3608a284.5873,284.5873,0,0,1-76.8539,7c-41.5028-2.0872-128.1478-8.35-133.929-12-2.5075-1.7989-.8186-22.3608,2.0069-35.3608,0,0,33.9822-67.1041,43.7957-79,8.4008-10.1835,17.1342-78,17.1342-78s-38.579-100.9578-40.5665-106c-5.3285-13.5181-16.2876-50.9036-16.6654-57-2.0408-32.9227-10.6317-58-4.6225-88,1.6953-48-1.3867-116-1.3867-116l-.1672-138c7.8583-34,23.0018-14.1564,41.2734-20,18.7606-6,37.6857,1.6908,53.4684.3608,15.7117-1.324,131.9422-8.0188,147.123-3.8829,16.8164,4.5815,131.854-10.6132,144.442-1.4779,0,0,14.33-3,18.0277,10,4.1494,14.5866-.3909,44.5724.4623,66,.8464,21.4381-28.4534,61-32.81,80-2.2059,9.6213-7.1,60-4.9222,82,0,0,20.4109,64.5983,26.6455,76.3608,7.1534,13.496,14.7458,23.8487,15.5673,26.287q3.8265,11.3746,6.6327,43.1174c1.8651,21.1654,16.6988,93.2963,16.6988,134.0643q0,9.408.2542,20.3892.2508,10.9813.7662,21.952.5016,14.8986,1.02,29.0106v63.4961c0,18.3021-3.26,60.0792-5.9784,71.3226,0,0-22.0747.2419-28.1972,6-6.1242,5.7476-100.9084-3.136-111.79-1.5628-2.7268.514-40.2257-.4372-43.2869-.4372C7939.3389,7957,7925.6042,7956.3608,7925.6042,7956.3608Z"/><path
      style={spriteStyle}
      d="M8196.1535,7234s-8.5969,193.6651-7.35,207.8654c4.4968,51.21,4.86,129.86,4.0029,137.1346-5.1355,43.5989-1.2893,213.0991-14.4428,236-1.697,2.9546-10.4952,146.38-6.19,149,4.4017,2.6783,248.8008-2,258.0766-2,1.3787,0,182.1977-2.9948,262.1914-2.0082l214.6962-1.0042c18.0169-37.0078,5.02-169.9876,5.02-169.9876C8888.895,7781.9712,8762,7642,8762,7642c6.3608-25.5664-23.733-198.0508-25-305-.4027-33.9913-4.9041-78.2822-6.9721-97.0122a10.0513,10.0513,0,0,0-10.0418-8.9457l-98.5927.46-171.9516,6.2327a602.8262,602.8262,0,0,1-68.8744-.3542Z"/><path
      style={spriteStyle}
      d="M6725.61,7956.3608a534.1569,534.1569,0,0,1-105.8567,7c-57.1651-2.0872-176.5079-8.35-184.4709-12-3.4536-1.7989-1.1274-22.3608,2.7644-35.3608,0,0,28.5486-92.7432,42.0655-104.6392,11.5711-10.1835,9.54-44.3608,9.54-44.3608s-20.82-108.9578-23.5576-114c-7.3393-13.5181-22.4341-50.9036-22.9545-57-2.811-32.9227-14.6439-58-6.3669-88,2.3351-48-1.91-116-1.91-116l-.23-138c10.8237-34,31.6821-14.1564,56.849-20,25.84-6,51.9075,1.6908,73.6461.3608,21.6409-1.324,181.7342-8.0188,202.6439-3.8829,23.1625,4.5815,181.6127-10.6132,198.9512-1.4779,0,0,19.7374-3,24.8309,10,5.7152,14.5866-.5385,44.5724.6367,66,1.1658,21.4381-39.1911,61-45.1911,80-3.0383,9.6213,9,74,12,96,0,0,27.69,24.5983,36.277,36.3608,9.853,13.496,20.3106,23.8487,21.442,26.287q5.2707,11.3746,9.1358,43.1174c2.569,21.1654,4.6447,119.2963,4.6447,160.0643q0,9.408.35,20.3892.3454,10.9813,1.0553,21.952.6911,14.8986,1.4055,29.0106v63.4961c0,18.3021-4.49,60.0792-8.2345,71.3226,0,0-30.4051.2419-38.8382,6-8.4354,5.7476-138.9889-3.136-153.9771-1.5628-3.7558.514-55.4059-.4372-59.6224-.4372C6744.5281,7957,6725.61,7956.3608,6725.61,7956.3608Z"/><path
      style={spriteStyle}
      d="M7049.9993,7234s-7,193.6651-5.9846,207.8654c3.6614,51.21,52.8645,123.261,52.1668,130.5357C7092,7616,7095.0532,7791.4933,7084.343,7814.3942c-1.3817,2.9546-17.7118,141.9738-14.2061,144.5934,3.584,2.6783,162.9212,3.0124,170.474,3.0124,1.1225,0,148.3529-2.9948,213.4871-2.0082l174.8147-1.0042C7643.5828,7921.98,7633,7789,7633,7789c-18.9416-7.0288-140-170-140-170,6.8292-33.7113-8.6323-317.54-1.8391-378.461a8.5267,8.5267,0,0,0-8.5162-9.4853l-86.3972.4484-140.01,6.2327a399.8413,399.8413,0,0,1-56.08-.3542Z"/><path
      style={spriteStyle}
      d="M5549.1181,7956.3608a974.5889,974.5889,0,0,1-143.3792,7c-77.4282-2.0872-239.0738-8.35-249.8594-12-4.6778-1.7989-1.527-22.3608,3.7443-35.3608,0,0,38.668-92.7432,56.9762-104.6392,15.6727-10.1835,12.9217-44.3608,12.9217-44.3608s-28.2-108.9578-31.9079-114c-9.9408-13.5181-30.3862-50.9036-31.091-57-3.8075-32.9227-19.8347-58-8.6238-88,3.1628-48-2.5871-116-2.5871-116L5155,7254c14.66-34,42.9123-14.1564,77-20,35-6,70.3069,1.6908,99.7511.3608,29.3119-1.324,31.1534,8.1979,50.7712,3.57,32.8806-7.756,41.1528-11.9307,96.3674-4.2655,65.8677,9.1442,99.0141-7.3236,127.3355-3.1877,31.3728,4.5815,354.3453-10.6132,377.83-1.4779,0,0,26.7336-3,33.6326,10,7.741,14.5866-.7293,44.5724.8624,66,1.579,21.4381-94.1156,120.3608-110.9838,125.3608-12.6821,3.7592-25.9853,44.8494-25.5889,55,0,0,20.7639,20.2375,32.3953,32,13.3455,13.496,27.51,23.8487,29.0424,26.287q7.139,11.3746,12.3741,43.1174c3.48,21.1654,6.2911,119.2963,6.2911,160.0643,0,6.272.1464,13.0684.4743,20.3892q.468,10.9813,1.4294,21.952.936,14.8986,1.9037,29.0106v63.4961c0,18.3021-6.0821,60.0792-11.1534,71.3226,0,0-41.1826.2419-52.6049,6-11.4254,5.7476-188.2557-3.136-208.5566-1.5628-5.0872.514-57.9121-1.098-63.6232-.8253q-8.5667.3933-17.1334.3881C5574.7418,7957,5549.1181,7956.3608,5549.1181,7956.3608Z"/><path
      style={spriteStyle}
      d="M6070,7234s-27.5321,195.858-26.9945,210c1.9386,51,29.0357,123.2262,27.62,130-4.7919,22.9348-.5974,218.1931-6.268,241-.7316,2.9425-9.3778,141.3911-7.5217,144,1.8977,2.6673,86.2609,3,90.26,3,.5944,0,78.5476-2.9825,113.0338-2l92.558-1c7.7673-36.856,2.5072-78,2.5072-78-10.0288-7-83.4336-172-83.4336-172,3.678-34.15,26.5447-405.738,33.85-467.7492a6.443,6.443,0,0,0-6.4395-7.2023L6229.5,7234.5l-74.13,6.2072a112.9163,112.9163,0,0,1-29.6926-.3528Z"/><path
      style={spriteStyle}
      d="M10114.8779,7618.0556s81.7886,101.648,82.0676,111.5356L10203.6447,7967H10131l-153.0739-18.3031a145.63,145.63,0,0,0-41.4794,2.586L9876,7959l-613.2444-2c-3.84,0-16.2074-174.4858-16.2074-174.4858L9251,7435l4.26-97.9887L9251,7227h563.18c31.1625,0,124.65,5.0136,124.65,5.0136l72.8672,2.5271a6.147,6.147,0,0,1,5.5974,6.0035L10018,7281l18.0585,84.7937Z"/><path
      style={spriteStyle}
      d="M9161,7228c-20-6-33.4294,1.7077-69,3-5.5076.2-50.4186-4.129-55.6567.0774C9025.8139,7239.5331,8998.9148,7235,8997,7243c-6.038,25.2262-2.4369,113.7249-3.1748,141-.3015,11.1444,14.39,59,10.1748,129-.4659,7.7365,22.4434,133.5881,24.8522,139,7.7773,17.4735,18.1478,63,18.1478,63l5,44c-3,26-32.29,53-40,65,0,0-24,11-22.9965,22,.9994,10.9545.7831,19.91-.4142,52.24S8988,7956,8988,7956s29.4,5.1,41.8291,5.047C9041,7961,9088,7961,9088,7961h51c3.6263,0,45-1,45-1,4.4479-2.9554,44-1,44-1,2.1917-32-6.9821-156.9378-6.6337-238,.4684-109,.6337-109,.6337-109s-38.9443-71.9595-39-72c-22-16-30-85-30-85-1.0993-21,4.8476-122,10-129l17-33c.2219-.32,13.1225-19.1442,13.3748-19.4414.6877-.81,2.2736-2.6775,4.3566-5.1308,8.5789-10.1041-6.744-28.6893-19.31-32.906C9170.5954,7232.8958,9168.1861,7230.1558,9161,7228Z"/><path
      style={spriteStyle}
      d="M9969,8426l57,84,107.97,86,52.5185,49.3226,24.0448,15.0806L10215,8772l-419.7164-4-65.3211-7.7069C9704.2388,8756,9654.1642,8762,9654.1642,8762l-43.9413-1.0376L9621.3881,8494,9605,8310l64.6418-270s74.7438,1,77.3881,1c18.51,0,25.0379-1,25.0379-1l59.8032-9.4618a7.6773,7.6773,0,0,1,6.7649,7.5694L9884,8140Z"/><path
      style={spriteStyle}
      d="M9313.1425,8031.2459c-8.9367,2.6695-98.7442-8.8948-165.96-4.0422-8.7449.6314-53.1593,32.3376-73.9043,49.5169-14.85,12.2977-48.7912,110.8825-49.27,138.4453-.1954,11.2619-6.343,36.38-9.0759,107.1182-.3021,7.8181,6.2178,123.8812,7.7794,129.35C9027.7535,8469.2922,9047,8533,9047,8533l-20,58c-7,18-28.0018,31.8734-33,44,0,0-1.7577,49.0606-1.1072,60.1767.6479,11.07-.7889,3.9511-1.5651,36.6222s-.382,38.1584-.382,38.1584,19.7077,4.1428,27.7654,4.09c7.2419-.0478,259.9621-5.1,259.9621-5.1h36.9521C9317.9762,8768.9468,9411,8758,9411,8758c3.4027-.9945,109,1,109,1s-7.446-230.264-13.0208-278.06c-1.2966-11.1161-23.3382-101.0549-23.3382-101.0549s4.0311-47.2335,5.359-50.8854c4-11,18-54,18-54-.7127-21.2215-4.34-74.9262-1-82l22-61s76.9954-32.98,77-33c2.4148-10.2631,12.914-44.442,12.1377-53.2754a10.824,10.824,0,0,0-10.6977-9.8879c-34.3982-.2585-148.4677-.0124-150.3587-.5486C9438.2612,8030.2354,9342.1861,8022.57,9313.1425,8031.2459Z"/><path
      style={spriteStyle}
      d="M7336,8469l43.9738,49,180.8587,97,31.2513,34L7619,8664l5,101H7140l-50.87-11.1831a143.6481,143.6481,0,0,0-65.82,1.8643L6994,8759l-27-1,3-274,29-132,6-313s47.5687-4,50.5288-4c20.7206,0,63.4712-4,63.4712-4l95.8025,7.6642a4.8181,4.8181,0,0,1,4.4268,4.54L7225,8149Z"/><path
      style={spriteStyle}
      d="M6645.2763,8760.1088s-125.0577.2272-148.4838.2272c-10.5418-2.1262-65.9731,5.4849-77.2925.164.3839-5.3209-6-65-6-73,0-10.2956,27.6257-49.7586,27.6257-49.7586l14.7165-49.2389,9.5978-75.3655-7.6782-56.273c-1.1713-3.1947-13.5452-35.9954-14.7165-39.19S6431.7776,8387.1369,6431,8385c-14.2971-24.9746,28.0078-128.6948,27.2223-162.5114-.7776-33.806.415-64.6913,4.6852-92.2506,1.7048-11.002,20.1331-43.9661,27.9385-56.2213,11.3194-17.0419,16.1775-31.093,35.3141-34.8326,19.1248-3.7182,55.64-2.68,76.34,1.3159,20.6918,3.9853,136.4284-10.0685,154-9,19.5185,1.0685,142.0517-5.8125,163.1667-.7881C6930.5672,8033.3057,6974,8041,6974,8041c5.7586,16.078-52.151,56.8115-50.5,80.5L6921,8195l-26,62-34,26c-11.5173,9.0439-34.0444,41.2408-31.5,49.5,12.2032,39.7656,3.0107,9.4216,39.5,98.5,2.9283,7.7249,53.7384,137.9516,53.7333,138.3141C6922.2,8607.808,6943.5,8692.5,6929.5,8740.5c-.1185.4063-112,12-112,12-7.03,5.8551-75.4861,15.7222-79,16C6734.9861,8768.7671,6645.2763,8760.1088,6645.2763,8760.1088Z"/><path
      style={spriteStyle}
      d="M4721,8498l68.3958,20,213.8974,97,36.96,34,33.0283,28L5077,8757l-518.36,6-57.56-2.5764c-25.865-6.8848-39.16-6.4236-78.6449-4.0617l-14.1788,3.0027L4358.8052,8753l-21.2324-213,2.3591-208.1588L4336,8039s49.6238-8,53.1246-8c24.5058,0,73.7866,5.0136,73.7866,5.0136L4595,8034l22,91Z"/><path
      style={spriteStyle}
      d="M4008.62,8036c-8.1018.2-78.8109,14.7888-86.3562,21-29.1553,24-37.5366,104.7249-38.6221,132-.4436,11.1444-2.44,70-8.6416,140-.6854,7.7365,14.4566,109.5881,18,115,11.4405,17.4735,48.0365,61,48.0365,61l7.355,44c-4.413,26-26.6735,68-38.0148,80,0,0-35.304,11-33.8278,22,1.47,10.9545,1.152,19.91-.6094,52.24s-.8668,57.76-.8668,57.76,43.2471,5.1,61.5307,5.047c16.4323-.0472,85.5693-.047,85.5693-.047h83.847c5.3343,0,48.8839,2.8327,57.369-1,6.5428-2.9554,130.9512-6,130.9512-6,3.2239-32-20.8252-200-22.2135-224-6.3-108.9066-19.4368-158-19.4368-158s18.0484-78,18.2273-95c.0013-.1288,28.9763-69,28.9763-69C4298.2761,8192,4311,8113,4311,8087l-5.5534-52-154.1062-5c-20.594-1-40.9922,5-45.8153,4C4070.3756,8026.7123,4060.9442,8034.7077,4008.62,8036Z"/><path
      style={spriteStyle}
      d="M3422.5,8493l86.8958,25,213.8974,97,36.96,34,33.0283,28L3798,8775l-519.36-12-57.56-2.5764c-25.865-6.8848-39.16-6.4236-78.6449-4.0617l-14.1788,3.0027L2964.8052,8757l-21.2324-213,2.3591-208.1588L2942,8030s163.6238,1,167.1246,1c24.5058,0,73.7866,5.0136,73.7866,5.0136l89.1483-3.981L3280.5622,8096Z"/><path
      style={spriteStyle}
      d="M2691.2009,8036c-5.8356.2-56.7661,14.7888-62.2009,21-21,24-27.0369,104.7249-27.8188,132-.32,11.1444,15.2472,59,10.7806,129-.4937,7.7365,16.486,101.5881,19.0382,107,8.24,17.4735,11.5218,80,11.5218,80l5.2977,44c-3.1787,26-19.2126,68-27.3815,80,0,0-25.4288,11-24.3655,22,1.0589,10.9545.83,19.91-.439,52.24s-.6243,57.76-.6243,57.76,31.15,5.1,44.3194,5.047c11.836-.0472,61.6341-.047,61.6341-.047h60.3935c3.8423,0,35.21,2.8327,41.3219-1,4.7127-2.9554,94.3219-6,94.3219-6,2.3221-32-15-200-16-224-4.5378-108.9066-14-158-14-158s13-78,13.1288-95c.001-.1288,20.8712-69,20.8712-69-1.1648-21,8-100,8-126l-4-52-111-5c-14.8335-1-29.526,5-33,4C2735.6824,8026.7123,2728.8892,8034.7077,2691.2009,8036Z"/><path
      style={spriteStyle}
      d="M1032,8457s204.8739,62.7036,205.1446,72.5912l6.5,237.4088H1173.16l-148.5234-18.3031a137.1934,137.1934,0,0,0-40.2464,2.586L925.74,8759l-595.0144-2C327,8757,315,8582.5142,315,8582.5142L319.3194,8235l4.1338-97.9887L319.3194,8027s293.7289-1,298.0484-1c30.2361,0,96.9443,7.0136,96.9443,7.0136l70.7012,2.5271a6.0737,6.0737,0,0,1,5.4309,6.0035L798.891,8105l98.711,230Z"/><path
      style={spriteStyle}
      d="M212.9961,8028c-21.3856-6-35.7454,1.7077-73.78,3-5.8892.2-53.9116-4.129-59.5126.0774C68.4443,8039.5331,39.6817,8035,37.6342,8043c-6.4563,25.2262-2.6057,113.7249-3.3948,141-.3224,11.1444,15.3875,59,10.88,129-.4982,7.7365,23.9982,133.5881,26.5739,139,8.3162,17.4735,19.4051,63,19.4051,63l5.3464,44c-3.2078,26-34.5272,53-42.7712,65,0,0-25.6627,11-24.59,22,1.0686,10.9545.8373,19.91-.443,52.24s-.63,57.76-.63,57.76,31.4366,5.1,44.727,5.047c11.9448-.0472,62.201-.047,62.201-.047h54.5332c3.8776,0,48.1176-1,48.1176-1,4.756-2.9554,47.0483-1,47.0483-1,2.3435-32-7.4658-156.9378-7.0933-238,.5008-109,.6776-109,.6776-109s-41.6423-71.96-41.7019-72c-23.5241-16-32.0784-85-32.0784-85-1.1755-21,5.1834-122,10.6928-129l18.1778-33c.2373-.32,14.0316-19.1442,14.3014-19.4414.7353-.81,2.4311-2.6775,4.6583-5.1308,9.1733-10.1041-7.2112-28.6893-20.648-32.906C223.2562,8032.8958,220.68,8030.1558,212.9961,8028Z"/><path
      style={spriteStyle}
      d="M2066,8492l106.9131,25,263.1707,97,45.4743,34,40.6367,28L2528,8774l-639-12-70.8193-2.5764c-31.8234-6.8848-48.1807-6.4236-96.7616-4.0617l-17.445,3.0027L1632.0586,8757l-26.1235-213,2.9026-208.1588L1604,8030h76.4356c30.1509,0,90.784,5.0136,90.784,5.0136l79.6845-.981L1861.3654,8098Z"/><path
      style={spriteStyle}
      d="M1411.2009,8036c-5.8356.2-56.7661,14.7888-62.2009,21-21,24-27.0369,104.7249-27.8188,132-.32,11.1444,15.2472,59,10.7806,129-.4937,7.7365,23.7795,133.5881,26.3318,139,8.24,17.4735,19.2282,63,19.2282,63l5.2977,44c-3.1787,26-34.2126,53-42.3815,65,0,0-25.4288,11-24.3655,22,1.0589,10.9545.83,19.91-.439,52.24s-.6243,57.76-.6243,57.76,31.15,5.1,44.3194,5.047c11.836-.0472,61.6341-.047,61.6341-.047h60.3935c3.8423,0,35.21,2.8327,41.3219-1,4.7127-2.9554,38.1433-3,38.1433-3,2.3221-32,1.0785-154.9378,1.4476-236,.4963-109,1.731-147,1.731-147s-45.4693-45.2283-49.8712-48c-7.9406-5-22.8293-50-22.8293-50-1.1648-21-4.8071-143,.6521-150l26.1433-52L1514,8030c-14.8335-1-29.526,5-33,4C1455.6824,8026.7123,1448.8892,8034.7077,1411.2009,8036Z"/><path
      style={spriteStyle}
      d="M6026.5,8493l73.4738,25,180.8587,97,31.2513,34L6339,8664l5,101H5860l-50.87-11.1831a143.6481,143.6481,0,0,0-65.82,1.8643L5714,8759l-47-1,23-274,29-132,6-313s47.5687-4,50.5288-4c20.7206,0,63.4712-4,63.4712-4l55.9745,4.24a11.4231,11.4231,0,0,1,10.5449,10.8L5911,8152Z"/><path
      style={spriteStyle}
      d="M5365.2763,8760.1088s-125.0577.2272-148.4838.2272c-10.5418-2.1262-65.9731,5.4849-77.2925.164.3839-5.3209-6-65-6-73,0-10.2956,27.6257-49.7586,27.6257-49.7586l14.7165-49.2389,9.5978-75.3655-7.6782-56.273c-1.1713-3.1947-13.5452-35.9954-14.7165-39.19s-8.18-17.9606-8.9579-20.0975c-14.2971-24.9746,24.92-141.2712,24.1349-175.0878-.7776-33.806.415-64.6913,4.6852-92.2506,1.7048-11.002,20.1331-43.9661,27.9385-56.2213,11.3194-17.0419,16.1775-31.093,35.3141-34.8326,19.1248-3.7182,55.64-2.68,76.34,1.3159,20.6918,3.9853,136.4284-10.0685,154-9,19.5185,1.0685,142.0517-5.8125,163.1667-.7881,10.9005,2.5938,53.8333,9.7881,53.8333,9.7881,5.7586,16.078,3.349,63.3115,5,87L5696,8201l-57.5,58.5-27.3963,39.3308c-11.5173,9.0439-64.1481,25.41-61.6037,33.6692,2.5346,8.2592,4.7,88.0729,4.7,88.0729,2.9283,7.7249,49.5384,154.3787,49.5333,154.7412C5603.2,8613.808,5624.5,8698.5,5610.5,8746.5c-.1185.4063-73,6-73,6-7.03,5.8551-75.4861,15.7222-79,16C5454.9861,8768.7671,5365.2763,8760.1088,5365.2763,8760.1088Z"/><path
      style={spriteStyle}
      d="M8636.4732,8491.4839,8721,8523l129,74,57.6842,49.3226,26.41,15.0806L8939,8773l-461-4-71.7461-7.7069C8378,8757,8323,8763,8323,8763l-48.2634-1.0376L8287,8495l-18-184-8-276.9516s117.3192-5.0269,120.2236-5.0269c20.3308,0,71.2771-2.0215,71.2771-2.0215l65.6855,4.5382a7.9913,7.9913,0,0,1,7.43,7.5694l5.53,109.5429Z"/><path
      style={spriteStyle}
      d="M8033.1425,8031.2459c-8.9367,2.6695-98.7442-8.8948-165.9605-4.0422-8.7449.6314-53.1593,32.3376-73.9043,49.5169-14.85,12.2977-48.7912,110.8825-49.27,138.4453-.1954,11.2619-6.343,36.38-9.0759,107.1182-.3021,7.8181,6.2178,123.8812,7.7794,129.35,5.0419,17.6579,13.6139,62.6541,13.6139,62.6541l3.2414,49.5169c-1.9448,26.2743-22.878,86.9072-27.8761,99.0338,0,0-19.4485,21.2215-18.798,32.3376.6479,11.07-.7889,3.9511-1.5651,36.6222s-.382,38.1584-.382,38.1584,19.7077,4.1428,27.7654,4.09c7.2419-.0478,259.9621-5.1,259.9621-5.1h36.9521C8037.9762,8768.9468,8131,8758,8131,8758c3.4027-.9945,109,1,109,1s-7.446-230.264-13.0208-278.06c-1.2966-11.1161-23.3382-101.0549-23.3382-101.0549s-31.0174-34.5894-33.7107-37.39c-4.8586-5.0528-10.7716-64.6752-10.7716-64.6752-.7127-21.2215,8.08-116.2131,11.42-123.287l10.7473-40.4219s40.742-45.4124,40.7429-45.4325c.9243-22.2321,0-32.38,0-32.38-5.5186-.518-43.8-.3905-45.9874-1.0106C8158.2612,8030.2354,8062.1861,8022.57,8033.1425,8031.2459Z"/><path
      style={spriteStyle}
      d="M9431.768,9566s-234.4831.23-278.4071.23c-19.7658-2.1541-89.1371,7.1607-110.3609,1.77.72-5.3909-41.9294-58.0766-45.602-75.072-3.6542-16.9953,51.5878-50.9043,51.5878-50.9043l27.5934-49.8862,17.9957-76.3564-14.3966-57.0129c-2.1962-3.2366-25.3972-36.4686-27.5934-39.7053s-15.338-18.1967-16.796-20.3617c-26.8071-25.303-30.0125-30.92-43.19-69.23-10.2428-29.13-5.71-71.8724-7.1824-106.1337-1.458-34.2505.7781-65.5418,8.7848-93.4635,3.1964-11.1466,37.7495-44.5441,52.3847-56.9605,21.2238-17.2659,30.3328-31.5017,66.2139-35.29a537.0628,537.0628,0,0,1,112.0062.4113c38.7971,4.0378,117.3177-14.5362,178.0952-4.8261,75.3906,12.4163,71.4284,9.0983,104.3751,10.1808C9543.874,8844.4716,9721.4094,8822.91,9761,8828c20.4385,2.6279,97,24,97,24,10.7974,16.2894,33.9044,47,37,71l-32,79-64,43-38,30c-21.5949,9.1628-60.7707,53.6322-56,62,4.7523,8.3678,39,88,39,88,5.49,7.8265,33.0094,97.6327,33,98-1,39,18.796,94.8787,2,158-13.2547,49.8128-62.9926,58.9256-76.17,64.8685-13.1809,5.9322-89.3886,28.225-95.9772,28.5064C9600.2644,9574.6456,9431.768,9566,9431.768,9566Z"/><path style={spriteStyle} d="M10101,9245l55.6793,114L10203,9454l-.3246,103-46.4937-4.6747a153.6051,153.6051,0,0,0-26.3956-.374l-26.8137,1.93L9819,9545V9249c-.1-8.1054,38.5131-86.0315,40-94l33-79c3.8578-20.4863,21.4537-74.7439,39-86l36-49,142,28-16,143Z"/><path
      style={spriteStyle}
      d="M7535,9029l-11.987,280.6309,116.684,161.4476L7642,9566h-45.9845l-21.9405-5.1885a104.8839,104.8839,0,0,0-45.445-.6433L7479.2565,9566H7263l5-127-3-136c-.2844-16.7722,12-113,12-113l62-167,61-33c21.4944,0,66.2063,18.64,66.2063,18.64Z"/><path
      style={spriteStyle}
      d="M7066.9679,8827s-81.2081,2.4155-247.816,3c-24.686.0866-84.5232-9.7173-108.5946-5.56-28.0783,4.8487-98.7939,5.5089-118.5346,15.46C6533.5888,8869.3548,6484.0939,8891.8816,6457,8941c-15.0479,27.28-27.81,134.4709-30,164-7.55,101.7893,121.5871,149.7961,85.5175,248-2.1562,5.87-69.8854,69.9792-78.2021,71-17.4806,2.1457-8.9113,39.6035-10.29,53-2.058,20,.4419,4.6925,3.2378,45.9848.6965,10.2868,2.0648,21.4427,11.2613,29.0157,10.778,8.8753,28.4867,9.8449,44.38,10.2126,100.3284,2.3221,2.8643.3282,326.3127,8.025,49.0782,1.1678,192.1543,2.7692,202.3562-8.2724C7027.5648,9544.6582,7132.0482,9533.5708,7145,9519c16-18,66.6359-31.9242,86-76,10.564-24.0453,5.4094-117.0682,8-146,4.8353-54-43.0264-107.6532-45.2037-153.8677-.9583-20.3416,47.4013-62.9649,66.2037-80.1323,23-21,59.6767-97.8418,75-107,0,0,42.0151-68,30.5373-87-9.2271-15.2743-81.289-44-81.289-44S7133.5358,8820.9353,7066.9679,8827Z"/><path
      style={spriteStyle}
      d="M6236.8717,9076.7893l6.1413,232.8416,116.684,161.4476L6362,9566h-45.9845l-21.9405-5.1885a104.8839,104.8839,0,0,0-45.445-.6433L6199.2565,9566H5949l1.5353-123.3168,7.2286-183.0964a214.65,214.65,0,0,1,4.9976-49.8542L6024.2243,9027l59.3988-39c21.4945,0,61.3769-7,61.3769-7l41.2063,27.64Z"/><path
      style={spriteStyle}
      d="M5786.9679,8827s-81.2081,2.4155-247.816,3c-24.686.0866-84.5232-9.7173-108.5946-5.56-28.0783,4.8487-98.7939,5.5089-118.5346,15.46C5253.5888,8869.3548,5204.0939,8891.8816,5177,8941c-15.0479,27.28-27.81,134.4709-30,164-7.55,101.7893,121.5871,149.7961,85.5175,248-2.1562,5.87-69.8854,69.9792-78.2021,71-17.4806,2.1457-8.9113,39.6035-10.29,53-2.058,20,.4419,4.6925,3.2378,45.9848.6965,10.2868,2.0648,21.4427,11.2613,29.0157,10.778,8.8753,28.4867,9.8449,44.38,10.2126,100.3284,2.3221,2.8643.3282,326.3127,8.025,49.0782,1.1678,192.1543,2.7692,202.3562-8.2724,15.9913-17.3075,84.1553-49.9379,91.5753-67.9657,12.3477-30,7.1878-121.3107,26.552-165.3865,10.564-24.0453,21.6412-48.5494,34.4639-74.6135,21.3125-43.3209,31.8089-64.6532,29.6316-110.8677-.9583-20.3416,20.4013-79.9649,39.2037-97.1323,23-21,78.6767-67.8418,94-77,0,0,50.0151-81,38.5373-100-9.2271-15.2743-81.289-44-81.289-44S5853.5358,8820.9353,5786.9679,8827Z"/><path
      style={spriteStyle}
      d="M8151.768,9566s-234.4831.23-278.4071.23c-19.7658-2.1541-89.1371,7.1607-110.3609,1.77.72-5.3909-41.9294-58.0766-45.602-75.072-3.6542-16.9953,51.5878-50.9043,51.5878-50.9043l27.5934-49.8862,17.9957-76.3564-14.3966-57.0129c-2.1962-3.2366-25.3972-36.4686-27.5934-39.7053s-15.338-18.1967-16.796-20.3617c-26.8071-25.303-30.0125-30.92-43.19-69.23-10.2428-29.13-5.71-71.8724-7.1824-106.1337-1.458-34.2505.7781-65.5418,8.7848-93.4635,3.1964-11.1466,37.7495-44.5441,52.3847-56.9605,21.2238-17.2659,30.3328-31.5017,66.2139-35.29a537.0628,537.0628,0,0,1,112.0062.4113c38.7971,4.0378,117.3177-14.5362,178.0952-4.8261,75.3906,12.4163,71.4284,9.0983,104.3751,10.1808C8263.874,8844.4716,8525.4094,8820.91,8565,8826c20.4385,2.6279,75.7577,44.3812,75.7577,44.3812C8651.5551,8886.6705,8663.9044,8895,8667,8919l-58,69-44.6035,59.0063-47.3271,30.3749c-21.5949,9.1627-54.84,48.251-50.0694,56.6188,4.7523,8.3678,39,88,39,88,5.49,7.8265,46.0094,96.6327,46,97-1,39,2.796,103.8787-14,167-13.2547,49.8128-101.9926,53.9256-115.17,59.8685-13.1809,5.9322-89.3886,28.225-95.9772,28.5064C8320.2644,9574.6456,8151.768,9566,8151.768,9566Z"/><path
      style={spriteStyle}
      d="M8823.6035,9291l53.0758,68L8923,9454l-.3246,103-61.6853-3.6269-97.9963,4.5927L8592,9559l-3.8505-313.8124c-.0994-8.1054,8.3636-59.2191,9.8505-67.1876l22.9557-121.9028a75.7385,75.7385,0,0,1,33.5352-49.7328L8680,8990l136,22-6.1681,122Z"/><path
      style={spriteStyle}
      d="M4940.8594,9076.7893l6.9272,232.8416,131.6157,161.4476L5082,9566h-51.869l-24.7482-5.1885a133.0968,133.0968,0,0,0-51.26-.6433L4898.4307,9566H4616.15l1.7318-123.3168,8.1536-183.0964a191.2457,191.2457,0,0,1,5.6371-49.8542L4701,9027l67-39c14-2,42-19,42-19l73.7105,39.64Z"/><path
      style={spriteStyle}
      d="M4488.8379,8827s-78.9214,2.4155-240.8379,3c-23.9908.0866-82.1431-9.7173-105.5367-5.56-27.2876,4.8487-96.012,5.5089-115.1969,15.46-56.7884,29.4555-82.8724,68.8137-109.2034,117.9321-14.6242,27.28-19.4144,115.5886-21.5428,145.1177-7.337,101.7893,88.5337,151.847,53.48,250.0509-2.0954,5.87-67.9175,69.9792-76,71-16.9884,2.1457-8.66,39.6035-10,53-2,20,.43,4.6925,3.1467,45.9848.6769,10.2868,2.0066,21.4427,10.9441,29.0157,10.4746,8.8753,27.6847,9.8449,43.13,10.2126,97.5032,2.3221,2.7836.3282,317.1242,8.025,47.6962,1.1678,186.7435,2.7692,196.6582-8.2724,15.541-17.3075,81.7855-49.9379,88.9966-67.9657,12-30,6.9854-121.3107,25.8043-165.3865,10.2665-24.0453,21.0318-48.5494,33.4935-74.6135,20.7123-43.3209,30.9132-64.6532,28.7972-110.8677-.9314-20.3416-1.0526-79.9566,21.7862-89.7535,16.6129-7.1262,83.227-82.2206,98.1188-91.3788,0,0,58.1546-74,47-93-8.9673-15.2743-79-44-79-44S4553.5313,8820.9353,4488.8379,8827Z"/><path
      style={spriteStyle}
      d="M2349,9161l38,115,71.4866,120,49.5912,49.3226,22.7046,15.0806L2535,9572l-396.3224-4-61.68-7.7069c-24.29-4.2931-71.5735,1.7069-71.5735,1.7069l-41.4921-1.0376L1974.4746,9294,1982,9107l38.0388-267s70.5777,1,73.0746,1c17.4784,0,23.6424-1,23.6424-1L2173,8839c3.4694.2788,26.8247,3.96,27,8l107,50Z"/><path
      style={spriteStyle}
      d="M1656.3888,8831.2459c-9.5812,2.6695-105.8663-8.8948-177.9307-4.0422-9.3757.6314-56.9935,32.3376-79.2348,49.5169-15.9213,12.2977-52.31,110.8825-52.8232,138.4453-.21,11.2619-6.8006,36.38-9.7306,107.1182-.3239,7.8181,3.4179,84.2469,5.0921,89.7159,5.4056,17.6579,35.8841,109,35.8841,109l-19.48,84c-7.5049,18-38.58,17.8734-43.9383,30,0,0-1.8845,49.0606-1.187,60.1767.6946,11.07-.8458,3.9511-1.678,36.6222s-.41,38.1584-.41,38.1584,21.1292,4.1428,29.7681,4.09c7.7642-.0478,229.5-7.1,229.5-7.1,4.2322,0,34.9946,1.0532,40.1582,1.0532,2.52,0,101.7129-12,101.7129-12,2.32-.6325,23.3722-.056,38.3909.4475,8.837.2963,16.6271-5.35,18.509-13.4084,11.8127-50.5839,88.4348-217.3622,83.3481-258.0391-1.39-11.116,7.6-100,7.6-100s1.7927-41.3481,3.2164-45c4.2885-11,6.4328-42,6.4328-42-.7641-21.2215,14.0283-65.9262,17.6094-73l67.667-22s20.5-64.98,20.5052-65c2.589-10.2631,14.1606-59.1665,13.3283-68-.524-5.5629,2.4823-34.1214-3.5046-34.1633-36.8793-.2585-173.53-.0124-175.5572-.5486C1790.5319,8830.2354,1687.5273,8822.57,1656.3888,8831.2459Z"/><path
      style={spriteStyle}
      d="M1059,9184l48,92,71.4866,120,49.5912,49.3226,22.7046,15.0806L1255,9572l-396.3224-4-61.68-7.7069C772.7075,9556,725.4239,9562,725.4239,9562l-41.4921-1.0376L694.4746,9294,679,9110l61.0388-270s70.5777,1,73.0746,1c17.4784,0,23.6424-1,23.6424-1l56.47-9.4618c3.4694.2788,33.599,8.4217,33.7743,12.4618l61,87Z"/><path
      style={spriteStyle}
      d="M367.8762,8831.2459c-9.3453,2.6695-103.2583-8.8948-173.5474-4.0422-9.1446.6314-55.5894,32.3376-77.2828,49.5169-15.529,12.2977-51.0216,110.8825-51.5219,138.4453-.2044,11.2619-6.633,36.38-9.4908,107.1182-.3159,7.8181,6.502,123.8812,8.135,129.35C69.4407,9269.2922,83.2928,9350,83.2928,9350L77,9405c-7.32,18-37.6291,17.8734-42.8558,30,0,0-1.8381,49.0606-1.1578,60.1767.6775,11.07-.8249,3.9511-1.6367,36.6222s-.3994,38.1584-.3994,38.1584,20.6086,4.1428,29.0347,4.09c7.5729-.0478,271.8461-5.1,271.8461-5.1,4.128,0,34.1325,1.0532,39.1689,1.0532,2.4584,0,99.2072-12,99.2072-12,2.263-.6325,22.7964-.056,37.4451.4475a17.69,17.69,0,0,0,18.053-13.4084c11.5217-50.5839,49.83-223.422,44.8687-264.0988-1.3558-11.1161-4.1611-95.94-4.1611-95.94s1.7485-41.3481,3.1372-45c4.1828-11,6.2743-42,6.2743-42-.7453-21.2215,13.6827-65.9262,17.1756-73l30-45s55.9952-41.98,56-42c2.5252-10.2631,13.8117-59.1665,13-68-.5112-5.5629,2.4211-34.1214-3.4184-34.1633-35.9707-.2585-169.2548-.0124-171.2322-.5486C498.7146,8830.2354,398.2475,8822.57,367.8762,8831.2459Z"/><path style={spriteStyle} d="M3639,8963l8,287,152,199,3,117h-59.9024l-28.581-6.3953a144.2076,144.2076,0,0,0-59.1995-.7929L3590,9566H3264l2-152-4.4421-247.871a250.8755,250.8755,0,0,1,6.51-61.45L3330,8839s68.0469,2,100,2c28,0,50-1,50-1l93,39Z"/><path
      style={spriteStyle}
      d="M3087,8827s-64.45-10.5658-194.7236-9.9814c-19.3024.0866-65.1387,3.2641-83.9606,7.4209-21.9549,4.8487-77.2488,5.5089-92.6845,15.46-45.6905,29.4555-38.5169,91.8137-59.7022,140.9321-11.7662,27.28-15.62,115.5886-17.3328,145.1177-5.9032,101.7893,39.4225,147.0931,11.219,245.2971-1.6859,5.87-36.6047,14.5908-43.1076,15.6117-13.6685,2.1457-17.928,18.9665-18.8641,32.3795-2.8791,41.2521-3.2268,62.4551-1.0406,103.7474.5446,10.2868,1.6145,21.4427,8.8054,29.0157,8.4275,8.8753,22.2744,9.8449,34.7015,10.2126,78.4486,2.3221,2.24.3282,255.15,8.025,38.3752,1.1678,150.2491,2.7692,158.2262-8.2724,12.5039-17.3075,16.0324-69.1455,31.9085-110.4166C3103.9994,9377.7087,3134.5952,9327.84,3163,9254c16.6646-43.3209,24.872-64.6532,23.1695-110.8677-.7493-20.3416-.8469-79.9566,17.5286-89.7535,13.3663-7.1262,44.32-26.2206,56.3019-35.3788,0,0,39.6193-158.868,37-170-4-17-65-14-65-14S3139.0507,8820.9353,3087,8827Z"/><path
      style={spriteStyle}
      d="M6815.5458,10366s-200.2516.23-237.7633.23c-16.88-2.1541-76.1243,7.1607-94.25,1.77.6147-5.3909-27.3964-39.0047-30.5328-56-3.1207-16.9953,57-48,57-48l31.21-68.8625,15.3686-76.3564-12.2948-57.0129c-1.8756-3.2366-21.408-45.5315-23.2836-48.7682s-13.38-9.1338-14.6256-11.2988C6483.4808,9976.3982,6467.2535,9944.31,6456,9906c-8.7475-29.13-20.3863-48.4011-21.644-82.6624-1.2452-34.2505.6644-65.5418,7.5023-93.4635,2.73-11.1466,32.2386-44.5441,44.7372-56.9605,18.1255-17.2659,25.9046-31.5017,56.5475-35.29a392.099,392.099,0,0,1,95.6548.4113c33.1333,4.0378,100.1909-14.5362,152.0957-4.8261,64.3846,12.4163,61.0009,9.0983,89.1378,10.1808,31.2545,1.0825,187.6092-18.4795,221.42-13.3891,17.4548,2.6279,58.4655,33,58.4655,33,9.2212,16.2894,3.0789,53,3.0789,81l-27.4422,51L7097,9854l-26,25c-18.4423,9.1628-14.0742,57.6322-10,66,4.0585,8.3678,20,82,20,82,4.689,7.8265,23.008,123.6327,23,124-.854,39,1.344,66.8787-13,130-11.32,49.8128-32.71,58.9256-43.9635,64.8685-11.2566,5.9322-76.339,28.225-81.9658,28.5064C6959.444,10374.6456,6815.5458,10366,6815.5458,10366Z"/><path style={spriteStyle} d="M7545,10037l48.07,93.0828,58.93,105.8857-.4129,114.8023L7159,10351l-5-334c-.1265-9.0342,15.1083-71.1185,17-80l18-114c4.9079-22.8337,16.9759-65.4026,17-65l11-63,312,8-2,158Z"/><path
      style={spriteStyle}
      d="M5535.5458,10366s-200.2516.23-237.7633.23c-16.88-2.1541-76.1243,7.1607-94.25,1.77.6147-5.3909-27.3964-39.0047-30.5328-56-3.1207-16.9953,57-48,57-48l31.21-68.8625,15.3686-76.3564-12.2948-57.0129c-1.8756-3.2366-21.408-45.5315-23.2836-48.7682s-13.38-9.1338-14.6256-11.2988c-22.8936-25.303-54.6311-33.92-65.8846-72.23-8.7475-29.13-4.8761-71.8724-6.1338-106.1337-1.2452-34.2505.6644-65.5418,7.5023-93.4635,2.73-11.1466,32.2386-44.5441,44.7372-56.9605,18.1255-17.2659,25.9046-31.5017,56.5475-35.29a392.099,392.099,0,0,1,95.6548.4113c33.1333,4.0378,100.1909-14.5362,152.0957-4.8261,64.3846,12.4163,61.0009,9.0983,89.1378,10.1808,31.2545,1.0825,187.6092-18.4795,221.42-13.3891,17.4548,2.6279,64.4655,33,64.4655,33,9.2212,16.2894,3.0789,61,3.0789,89l-27.4422,51-38.092,59.0063L5791,9879c-18.4423,9.1628-14.0742,57.6322-10,66,4.0585,8.3678,20,82,20,82,4.689,7.8265,23.008,123.6327,23,124-.854,39,1.344,66.8787-13,130-11.32,49.8128-32.71,58.9256-43.9635,64.8685-11.2566,5.9322-76.339,28.225-81.9658,28.5064C5679.444,10374.6456,5535.5458,10366,5535.5458,10366Z"/><path style={spriteStyle} d="M6265,10037l48.07,93.0828,58.93,105.8857-.4129,114.8023L5913,10351l-22-328c-.1265-9.0342-1.8917-77.1185,0-86l18-114c4.9079-22.8337,22.9759-84.4026,23-84l13-44,280,2,4,171Z"/><path
      style={spriteStyle}
      d="M4255.5458,10366s-200.2516.23-237.7633.23c-16.88-2.1541-76.1243,7.1607-94.25,1.77.6147-5.3909-35.8083-58.0766-38.9447-75.072-3.1208-16.9953,61.0566-47.9043,61.0566-47.9043l23.5651-49.8862,15.3686-76.3564-12.2948-57.0129c-1.8756-3.2366-21.69-36.4686-23.5652-39.7053s-13.0988-18.1967-14.344-20.3617c-22.8936-25.303-42.6311-33.92-53.8846-72.23-8.7475-29.13-4.8761-71.8724-6.1338-106.1337-1.2452-34.2505.6644-65.5418,7.5023-93.4635,2.73-11.1466,32.2386-44.5441,44.7372-56.9605,18.1255-17.2659,25.9046-31.5017,56.5475-35.29a392.099,392.099,0,0,1,95.6548.4113c33.1333,4.0378,100.1909-14.5362,152.0957-4.8261,64.3846,12.4163,61.0009,9.0983,89.1378,10.1808,31.2545,1.0825,187.6092-18.4795,221.42-13.3891,17.4548,2.6279,64.4655,33,64.4655,33,9.2212,16.2894,3.0789,61,3.0789,89l-27.4422,51-38.092,59.0063L4511,9879c-18.4423,9.1628-8.0742,55.6322-4,64,4.0585,8.3678,14,84,14,84,4.689,7.8265,23.008,123.6327,23,124-.854,39,1.344,66.8787-13,130-11.32,49.8128-32.71,58.9256-43.9635,64.8685-11.2566,5.9322-76.339,28.225-81.9658,28.5064C4399.444,10374.6456,4255.5458,10366,4255.5458,10366Z"/><path style={spriteStyle} d="M4965.5471,10054.291l67.5234,75.7918,58.93,105.8857-.4129,114.8023L4653,10351l-13.9989-343.7709c-.1265-9.0341-7.36-70.0048-5.4682-78.8863L4646,9803c4.9079-22.8337,5.9759-64.4026,6-64l13-44,280,2,4,171Z"/><path
      style={spriteStyle}
      d="M2975.5458,10366s-200.2516.23-237.7633.23c-16.88-2.1541-76.1243,7.1607-94.25,1.77.6147-5.3909-35.8083-58.0766-38.9447-75.072-3.1208-16.9953,61.0566-47.9043,61.0566-47.9043l23.5651-49.8862,15.3686-76.3564-12.2948-57.0129c-1.8756-3.2366-21.69-36.4686-23.5652-39.7053s-13.0988-18.1967-14.344-20.3617c-22.8936-25.303-42.6311-33.92-53.8846-72.23-8.7475-29.13-4.8761-71.8724-6.1338-106.1337-1.2452-34.2505.6644-65.5418,7.5023-93.4635,2.73-11.1466,32.2386-44.5441,44.7372-56.9605,18.1255-17.2659,25.9046-31.5017,56.5475-35.29a392.099,392.099,0,0,1,95.6548.4113c33.1333,4.0378,100.1909-14.5362,152.0957-4.8261,64.3846,12.4163,61.0009,9.0983,89.1378,10.1808,31.2545,1.0825,187.6092-18.4795,221.42-13.3891,17.4548,2.6279,82.4655,33,82.4655,33,9.2212,16.2894,3.0789,61,3.0789,89l-27.4422,51-38.092,59.0063L3249,9879c-18.4423,9.1628-26.0742,55.6322-22,64,4.0585,8.3678,14,84,14,84,4.689,7.8265,23.008,123.6327,23,124-.854,39,1.344,66.8787-13,130-11.32,49.8128-32.71,58.9256-43.9635,64.8685-11.2566,5.9322-76.339,28.225-81.9658,28.5064C3119.444,10374.6456,2975.5458,10366,2975.5458,10366Z"/><path style={spriteStyle} d="M3685.5471,10054.291l67.5234,75.7918,58.93,105.8857-.4129,114.8023L3390.9,10353l-31.8986-345.7709c-.1265-9.0341-7.36-70.0048-5.4682-78.8863l29.2044-135.8712c4.908-22.8337,15.8618-52.273,15.8859-51.87L3412,9697l261,22-4,149Z"/><path
      style={spriteStyle}
      d="M471.768,10366s-234.4831.23-278.4071.23c-19.7658-2.1541-89.1371,7.1607-110.3609,1.77.72-5.3909-41.9294-58.0766-45.602-75.072-3.6542-16.9953,51.5878-50.9043,51.5878-50.9043l27.5934-49.8862,17.9957-76.3564-14.3966-57.0129c-2.1962-3.2366-25.3972-36.4686-27.5934-39.7053s-15.338-18.1967-16.796-20.3617c-26.8071-25.303-30.0125-30.92-43.19-69.23-10.2428-29.13-5.71-71.8724-7.1824-106.1337-1.458-34.2505.7781-65.5418,8.7848-93.4635,3.1964-11.1466,37.75-44.5441,52.3847-56.9605,21.2238-17.2659,30.3328-31.5017,66.2139-35.29a537.0628,537.0628,0,0,1,112.0062.4113c38.7971,4.0378,117.3177-14.5362,178.0952-4.8261,75.3906,12.4163,71.4284,9.0983,104.3751,10.1808C583.874,9644.4716,761.4094,9622.91,801,9628c20.4385,2.6279,62,14,62,14,10.7974,16.2894,33.9044,47,37,71l-32,79-42,45-38,21c-21.5949,9.1628-63.7707,52.6322-59,61,4.7523,8.3678,26,110,26,110,5.49,7.8265,30.0094,97.6327,30,98-1,39,20.796,84.8787,4,148-13.2547,49.8128-32.9926,64.9256-46.17,70.8685-13.1809,5.9322-89.3886,28.225-95.9772,28.5064C640.2644,10374.6456,471.768,10366,471.768,10366Z"/><path style={spriteStyle} d="M1141,10045l55.6793,114L1243,10254l-.3246,103-46.4937-4.6747a153.6051,153.6051,0,0,0-26.3956-.374l-26.8137,1.93L859,10345v-296c-.1-8.1054,10.5131-90.0315,12-98l18-90c3.8578-20.4863,38-90,38-90l24-70,173,60,9,151Z"/><path
      style={spriteStyle}
      d="M1695.5458,10366s-200.2516.23-237.7633.23c-16.88-2.1541-76.1243,7.1607-94.25,1.77.6147-5.3909-35.8083-58.0766-38.9447-75.072-3.1208-16.9953,44.0566-50.9043,44.0566-50.9043l23.5651-49.8862,15.3686-76.3564-12.2948-57.0129c-1.8756-3.2366-21.69-36.4686-23.5652-39.7053s-13.0988-18.1967-14.344-20.3617c-22.8936-25.303-25.6311-30.92-36.8846-69.23-8.7475-29.13-4.8761-71.8724-6.1338-106.1337-1.2452-34.2505.6644-65.5418,7.5023-93.4635,2.73-11.1466,32.2386-44.5441,44.7372-56.9605,18.1255-17.2659,25.9046-31.5017,56.5475-35.29a392.099,392.099,0,0,1,95.6548.4113c33.1333,4.0378,100.1909-14.5362,152.0957-4.8261,64.3846,12.4163,61.0009,9.0983,89.1378,10.1808,31.2545,1.0825,254.6092-22.4795,288.42-17.3891,17.4548,2.6279,82.4655,33,82.4655,33,9.2212,16.2894,3.0789,61,3.0789,89l-27.4422,51-38.092,59.0063-60.9436,19.3749c-18.4423,9.1627-46.8341,48.251-42.76,56.6188,4.0586,8.3678,33.3065,88,33.3065,88,4.689,7.8265,39.2927,96.6327,39.2846,97-.854,39,2.3879,103.8787-11.9561,167-11.32,49.8128-87.1031,53.9256-98.3566,59.8685-11.2566,5.9322-76.339,28.225-81.9658,28.5064C1839.444,10374.6456,1695.5458,10366,1695.5458,10366Z"/><path
      style={spriteStyle}
      d="M2405.5471,10054.291l67.5234,75.7918,58.93,105.8857-.4129,114.8023L2110.9,10353l-4.8986-349.7709c-.1265-9.0341,10.64-66.0048,12.5318-74.8863l29.2044-135.8712c4.908-22.8337,15.8618-52.273,15.8859-51.87L2176.55,9697l219.3244,46.3215-7.8471,135.9795Z"/><path
      style={spriteStyle}
      d="M9366.5458,10368s-200.2516.23-237.7633.23c-16.88-2.1541-103.6571,9.1607-121.7825,3.77.6147-5.3909,4.1364-27.0047,1-44-3.1207-16.9953,93.6447-87.9763,93.6447-87.9763l66.5651-65.8862,15.3686-76.3564-12.2948-57.0129c-1.8756-3.2366-21.69-36.4686-23.5652-39.7053s-13.0988-18.1967-14.344-20.3617C9110.4808,9955.3982,9022.2535,9913.31,9011,9875c-8.7475-29.13-25.7423-49.7387-27-84-1.2452-34.25,6.1621-64.0784,13-92,2.73-11.1466,28.0969-11.67,40.5955-24.0864,18.1255-17.2659,25.9046-31.5017,56.5475-35.29a392.099,392.099,0,0,1,95.6548.4113c33.1333,4.0378,100.1909-14.5362,152.0957-4.8261,64.3846,12.4163,61.0009,9.0983,89.1378,10.1808,31.2545,1.0825,166.6092-25.4795,200.42-20.3891,17.4548,2.6279,47.5486,17,47.5486,17,9.2211,16.2894,4,75,4,103l7,53-31,83-32,24c-18.4423,9.1628-48.4648,44.7047-48,54,1,20-4,90-4,90,4.689,7.8265,34.008,71.6327,34,72-.854,39,56.344,96.8787,42,160-11.32,49.8128-41.71,60.9256-52.9635,66.8685-11.2566,5.9322-76.339,28.225-81.9658,28.5064C9510.444,10376.6456,9366.5458,10368,9366.5458,10368Z"/><path
      style={spriteStyle}
      d="M10128.614,10027l13.5037,105.0828L10203,10237.9685l-.4266,114.8023L9673,10360l15.4971-219c-.1307-9.0342,20.2921-205.7757,22.2465-214.6572l19.8408-194.8712c5.0706-22.8337,16.3873-52.273,16.4123-51.87L9760.3513,9636l314.5395,8,33.0605,211Z"/><path
      style={spriteStyle}
      d="M8086.5458,10368s-200.2516.23-237.7633.23c-16.88-2.1541-76.1243,7.1607-94.25,1.77.6147-5.3909-8.3964-37.0047-11.5328-54-3.1207-16.9953,78.6447-75.9763,78.6447-75.9763l23.5651-49.8862,15.3686-76.3564-12.2948-57.0129c-1.8756-3.2366-21.69-36.4686-23.5652-39.7053s-13.0988-18.1967-14.344-20.3617C7787.4808,9971.3982,7742.2535,9913.31,7731,9875c-8.7475-29.13-25.7423-49.7387-27-84-1.2452-34.25,6.1621-64.0784,13-92,2.73-11.1466,28.0969-11.67,40.5955-24.0864,18.1255-17.2659,25.9046-31.5017,56.5475-35.29a392.099,392.099,0,0,1,95.6548.4113c33.1333,4.0378,100.1909-14.5362,152.0957-4.8261,64.3846,12.4163,61.0009,9.0983,89.1378,10.1808,31.2545,1.0825,166.6092-25.4795,200.42-20.3891,17.4548,2.6279,82.4655,33,82.4655,33,9.2212,16.2894,3.0789,61,3.0789,89l-27.4422,51-38.092,59.0063L8340,9867c-18.4423,9.1628-27.0742,57.6322-23,66,4.0585,8.3678,44,94,44,94,4.689,7.8265,24.008,97.6327,24,98-.854,39,.344,92.8787-14,156-11.32,49.8128-41.71,60.9256-52.9635,66.8685-11.2566,5.9322-76.339,28.225-81.9658,28.5064C8230.444,10376.6456,8086.5458,10368,8086.5458,10368Z"/><path style={spriteStyle} d="M8825,10037l39.07,95.0828,58.93,105.8857-.4129,114.8023L8438.9,10351l-4.8986-349.7709c-.1265-9.0341,10.64-66.0048,12.5318-74.8863l29.2044-135.8712c4.908-22.8337,15.8618-52.273,15.8859-51.87L8504.55,9695,8809,9703l-2,158Z"/><path
      style={spriteStyle}
      d="M10115,10445v127l6,326,90.2791,145,3.7209,118-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187L9834,11157l1-148-2-195a42.29,42.29,0,0,1,3.1039-16.92c11.193-29.6992,35.4235-121.0268,35.8961-129.08l-16-231s-6.7238-1,23-1c26.0465,0,140.6512-1,140.6512-1h37.0934a91.6361,91.6361,0,0,1,18.3239,1.8507Z"/><path
      style={spriteStyle}
      d="M9771,11161s-364.5223-3.0837-429.4545-4.4116c-64.41-1.3278-147.8726,7.4116-179.5455,7.4116-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.6105.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,51-35,51-35l20-35,26-30c17.6993-21,25.0284-142-1-179,0,0-30.2457-126.1655-31.2971-127.5,0,0-73.4723-60.4306-81.7029-78.5-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,193.1574,2.1231,211.5788,4l19,132-11,89-47,43s-31.451,25.4991-58,40c-22.905,20,12,84,12,107,0,0,19.7532,110,26,130C9759.45,10985.2479,9793.905,11134,9771,11161Z"/><path
      style={spriteStyle}
      d="M8835,10445v127l17.2093,367,79.07,104L8935,11161l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187L8554,11157l1-148-2-195a42.29,42.29,0,0,1,3.1039-16.92c11.193-29.6992,35.4235-121.0268,35.8961-129.08l-33-232h29.9535c26.0465,0,150.6977-1,150.6977-1l68.8372,7Z"/><path
      style={spriteStyle}
      d="M8491,11161s-364.5223-3.0837-429.4545-4.4116c-64.41-1.3278-147.8726,7.4116-179.5455,7.4116-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.61.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,51-35,51-35l20-35,26-30c17.6993-21,25.0284-142-1-179,0,0-30.2457-126.1655-31.2971-127.5,0,0-73.4723-60.4306-81.7029-78.5-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,182.1574,11.1231,200.5788,13l20,125-12,77-36,53s-49.451,36.4991-76,51c-22.905,20,15,77,15,100,0,0,34.7532,106,41,126C8479.45,10985.2479,8513.905,11134,8491,11161Z"/><path
      style={spriteStyle}
      d="M7555,10445v127l17.2093,367,79.07,104L7655,11161l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187L7261,11157l-1-144-10-205a42.29,42.29,0,0,1,3.1039-16.92c11.193-29.6992,35.4235-121.0268,35.8961-129.08l-10-226h29.9535c26.0465,0,150.6977-1,150.6977-1l68.8372,7Z"/><path
      style={spriteStyle}
      d="M7179,11169s-332.5223-11.0837-397.4545-12.4116c-64.41-1.3278-147.8726,7.4116-179.5455,7.4116-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.61.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,51-35,51-35l20-35,26-30c17.6993-21,25.0284-142-1-179,0,0-30.2457-126.1655-31.2971-127.5,0,0-73.4723-60.4306-81.7029-78.5-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,182.1574,11.1231,200.5788,13l20,125-12,77-36,53s-49.451,36.4991-76,51c-22.905,20-5,83-5,106,0,0,34.7532,106,41,126C7179.45,10991.2479,7201.905,11142,7179,11169Z"/><path
      style={spriteStyle}
      d="M6275,10445v127l17.2093,367,79.07,104L6375,11161l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187L5962,11157l1-144-10.5395-205.9995a41.9018,41.9018,0,0,1,2.6434-16.9208c11.193-29.6992,44.4235-124.0268,44.8961-132.08l-1-222h29.9535c26.0465,0,150.6977-1,150.6977-1l68.8372,7Z"/><path
      style={spriteStyle}
      d="M5871,11165s-304.5223-7.0837-369.4545-8.4116c-64.41-1.3278-147.8726,7.4116-179.5455,7.4116-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.61.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,36.0717-15,43.36-21l27.64-49,26-30c17.6993-21,25.0284-142-1-179,0,0-30.2457-126.1655-31.2971-127.5,0,0-73.4723-60.4306-81.7029-78.5-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,166.1574,9.1231,184.5788,11l22,135-12,77-50,47s-55.451,34.4991-82,49c-22.905,20,12,74,12,97,0,0,17.7532,113,24,133C5865.45,10989.2479,5893.905,11138,5871,11165Z"/><path
      style={spriteStyle}
      d="M5015,10447l-4.545,62.01,1.7543,429.99,79.07,104L5095,11161l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187L4643,11156v-145l2.9-224.2534a44.0085,44.0085,0,0,1,2.5328-14.18c9.2817-26.1065,36.089-111.4141,36.5674-119.5668l-10-220s44.23,3,73.9535,3c26.0465,0,150.6977-1,150.6977-1l68.8372,7Z"/><path
      style={spriteStyle}
      d="M4533,11166s-246.5223-8.0837-311.4545-9.4116c-64.41-1.3278-147.8726,7.4116-179.5455,7.4116-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.61.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,36.0717-15,43.36-21l27.64-49,26-30c17.6993-21,25.0284-142-1-179,0,0-30.2457-126.1655-31.2971-127.5,0,0-73.4723-60.4306-81.7029-78.5-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,137.8694,1.1231,156.2908,3l7.288,161-21.8639,66-36.96,36.5s-51.6268,26.9991-78.1758,41.5c-22.905,20,22,85,22,108,0,0,7.5177,119.5,13.7645,139.5C4549.2147,10995.7479,4555.905,11139,4533,11166Z"/><path
      style={spriteStyle}
      d="M3730.3488,10483l1.86,456,79.07,104L3815,11161l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187-223.9535-1L3325,11010l29.7674-104c2.2192-8.7859,8.6408-51.5779,9.174-60.6638L3388,10431s51.23,5,80.9535,5c26.0465,0,150.6977-1,150.6977-1l68.8372,7Z"/><path
      style={spriteStyle}
      d="M3253,11166s-246.5223-8.0837-311.4545-9.4116c-64.41-1.3278-147.8726,7.4116-179.5455,7.4116-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.61.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,36.0717-15,43.36-21l27.64-49,42-48c17.6993-21,24.0284-125-2-162,0,0-45.2457-125.1655-46.2971-126.5,0,0-73.4723-60.4306-81.7029-78.5-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,100.8694,1.1231,119.2908,3l7.288,161-21.8639,66-36.96,36.5s-49.0142,19.4991-75.5631,34c-22.905,20,9.0269,101.5,9.0269,124.5,0,0,34.8781,111.5,41.1249,131.5C3249.2147,10996.7479,3275.905,11139,3253,11166Z"/><path
      style={spriteStyle}
      d="M2450.3488,10483l1.86,456,79.07,104L2535,11161l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187-253.9535-3L2015,11008l29.7674-104c2.2192-8.7859,8.6408-51.5779,9.174-60.6638L2078.2558,10429s80.9739,7,110.6977,7c26.0465,0,150.6977-1,150.6977-1l68.8372,7Z"/><path
      style={spriteStyle}
      d="M1953.1347,11160.5s-226.657-2.5837-291.5892-3.9116c-64.41-1.3278-179.4162,7.5454-211.0892,7.5454-14.2528-1.3278-57.9732,10.8662-57.9732,10.8662s-53.5506-1.5128-77.0936-7.17a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,10.675-22.7039,19.187-31.39,16.6582-17,53.0979-32,60.3859-38l43.7277-41L1479,10999c17.6993-21,22.0284-139-4-176,0,0-63.2457-119.1655-64.2971-120.5,0,0-83.91-50.4306-92.14-68.5-7.2879-16-12.4728-70.8818-13.5347-92-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,64.8694,6.1231,83.2908,8l7.288,161-21.8639,66-36.96,36.5s-13.0142,14.4991-39.5631,29c-22.905,20-11.9731,103.5-11.9731,126.5,0,0,34.8781,111.5,41.1249,131.5C1948.2147,10998.7479,1976.04,11133.5,1953.1347,11160.5Z"/><path style={spriteStyle} d="M1164,10483l2,456,85,104,4,118-78.474-.428-37.5327-5.6024a300.5436,300.5436,0,0,0-80.1422-1.1566L978,11161l-273-3-9-150,32-104c2.3856-8.7859,9.2888-51.5779,9.862-60.6638L764,10429s87.0469,7,119,7c28,0,162-1,162-1l74,7Z"/><path
      style={spriteStyle}
      d="M647.5,11160.5s-217.7017-2.5837-280.0683-3.9116c-61.8656-1.3278-172.3275,7.5454-202.749,7.5454C150.993,11162.806,109,11175,109,11175s-51.4348-1.5128-74.0476-7.17a7.794,7.794,0,0,1-5.3813-10.44c3.5013-8.7589,10.2532-22.7039,18.4289-31.39,16-17,51-32,58-38l42-41,74-40c17-21,9.5-151.5-15.5-188.5,0,0-78.99-114.6655-80-116,0,0-80.5946-50.4306-88.5-68.5-7-16-11.98-70.8818-13-92-1.01-21.1116-1.9452-42.2777,5.6371-58.9048,3.656-8.0171,27.886-28.732,38.0222-36.3853,6.5808-3.7,35.8382-6.9151,60.6893-9.2505,24.8357-2.322,42.7706-12.9549,69.6514-10.4594,26.8706,2.4888,157.6708.3183,200.203,3.393,51.1837,3.7,196.0474-6.2888,218.797-4.393,12,1,62.3064,6.1231,80,8l7,161-21,66-35.5,36.5s-12.5,14.4991-38,29c-22,20-11.5,103.5-11.5,126.5,0,0,33.5,111.5,39.5,131.5C642.7744,10998.7479,669.5,11133.5,647.5,11160.5Z"/><path
      style={spriteStyle}
      d="M9930.4677,11238,9978,11642l99,91c5.2215,11.5876,43.182,50.3592,55.6885,58.6618l59.6583,43.3382L10205,11953H9947.7177c-25.3695,0-67.4648,6.9815-92.673,4.95L9611,11944l-11-149,6.0887-246L9615,11446c8.6526-57.68,27.5384-79.0495,16.2119-136.4945l-14.3511-72.7855c-.4977-2.524,26.4184-5.6284,29.9994-5.72l136.14,1,50-1c15.2786.7343,45.4489-2,58.1022,3Z"/><path
      style={spriteStyle}
      d="M9555.1855,11952.5s-273.0679-2.5837-317.3368-3.9116c-43.9133-1.3278-122.321,7.5454-143.9148,7.5454-9.7171-1.3278-31.7165-.1338-31.7165-.1338-5.2215-1.3278-66.2257,7.1646-72.3717,5-5.6786-2,2.1294-81,2.1294-81,9.9375-6,47.5578-45,47.5578-45L9060,11765c7.808-39,23.6748-53.5,5.9294-90.5,0,0-6.9892-20.4779-14.9716-43.8037-11.6571-34.0641-27.5135-64.9748-46.931-91.0849-3.55-4.7732-6.5184-9.794-8.0144-14.6114-4.9688-16-.5037-169.8818-1.2277-191-.7167-21.1116-1.3807-42.2777,4.0014-58.9048,2.5951-8.0171,19.7939-28.732,26.9888-36.3853,4.6711-3.7,25.4386-6.9151,43.0783-9.2505,17.6288-2.322,30.3593-12.9549,49.44-10.4594,19.0733,2.4888,111.9175.3183,142.1076,3.393,36.3311,3.7,205.2151.5,221.4122,1.167,17.9917.6673,94.3352,5.89,102.12,11.44L9596,11387l-14.9062,66-25.1985,36.5s-8.8727,14.4991-26.973,29c-15.616,20-8.1629,103.5-8.1629,126.5,0,0,23.7788,111.5,28.0377,131.5C9551.8312,11790.7479,9570.8015,11925.5,9555.1855,11952.5Z"/><path
      style={spriteStyle}
      d="M8774.4677,11240,8769,11647l56.6526,114.3285c5.2215,11.5876,14.5294,22.0307,27.0359,30.3333L8912.3468,11835,8925,11953H8667.7177c-25.3695,0-67.4648,6.9815-92.673,4.95L8402,11944l2.8118-143,18.2769-250L8432,11448c8.6526-57.68,27.5384-79.0495,16.2119-136.4945l-14.3511-72.7855c-.4977-2.524,26.4184-5.6284,29.9994-5.72l140.492-11,80.4421,3.866c15.2786.7343,37.6546,5.134,50.3079,10.134Z"/><path
      style={spriteStyle}
      d="M8363.8106,11952.5s-315.7191-2.5837-366.9025-3.9116c-50.7721-1.3278-141.4266,7.5454-166.3931,7.5454-11.2349-1.3278-36.6705-.1338-36.6705-.1338-6.037-1.3278-76.57,7.1646-83.6756,5-6.5655-2,2.4621-81,2.4621-81,11.49-6,54.9859-45,54.9859-45l43.462-74c9.0276-39,16.8241-148.5-3.693-185.5,0,0-8.0809-20.4779-17.31-43.8037-13.4779-34.0641-31.8109-64.9748-54.2613-91.0849-4.1042-4.7732-7.5366-9.794-9.2663-14.6114-5.7448-16-9.8318-70.8818-10.6689-92-.8287-21.1116-1.5964-42.2777,4.6263-58.9048,3.0005-8.0171,22.8856-28.732,31.2043-36.3853,5.4007-3.7,29.4119-6.9151,49.8068-9.2505,20.3823-2.322,35.1012-12.9549,57.1619-10.4594,22.0523,2.4888,129.3981.3183,164.3037,3.393,42.0057,3.7,237.2681.5,255.9951,1.167,20.8019.6673,109.07,5.89,118.0708,11.44L8411,11387l-17.2344,66-29.1343,36.5s-10.2586,14.4991-31.1861,29c-18.0551,20-9.4379,103.5-9.4379,126.5,0,0,27.493,111.5,32.4171,131.5C8359.9323,11790.7479,8381.8657,11925.5,8363.8106,11952.5Z"/><path
      style={spriteStyle}
      d="M6275,11245l7,211,23,266,70,143v96l-72.9991-.428L6269,11944c-24.6908-3.9619-51.6772,6.6187-76.4641,9.813l-75.21,7.187L5994,11957l1-148-2-195a42.29,42.29,0,0,1,3.1039-16.92c11.193-29.6992,31.4235-104.0268,31.8961-112.08l-9-246c10-1,53.3062-4.1289,77-4,52.999.2886,83.6512,0,83.6512,0h37.0934a91.6361,91.6361,0,0,1,18.3239,1.8507Z"/><path
      style={spriteStyle}
      d="M5931,11961s-313.0678-.6722-378-2c-64.41-1.3278-200.3486,12.9795-231,5-8.022-2.0884-45.8794,3.1538-61,5,0,0-60.0675,8.4872-83.61,2.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589.7012-53.7039,9.2132-62.39,16.6582-17,56-25,56-25l20-35,26-30c17.6993-21,35.0284-117,9-154,0,0-31.9486-117.6655-33-119,0,0-81.7694-93.9306-90-112-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,10.0081.8011,127.9294,1.6811,183.922,2.9643C5962.2714,11229.3294,5977,11235,5977,11235l16,127-25,102-33,30s-31.451,25.4991-58,40c-22.905,20,34,110,34,133,0,0-2.2468,84,4,104C5919.45,11785.2479,5953.905,11934,5931,11961Z"/><path
      style={spriteStyle}
      d="M7555,11247v421l19.336,93.3285a71.2323,71.2323,0,0,0,19.23,30.3333L7636,11835l9,118H7462c-18.0449,0-47.9865,6.9815-65.9166,4.95L7273,11944l2-143,13-250,20.7333-66.1521a707.5675,707.5675,0,0,0-2.8636-173.3424l-10.2077-72.7855c-.354-2.524,18.7909-5.6284,21.338-5.72l117-4,57.217,3.866C7502.0844,11233.6,7518,11238,7527,11243Z"/><path
      style={spriteStyle}
      d="M7225.5,11952.5s-384.7017-2.5837-447.0683-3.9116c-61.8656-1.3278-172.3275,7.5454-202.749,7.5454C6561.993,11954.806,6531,11956,6531,11956c-7.3561-1.3278-43.3414,4.1646-52,2-8-2,3-81,3-81,14-6,67-45,67-45l3-71c11-39,20.5-148.5-4.5-185.5,0,0-9.8464-20.4779-21.0921-43.8037a314.859,314.859,0,0,0-66.117-91.0849c-5.001-4.7732-9.1833-9.794-11.2909-14.6114-7-16-11.98-70.8818-13-92-1.01-21.1116-1.9452-42.2777,5.6371-58.9048,3.656-8.0171,27.886-28.732,38.0222-36.3853,6.5808-3.7,35.8382-6.9151,60.6893-9.2505,24.8357-2.322,42.7706-12.9549,69.6514-10.4594,26.8706,2.4888,157.6708.3183,200.203,3.393,51.1837,3.7,289.11.5,311.9284,1.167,25.347.6673,132.9007,5.89,143.8686,11.44l17,152-21,66-35.5,36.5s-12.5,14.4991-38,29c-22,20-11.5,103.5-11.5,126.5,0,0,33.5,111.5,39.5,131.5C7220.7744,11790.7479,7247.5,11925.5,7225.5,11952.5Z"/><path
      style={spriteStyle}
      d="M4995,11245v127l19,328,81,165v96l-72.9991-.428L4989,11944c-24.6908-3.9619-51.6772,6.6187-76.4641,9.813l-75.21,7.187L4714,11957l1-148-2-195a42.29,42.29,0,0,1,3.1039-16.92c11.193-29.6992,35.4235-121.0268,35.8961-129.08l-16-231s2.2762-1,32-1c53,0,131.6512-1,131.6512-1h37.0934a91.6361,91.6361,0,0,1,18.3239,1.8507Z"/><path
      style={spriteStyle}
      d="M4651,11961s-356.0678-2.6722-421-4c-64.41-1.3278-157.3486,14.9795-188,7-8.022-2.0884-45.8794,3.1538-61,5,0,0-60.0675,8.4872-83.61,2.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589.7012-53.7039,9.2132-62.39,16.6582-17,56-25,56-25l20-35,26-30c17.6993-21,25.0284-142-1-179,0,0-21.9486-92.6655-23-94,0,0-81.7694-93.9306-90-112-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,10.0081.8011,127.9294,1.6811,183.922,2.9643,15.9282.3651,27.3869-3.7342,29.6568,12.0357l17,121-25,102-33,30s-31.451,25.4991-58,40c-22.905,20,12,84,12,107,0,0,19.7532,110,26,130C4639.45,11785.2479,4673.905,11934,4651,11961Z"/><path
      style={spriteStyle}
      d="M3715,11245v127l6,326,90.2791,145L3815,11961l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187L3434,11957l1-148-2-195a42.29,42.29,0,0,1,3.1039-16.92c11.193-29.6992,35.4235-121.0268,35.8961-129.08l-16-231s-6.7238-1,23-1c26.0465,0,140.6512-1,140.6512-1h37.0934a91.6361,91.6361,0,0,1,18.3239,1.8507Z"/><path
      style={spriteStyle}
      d="M3371,11961s-364.5223-3.0837-429.4545-4.4116c-64.41-1.3278-147.8726,7.4116-179.5455,7.4116-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.61.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,51-35,51-35l20-35,26-30c17.6993-21,25.0284-142-1-179,0,0-30.2457-126.1655-31.2971-127.5,0,0-73.4723-60.4306-81.7029-78.5-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,193.1574,2.1231,211.5788,4l19,132-11,89-47,43s-31.451,25.4991-58,40c-22.905,20,12,84,12,107,0,0,19.7532,110,26,130C3359.45,11785.2479,3393.905,11934,3371,11961Z"/><path
      style={spriteStyle}
      d="M2435,11245v127l6,299,37,93,53.2791,79L2535,11961l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566L2299,11962l-145-5,1-148-2-195a42.29,42.29,0,0,1,3.1039-16.92c11.193-29.6992,35.4235-121.0268,35.8961-129.08l-16-231s10.2762-1,40-1c26.0465,0,123.6512-1,123.6512-1h37.0934a91.6361,91.6361,0,0,1,18.3239,1.8507Z"/><path
      style={spriteStyle}
      d="M2091,11961s-324.0678,1.3278-389,0c-64.41-1.3278-188.3271,3-220,3-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.61.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,51-35,51-35l20-35,35-52c12-47,16.0284-120-10-157,0,0-24.9486-106.6655-26-108,0,0-78.7694-79.9306-87-98-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.6438-7.3746,63.5171-9.71,25.8574-2.322,44.1987-12.4955,72.1853-10,27.976,2.4888,208.533,4.9252,252.8147,8,53.2892,3.7,159.7358-10.8958,183.4212-9,12.4936,1,193.1574,2.1231,211.5788,4l24,103-16,118-47,43s-31.451,25.4991-58,40c-22.905,20,12,84,12,107,0,0,19.7532,110,26,130C2079.45,11785.2479,2113.905,11934,2091,11961Z"/><path
      style={spriteStyle}
      d="M1155,11262v135l6,301,90.2791,145L1255,11961l-72.9991-.428-34.9141-5.6024a260.2635,260.2635,0,0,0-74.5509-1.1566l-75.21,7.187L874,11957l1-148-2-181c-.2948-5.7629,1.0688-25.5205,3.1039-30.92,11.193-29.6992,35.4235-121.0268,35.8961-129.08l-24-231s1.2762-1,31-1c26.0465,0,140.6512-1,140.6512-1h37.0934a91.6361,91.6361,0,0,1,18.3239,1.8507Z"/><path
      style={spriteStyle}
      d="M811,11961s-364.5223-3.0837-429.4545-4.4116C317.1351,11955.2606,233.6729,11964,202,11964c-14.2528-1.3278-76,7-76,7s-45.0675,6.4872-68.61.83a7.7453,7.7453,0,0,1-5.6027-10.44c3.6454-8.7589,5.7012-43.7039,14.2132-52.39,16.6582-17,51-35,51-35l20-35,26-30c17.6993-21,25.0284-142-1-179,0,0-25.9486-108.6655-27-110,0,0-77.7694-77.9306-86-96-7.288-16-22.91-60.8818-23.9723-82-1.0514-21.1116-2.0253-42.2777,5.869-58.9048,3.8064-8.0171,29.033-28.732,39.5862-36.3853,6.8515-3.7,37.3125-6.9151,63.1858-9.2505,25.8574-2.322,44.53-12.9549,72.5166-10.4594,27.976,2.4888,164.1567.3183,208.4385,3.393,53.2892,3.7,204.112-6.2888,227.7974-4.393,12.4936,1,189.1574,1.1231,207.5788,3l13.64,129.5849a53.8767,53.8767,0,0,1,.2824,6.8483L862,11451l-47,43s-31.451,25.4991-58,40c-22.905,20,15,101,15,124,0,0,16.7532,93,23,113C799.45,11785.2479,833.905,11934,811,11961Z"/><path
      style={spriteStyle}
      d="M6189,12357l48,84,42,130,44.0838,71.76,41.9162,7.807L6370,12757l-499,8-50.87-11.7847a136.5334,136.5334,0,0,0-65.82,1.9645L5725,12758.6772l-47-1.0538-9-310.87,30-144.37,73-170.1994s38.5687-22.6455,41.5288-22.6455c20.7206,0,63.4712-4.2152,63.4712-4.2152L6069,12080l111,17Z"/><path
      style={spriteStyle}
      d="M5376.2763,12760.1088s-125.0577.2272-148.4838.2272c-10.5418-2.1262-65.9731,5.4849-77.2925.164.3839-5.3209-6-65-6-73,0-10.2956,27.6257-49.7586,27.6257-49.7586L5198,12596l21-77-30.2382-62.136c-1.1713-3.1947-13.5452-35.9954-14.7165-39.19s-8.18-17.9606-8.9579-20.0975c-14.2971-24.9746,2.698-128.76,1.9126-162.5764-.7776-33.806-3.3627-69.2027.9075-96.762,1.7048-11.002,20.1331-43.9661,27.9385-56.2213,11.3194-17.0419,16.1775-31.093,35.3141-34.8326,19.1248-3.7182,81.64-10.68,102.34-6.6841,20.6918,3.9853,136.4284-10.0685,154-9,19.5185,1.0685,142.0517-5.8125,163.1667-.7881,10.9005,2.5938,58.3333.2881,58.3333.2881,24,0,81.349,4.3115,83,28l-14,46-96,52-45,24-24,60c-5.735,8.7534-6.0379,59.6923-8.31,69.9076-4.69,21.0924,4.4549,36.0718,6.31,42.0924,2.5346,8.2592,14,82,14,82,2.9283,7.7249-10.2616,139.9516-10.2667,140.3141C5614.2,12613.808,5636,12699,5622,12747c-.1185.4063-73.5,5.5-73.5,5.5-7.03,5.8551-75.4861,15.7222-79,16C5465.9861,12768.7671,5376.2763,12760.1088,5376.2763,12760.1088Z"/><path
      style={spriteStyle}
      d="M4869,12404.6013l52,69.5506,90.8325,132.7785,31.2513,35.8291,26.9162,15.807L5075,12765H4591l-50.87-11.7847a136.5334,136.5334,0,0,0-65.82,1.9645L4445,12758.6772l-47-1.0538-9-310.87,30-144.37,79-180.1994s32.5687-12.6455,35.5288-12.6455c20.7206,0,63.4712-4.2152,63.4712-4.2152L4775,12099l58,84.3038Z"/><path
      style={spriteStyle}
      d="M4096.2763,12760.1088s-125.0577.2272-148.4838.2272c-10.5418-2.1262-65.9731,5.4849-77.2925.164.3839-5.3209-6-65-6-73,0-10.2956,27.6257-49.7586,27.6257-49.7586L3918,12596l21-77-30.2382-62.136c-1.1713-3.1947-13.5452-35.9954-14.7165-39.19s-8.18-17.9606-8.9579-20.0975c-14.2971-24.9746,2.698-128.76,1.9126-162.5764-.7776-33.806-3.3627-69.2027.9075-96.762,1.7048-11.002,20.1331-43.9661,27.9385-56.2213,11.3194-17.0419,16.1775-31.093,35.3141-34.8326,19.1248-3.7182,81.64-10.68,102.34-6.6841,20.6918,3.9853,136.4284-10.0685,154-9,19.5185,1.0685,142.0517-5.8125,163.1667-.7881,10.9005,2.5938,58.3333.2881,58.3333.2881,24,0,58.349,20.3115,60,44l-74,96-66,50-16,51c-5.735,8.7534,1.4429,35.1125-8.31,38.9076-19.1675,7.4588-20.5451,49.0718-18.69,55.0924,2.5346,8.2592,3,69,3,69,2.9283,7.7249,25.7384,139.9516,25.7333,140.3141C4334.2,12613.808,4355.5,12698.5,4341.5,12746.5c-.1185.4063-73,6-73,6-7.03,5.8551-75.4861,15.7222-79,16C4185.9861,12768.7671,4096.2763,12760.1088,4096.2763,12760.1088Z"/><path style={spriteStyle} d="M3535,12429l44,70,152.8325,116,31.2513,34L3790,12664l5,101H3311l-50.87-11.1831a143.6481,143.6481,0,0,0-65.82,1.8643L3165,12759l-47-1,23-274,29-132,30-219s32.5687-12,35.5288-12c20.7206,0,63.4712-4,63.4712-4l112,8,30,90Z"/><path
      style={spriteStyle}
      d="M2816.2763,12760.1088s-125.0577.2272-148.4838.2272c-10.5418-2.1262-65.9731,5.4849-77.2925.164.3839-5.3209-6-65-6-73,0-10.2956,27.6257-49.7586,27.6257-49.7586l14.7165-49.2389,9.5978-75.3655-7.6782-56.273c-1.1713-3.1947-13.5452-35.9954-14.7165-39.19s-8.18-17.9606-8.9579-20.0975c-14.2971-24.9746,24.92-141.2712,24.1349-175.0878-.7776-33.806.415-64.6913,4.6852-92.2506,1.7048-11.002,20.1331-43.9661,27.9385-56.2213,11.3194-17.0419,16.1775-31.093,35.3141-34.8326,19.1248-3.7182,55.64-2.68,76.34,1.3159,20.6918,3.9853,136.4284-10.0685,154-9,19.5185,1.0685,142.0517-5.8125,163.1667-.7881,10.9,2.5938,58.3333.2881,58.3333.2881,24,0,58.349,20.3115,60,44l-62,126-57.5,58.5-21.0213,32.0851a50.2564,50.2564,0,0,1-23.7883,19.3225c-19.1675,7.4588-46.0451,15.5718-44.19,21.5924,2.5346,8.2592,4.7,88.0729,4.7,88.0729,2.9283,7.7249,49.5384,154.3787,49.5333,154.7412C3054.2,12613.808,3075.5,12698.5,3061.5,12746.5c-.1185.4063-73,6-73,6-7.03,5.8551-75.4861,15.7222-79,16C2905.9861,12768.7671,2816.2763,12760.1088,2816.2763,12760.1088Z"/><path
      style={spriteStyle}
      d="M858.4677,12039,873,12186l66,279,153,72c5.2215,11.5876,68.182,46.3592,80.6885,54.6618L1232.3468,12635,1245,12753H987.7177c-25.37,0-67.4648,6.9815-92.673,4.95l-81.3-15.9539a299.4575,299.4575,0,0,0-82.1279-4.6035L651,12744l-11-149,6.0887-246L655,12246c8.6526-57.68,8.7881-124.5055,8-125l-6.1392-84.28c-.4977-2.524,26.4184-5.6284,29.9994-5.72l58.14,1,50-1c15.2786.7343,11.4489-1,24.1022,4Z"/><path
      style={spriteStyle}
      d="M501.6078,12746.5s-204.1709,3.4163-244.7279,2.0884c-40.2311-1.3278-112.0643,7.5454-131.8474,7.5454-8.9023-1.3278-29.0571-.1338-29.0571-.1338-4.7836-1.3278-60.6726,7.1646-66.3033,5-5.2024-2,1.9509-81,1.9509-81,9.1042-6,43.57-45,43.57-45l18.751-70c7.1533-39,21.69-53.5,5.4322-90.5,0,0-6.4032-20.4779-13.7162-43.8037-10.68-34.0641-25.2065-64.9748-42.9958-91.0849A52.65,52.65,0,0,1,35.3217,12325c-4.552-16,29.5387-159.8818,28.8754-181-.6567-21.1116-1.265-42.2777,3.6658-58.9048,2.3775-8.0171,18.1342-28.732,24.7258-36.3853,4.2794-3.7,23.3055-6.9151,39.4661-9.2505,16.1507-2.322,27.8137-12.9549,45.2942-10.4594,17.474,2.4888,121.9924,1.9252,149.651,5,33.2847,3.7,138.5484-11.1072,153.3874-10.44,16.4831.6673,134.4251,3.89,141.5575,9.44L633,12185l-13.6563,66-23.0856,36.5S504.5826,12325.4991,488,12340c-14.3066,20-17.9317,76-17.9317,99,0,0,21.785,111.5,25.6868,131.5C498.5348,12584.7479,515.9144,12719.5,501.6078,12746.5Z"/><path
      style={spriteStyle}
      d="M9375.2446,12756.6392s-37.0175-1.0488-111.0556-3.136c-73.4432-2.0872-226.9791-10.9561-237.21-14.606-9.0286-3.66-40.477-201.7779-44.0885-234.7111-3.6114-32.9228,27.0912-86.8686,52.9165-100.2462,10.5943-35.43-5.9388-123.1624-5.9388-123.1624l-6.1456-124.4485c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,68.9182,16.6763,95.782,20.8123,29.7582,4.5815,56.2737,6.1461,78.5494,15.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381,3.76,74.73-1.3464,96.2894l-35.02,39.06s11.0857,32.2937,15.6,39.8662q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,4.9673,84.2963,4.9673,125.0643q0,9.408.45,20.3892.4439,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4532-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761q-16.2561,8.6214-45.1464,10.9812c-4.8253.514-9.9315.902-15.3487,1.1747q-8.1258.3933-16.2516.3881Z"/><path style={spriteStyle} d="M10022.6366,12065.7789l26.5878,180.6522,27.9288,97.0859,121.9916,287.14-7.7418,55.839-607.6588,48.4551L9573.77,12385.52l43.3629-299.4012Z"/><path
      style={spriteStyle}
      d="M9375.2446,12756.6392s-37.0175-1.0488-111.0556-3.136c-73.4432-2.0872-226.9791-10.9561-237.21-14.606-9.0286-3.66-40.477-201.7779-44.0885-234.7111-3.6114-32.9228,27.0912-86.8686,52.9165-100.2462,10.5943-35.43-5.9388-123.1624-5.9388-123.1624l-6.1456-124.4485c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,68.9182,16.6763,95.782,20.8123,29.7582,4.5815,56.2737,6.1461,78.5494,15.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381,3.76,74.73-1.3464,96.2894l-35.02,39.06s11.0857,32.2937,15.6,39.8662q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,4.9673,84.2963,4.9673,125.0643q0,9.408.45,20.3892.4439,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4532-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761q-16.2561,8.6214-45.1464,10.9812c-4.8253.514-9.9315.902-15.3487,1.1747q-8.1258.3933-16.2516.3881Z"/><path style={spriteStyle} d="M10022.6366,12065.7789l26.5878,180.6522,27.9288,97.0859,121.9916,287.14-7.7418,55.839-607.6588,48.4551L9573.77,12385.52l43.3629-299.4012Z"/><path style={spriteStyle} d="M8359,12117l-107,284v352l467-9,203-3c.2783,0-3-116-3-116-8-7-123-242-123-242,.2783,0-35-276-35-276l-250-10Z"/><path
      style={spriteStyle}
      d="M7886,12755s-49-2-78.2783-1c-44.1649,1.5084-81.2587,5.3882-91.7461,0,.0244,0-1.6973-23-1.9756-45-.1211-9.5759-11-116-11-116l28-21c.15-.0334,27-21,27-21,5.2167-11,5.4427-50.224,4-54-1.4482-3.7652,3.8815-28.1573-1-46-.9-3.2913-16.2795-23.8361-17-26-11.9374-16.1753-7.383-43-8-43-.384,0-8.2723-129.756-9-164-.7205-34.2331,4.59-68.0385,10-95,2.6084-13,16.7683-34.59,24-47,4.6951-6,32.6281-9.2131,50.3581-13,17.7192-3.7652,35.0759-2.948,54.3636-2,20.3452,1,133.3423-19.1992,163.2783-22,7.3477-.6875,168.78,8.4771,200,10,26.0586,1.271,26.6465,16.2827,37,22l18,39-12,33s-25,36-35,39c-19.7087,5.9126-58,32-58,32-9,13,15,59,23.0368,80.0177,2.7876,7.29,8.9539,12.8212,11.6669,20.633,2.7131,7.8225,5.3037,26.5154,7.2963,48.3493,1.9835,21.8339-11.13,136.9443-11.13,179,0,6.47,1.043,29.451,1.23,37.003q.2667,11.3281.8148,22.6454.5336,15.3693,1.0852,29.927v65.5017c0,18.88-3,51.9229-3,51.9229.6517,0-106.4274,19.3771-118,21C8089.1,12770.53,7886,12755,7886,12755Z"/><path style={spriteStyle} d="M7046,12151l-95,249c-3.0643.1262-2,363-2,363s181-7,205-7c17.8676,0,75.0155,3.6722,93.1194,3.4689,138.826-1.5584,276.5866-2.9437,289.8806-2.4689h113c.2783,0-3-116-3-116-8-7-71-70-71-70l-60-188c.2783,0-35-276-35-276l-265,21Z"/><path
      style={spriteStyle}
      d="M6606,12767s-49-2-78.2783-1c-44.1649,1.5084-81.2587,5.3882-91.7461,0,.0244,0-1.6973-23-1.9756-45-.1211-9.5759-11-91-11-91l55-34c.15-.0334,18-18,18-18,5.2167-11,4.4427-68.224,3-72-1.4482-3.7652-4.1185-21.1573-9-39-.9-3.2913-25.2795-39.8361-26-42-11.9374-16.1753-7.383-43-8-43-.384,0-8.2723-129.756-9-164-.7205-34.2331,4.59-68.0385,10-95,2.6084-13,16.7683-34.59,24-47,4.6951-6,32.6281-9.2131,50.3581-13,17.7192-3.7652,35.0759-2.948,54.3636-2,20.3452,1,133.3423-19.1992,163.2783-22,7.3477-.6875,244-13,275-9s45,17,45,17l10,45-54,33s-41,24-51,27c-19.7087,5.9126-82,42-82,42-9,13,0,83,8.0368,104.0177,2.7876,7.29,8.9539,12.8212,11.6669,20.633,2.7131,7.8225,5.3037,26.5154,7.2963,48.3493,1.9835,21.8339-11.13,136.9443-11.13,179,0,6.47,1.043,29.451,1.23,37.003q.2667,11.3281.8148,22.6454.5336,15.3693,1.0852,29.927v65.5017c0,18.88-3.6517,62.9229-3.6517,62.9229.6517,0-83.7757,4.3771-95.3483,6C6809.1,12770.53,6606,12767,6606,12767Z"/><path style={spriteStyle} d="M2197.5,12493l73.4738,25,180.8587,97,31.2513,34L2510,12664l5,101H2031l-50.87-11.1831a143.6481,143.6481,0,0,0-65.82,1.8643L1885,12759l-47-1,23-274,29-132,6-313s47.5687-4,50.5288-4c20.7206,0,63.4712-4,63.4712-4l66,5,6,116Z"/><path
      style={spriteStyle}
      d="M1536.2763,12760.1088s-125.0577.2272-148.4838.2272c-10.5418-2.1262-65.9731,5.4849-77.2925.164.3839-5.3209-6-65-6-73,0-10.2956,27.6257-49.7586,27.6257-49.7586l14.7165-49.2389,9.5978-75.3655-7.6782-56.273c-1.1713-3.1947-13.5452-35.9954-14.7165-39.19s-8.18-17.9606-8.9579-20.0975c-14.2971-24.9746,24.92-141.2712,24.1349-175.0878-.7776-33.806.415-64.6913,4.6852-92.2506,1.7048-11.002,20.1331-43.9661,27.9385-56.2213,11.3194-17.0419,16.1775-31.093,35.3141-34.8326,19.1248-3.7182,55.64-2.68,76.34,1.3159,20.6918,3.9853,136.4284-10.0685,154-9,19.5185,1.0685,142.0517-5.8125,163.1667-.7881,10.9,2.5938,53.8333,9.7881,53.8333,9.7881,5.7586,16.078,3.349,63.3115,5,87l-2.5,73.5-57.5,58.5-21.0213,32.0851a50.2564,50.2564,0,0,1-23.7883,19.3225c-19.1675,7.4588-46.0451,15.5718-44.19,21.5924,2.5346,8.2592,4.7,88.0729,4.7,88.0729,2.9283,7.7249,49.5384,154.3787,49.5333,154.7412C1774.2,12613.808,1795.5,12698.5,1781.5,12746.5c-.1185.4063-73,6-73,6-7.03,5.8551-75.4861,15.7222-79,16C1625.9861,12768.7671,1536.2763,12760.1088,1536.2763,12760.1088Z"/><path style={spriteStyle} d="M3743,6902l56,114,14,149-216-7,5-76,4-48,10-67-3-201,22-278s-4.4518-55,0-55c31.1625,0,65,2,65,2h48l4,80Z"/><path
      style={spriteStyle}
      d="M3108.5305,6435c-14.435,2.6416-159.496-8.802-268.0668-4-14.125.6248-151.3737,10-184.882,27-23.9866,12.1693-55.5545,89.7249-56.3272,117-.3158,11.1444,2.366,102-2.0483,172-.4879,7.7365,73.2715,108.5881,75.7938,114,8.1439,17.4735,29,92,29,92l-4.9938,45c-3.1414,26-64.1791,62-72.2524,74,0,0-34.5555,9-33.5046,20,1.0465,10.9545-1.2743,3.91-2.5281,36.24s2.34,24.76,2.34,24.76,48.4327,2.0525,61.4479,2c11.6974-.0472,406.8058,12,406.8058,12h59.6867c3.7973,0,142.41-14,159.1646-17,5.4962-.9841,263.8782,4,263.8782,4s30.9466-239.7032,21.9419-287c-2.0943-11-6.1448-117-6.1448-117s-44.9551-30.2283-49.3055-33c-7.8477-5-35.2-51-35.2-51-1.1512-21,42.37-59,47.7656-66L3579,6533s27.9656-73.98,27.967-74c1.4931-22-48.1682-32-48.1682-32-8.9139-.5126-54.5961-.3864-58.1283-1C3471.8865,6421,3155.4429,6426.415,3108.5305,6435Z"/><path
      style={spriteStyle}
      d="M437.4352,751.7135s-36.6214-1.0376-109.8671-3.1024c-72.6573-2.0649-234.6711-14.45-244.7921-18.0606C73.8439,726.9293,43.4021,534.2067,49.28,501.962c6.7431-36.9891,22.4861-62.68,14.3771-139.1576C60.8851,336.658,46.4536,296.0483,39.944,258.5775c-5.6229-32.3665-8.8779-85.2247-2.93-112.1194Q45.9534,106.1317,63.81,88.29q25.8956-24.8247,69.673-30.2566C151.9582,55.7463,366.618,41.1593,366.618,41.1593s130.44,7.8516,183.8212,26.8932c22.4309,8.0014,34.8379,24.7054,38.4108,46.9309,2.9723,16.55,7.4262,60.9631,8.92,82.1614,1.4817,21.2087,3.72,73.93-1.3319,95.259l-13.7625,36.2968s-9.9154,34.2931-5.4493,41.7846q6.699,11.2527,11.6116,42.6559c3.2652,20.9389,4.9141,83.3942,4.9141,123.7259q0,9.3074.4452,20.1711.439,10.8636,1.3413,21.717.8783,14.7393,1.7864,28.7v62.8166q0,27.1593-7.1457,43.8284a50.19,50.19,0,0,1-23.2233,25.2034q-16.0821,8.5291-44.6632,10.8638c-4.7737.5084-9.8252.8923-15.1845,1.1621q-8.0388.3891-16.0777.3839Z"/><path style={spriteStyle} d="M1096.9383,62.7619l32.7722,251.4946L1252.5185,627.08v55.0657L621.8208,724.9516,641.5976,76.5981Z"/><path
      style={spriteStyle}
      d="M2901.0262,748.5522q-111.0549-3.1562-165.6524-3.1614-24.5742-3.156-37.7732-6.3226a279.308,279.308,0,0,1-28.6715-8.6911q-13.6525-5.535-40.0471-13.4383.8949-7.8981-3.6407-32.7975-4.5432-24.9-10.9219-54.1339-19.1133-82.993-24.5743-132.7867c-3.6407-33.1887,35.7778-100.27,50.34-101.3381,24.9346-14.3294,41.6425-26.4572,41.6425-26.4572s-28.847-25.5606-36.0989-40.9126c-1.5581-3.2984-11.574-37.6825-12.7824-39.7971-14.956-33.6654,9.769-52.2477,19.894-84.1662,11.698-36.8773,36.2261-37.799,42.2867-65.2043,6.0728-27.3947,31.7577-41.0705,43.8881-53.1978,17.5914-16.8639,17.0182-30.7948,46.5481-35.9084,13.3778-2.3166,45.2471-9.7028,58.5175-8.9984,32.3524,1.7175,67.99-15.115,118.365-5.631,62.4875,12.1272,61.5985,31.9546,88.8627,30.0837,50.7813-3.4848,94.6695,28.4338,95.8445,87.966.4275,21.66,10.7945,70.2411,4.1448,94.7413l-20.8053,44.9088-28.04,20.9629c3.0288,13.7132,4.5193,22.64,8.4735,30.8132q5.9084,12.2593,10.9219,17.7732a111.814,111.814,0,0,0,14.106,12.6454,64.8264,64.8264,0,0,1,19.57,20.1627c4.5554,7.6416,8.6948,31.5427,12.0372,52.879,3.3271,21.3364-15.2822,35.66-15.2822,76.7575q0,9.484.4536,20.554.4475,11.07,1.3667,22.1293.8951,15.019,1.82,29.245v64.0091q0,27.6749-7.2813,44.6605a51.1438,51.1438,0,0,1-23.6642,25.6818q-16.3875,8.6912-45.5111,11.07c-4.8643.5181-10.0118.9093-15.4727,1.1842q-8.1915.3964-16.3829.3912C3011.1008,744.2284,2975.6624,750.6562,2901.0262,748.5522Z"/><path style={spriteStyle} d="M3611.2642,56.3384l-2.5655,63.0576L3626.6,204.0281l3.489,42.7406a121.21,121.21,0,0,0,6.0365,29.1165l66.9994,197.2758,95.8015,147.4965L3788.241,685.952l-588.9237,16.7661-2.7936-374.6573,25.1614-261.8625Z"/><path
      style={spriteStyle}
      d="M1695.2446,746.6392s-37.0175-1.0488-111.0556-3.136c-73.4432-2.0872-226.9791-10.9561-237.21-14.606-9.0286-3.66-40.477-201.7779-44.0885-234.7111-3.6114-32.9228,27.0912-86.8686,52.9165-100.2462,10.5943-35.43-5.9388-123.1624-5.9388-123.1624L1343.723,146.329c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,68.9182,16.6763,95.782,20.8123,29.7582,4.5815,56.2737,6.1461,78.5494,15.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381,3.76,74.73-1.3464,96.2894l-35.02,39.06s11.0857,32.2937,15.6,39.8662q6.7716,11.3747,11.7373,43.1174c3.3,21.1653,4.9673,84.2963,4.9673,125.0643q0,9.408.45,20.3892.4439,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4531-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761q-16.2561,8.6214-45.1464,10.9812c-4.8253.514-9.9315.902-15.3487,1.1747q-8.1258.3933-16.2516.3881Z"/><path style={spriteStyle} d="M2342.6366,55.7789l26.5878,180.6522,27.9288,97.0859,121.9916,287.1405-7.7418,55.839-607.6588,48.4551L1893.77,375.52l43.3629-299.4012Z"/><path
      style={spriteStyle}
      d="M4181.3456,749.7329q-111.23-3.1609-165.9135-3.1663-24.6132-3.1611-37.8328-6.3327a279.7123,279.7123,0,0,1-28.7168-8.7047q-13.674-5.5437-40.11-13.4595.8962-7.9105-3.6464-32.8493-4.55-24.9387-10.9392-54.2192-19.1435-83.1239-24.6131-132.9961c-3.6464-33.241,33.1756-111.2262,47.7611-112.2958,24.974-14.3521,64.6185-29.8755,70.5982-43.6739,6.9666-16.0756-23.3735-21.921-30.6369-37.2971-1.5606-3.3037-4.1178-39.1754-5.3282-41.2933-14.98-33.7185-2.1487-34.6333,7.9923-66.6021,11.7164-36.9354,29.8949-33.8254,35.9651-61.2739,6.0824-27.4379,37.136-37.1391,49.2855-49.2855,17.6191-16.8906,20.1589-36.4844,49.7353-41.606,13.399-2.32,15.9733-4.0772,29.2646-3.3716,32.4034,1.72,92.9961-18.06,143.4509-8.5608,62.5862,12.1464,63.8636,36.5357,91.1709,34.6618,50.8614-3.49,67.7515,26.8689,68.9284,86.4949.4281,21.6939,10.8115,70.3519,4.1513,94.8907l-20.8381,44.98-28.0846,20.9959c3.0336,13.7349,4.5264,22.676,8.4868,30.8618q5.9178,12.2789,10.9392,17.8013a111.9894,111.9894,0,0,0,14.1282,12.6653,64.9262,64.9262,0,0,1,19.6008,20.1945c4.5627,7.6536,8.7086,31.5924,12.0562,52.9625,3.3324,21.37-15.3062,35.7163-15.3062,76.8785q0,9.4989.4543,20.5863.448,11.0875,1.3689,22.1643.8962,15.0426,1.8232,29.2911v64.11q0,27.7185-7.2928,44.7308c-4.872,11.3416-18.7565,17.9106-29.6957,23.7244q-16.4133,8.7046-45.5829,11.0874c-4.872.5189-10.0275.9107-15.4971,1.186q-8.2044.3972-16.4087.3919C4285.6,743.4043,4256.1,751.84,4181.3456,749.7329Z"/><path style={spriteStyle} d="M4864.42,57.1433l25.7141,62.4412,8.9148,90.5788,2.6641,71.1205,77.2525,207.5878,99.9089,92.9312L5069.55,708.3467l-589.4454-4.4279.9051-371.196,21.5-266.42Z"/><path
      style={spriteStyle}
      d="M5461.589,748.5522q-111.0549-3.1562-165.6524-3.1614-24.5743-3.156-37.7732-6.3226a279.3093,279.3093,0,0,1-28.6716-8.6911q-13.6524-5.535-40.047-13.4383.8949-7.8981-3.6407-32.7975-4.5432-24.9-10.9219-54.1339-19.1134-82.993-24.5744-132.7867c-3.6406-33.1887,33.1234-111.0511,47.686-112.119,16.4881-8.1069,71.3112-22.4176,75.0654-36.9554,4.1514-16.0759-14.6921-38.5107-21.944-53.8627-1.5582-3.2984-2.1454-57.8527,7.98-89.7712,11.698-36.8773,22.8657-42.4167,28.9263-69.822,6.0728-27.3947,40.62-46.9237,52.7509-59.0509,17.5913-16.864,71.2687-32.3486,93.6779-37.346,30.9131-6.894,86.15-17.0084,136.5251-7.5244,62.4875,12.1272,69.0309,20.9429,96.2126,23.77,41.7607,4.3441,57.5424,34.5831,58.7174,94.1153.4275,21.66,11.637,78.238,4.9873,102.7381l-28.1145,36.5845-27.9692,30.5765c3.0288,13.7132,4.16,40.6254,7.502,44.3048a111.814,111.814,0,0,0,14.106,12.6454,64.8249,64.8249,0,0,1,19.57,20.1627c4.5555,7.6416,8.6949,31.5427,12.0372,52.879,3.3272,21.3364-5.9339,34.2631-5.9339,75.3605q0,9.4839.4536,20.5539.4475,11.07,1.3667,22.1294.8949,15.0189,1.82,29.245c0,6.8513-9.3482,18.9165-9.3482,33.4016,0,14.4956-17.5467,56.686-22.3958,68.02-4.8643,11.3237-69.3621,36.3175-88.7737,37.9034C5600.3483,735.678,5536.2252,750.6562,5461.589,748.5522Z"/><path style={spriteStyle} d="M6143.5878,57.0531l25.6736,62.3429.5123,98.4159,11.97,90.4361,21.2791,89.1062,42.5035,85.5441,110.5992,65.0445-4.149,148.6488-355.095,15.4242L5767.8575,702.81l-1.0364-374.7708,15.322-253.9047L5998.211,43.5892Z"/><path
      style={spriteStyle}
      d="M6741.37,748.5522q-111.0549-3.1562-165.6524-3.1614-24.5743-3.156-37.7732-6.3226a279.3093,279.3093,0,0,1-28.6716-8.6911q-13.6524-5.535-40.047-13.4383.8949-7.8981-3.6407-32.7975-4.5432-24.9-10.9219-54.1339-19.1134-82.993-24.5744-132.7867c-3.6406-33.1887,33.1234-111.0511,47.686-112.119,16.4881-8.1069,87.1942-24.4125,90.9484-38.95,4.1514-16.0759-.7277-46.49-7.98-61.8424-1.5581-3.2984-2.1453-57.8527,7.98-89.7712,11.698-36.8772-6.9817-32.4421-.9211-59.8474,6.0728-27.3947,40.62-46.9237,52.7509-59.0509,17.5913-16.864,71.2687-32.3486,93.6779-37.346,30.9131-6.894,86.15-17.0084,136.5251-7.5244,62.4875,12.1272,69.0309,20.9429,96.2126,23.77,41.7606,4.3441,57.5424,34.5831,58.7174,94.1153.4275,21.66,11.637,78.238,4.9873,102.7381l-28.1145,36.5845L6954.59,318.5539c3.0288,13.7132,4.16,40.6254,7.502,44.3048a111.814,111.814,0,0,0,14.106,12.6454,64.8249,64.8249,0,0,1,19.57,20.1627c4.5555,7.6416,8.6949,31.5427,12.0372,52.879,3.3271,21.3364,4.3179,166.2053,4.3179,180.69,0,14.4956-33.5061,56.686-38.3552,68.02-4.8644,11.3237-69.3621,36.3175-88.7738,37.9034C6880.13,735.678,6816.0066,750.6562,6741.37,748.5522Z"/><path
      style={spriteStyle}
      d="M7423.3692,57.0531l25.6736,62.3429.5123,98.4159,11.97,90.4361,21.2791,89.1062,42.5035,85.5441,100.6246,65.0445-6.1439,148.6488-343.1255,19.414L7047.6389,702.81l-1.5672-334.5756,15.8528-294.1,189.73-26.8221a227.4954,227.4954,0,0,1,52.8233-1.27Z"/><path
      style={spriteStyle}
      d="M8021.2352,738.583c-74.0234-2.1037-131.1034,8.3873-165.6229-3.1608-21.2056-7.0941-107.0695-34.6692-110.113-51.2659q-4.5425-24.895-10.92-54.1243c-12.74-55.3188-11.453-99.9537-15.093-133.1471-3.64-33.1828,28.1418-123.6615,42.7018-124.7292,16.4852-8.1055,67.817-20.8538,90.7477-30.5759,25.9408-10.9982,38.836-75.0782,31.5854-90.4275-1.5579-3.2978-1.3909-20.1582-9.308-58.5071-7.8207-37.8825-8.3379-55.6368,8.5618-78.04,22.827-30.2606,28.5092-26.8493,40.6374-38.9744,17.5883-16.861,47.2833-32.2113,69.6885-37.2078,30.9077-6.8927,86.1344-17.0054,136.5009-7.5231,62.4765,12.1252,69.0187,20.9393,96.1956,23.7663,41.7532,4.3433,64.6209,37.2363,65.7957,96.758.4274,21.6558,4.5462,75.5646-2.1023,100.0605l-28.11,36.578-27.9642,30.571c3.0282,13.7108,14.6642,30.338,18.0059,34.0167a111.7941,111.7941,0,0,0,14.1035,12.6431,64.8136,64.8136,0,0,1,19.5665,20.1592c4.5546,7.64,8.6933,31.5371,12.0351,52.87,3.3265,21.3326-16.4382,44.5373-16.4382,85.6273q0,9.4824.4535,20.55.4474,11.0679,1.3665,22.1254.8947,15.0163,1.82,29.24c0,6.85-9.3465,18.9132-9.3465,33.3956,0,14.4931-27.5928,48.033-32.441,59.3653-4.8635,11.3217-59.3006,44.9541-78.7089,46.54C8159.97,735.6839,8095.8582,740.6866,8021.2352,738.583Z"/><path
      style={spriteStyle}
      d="M8695.6429,67.6527l33.1392,51.8586-6.5451,99.7281,3.1448,94.1093,21.8318,102.4654,57.8194,67.1349L8905.64,547.9821l-6.1428,148.6223L8556.4323,716.015l-238.9557-13.1931,43.7448-581.9809,159.3125-62.5147a142.8834,142.8834,0,0,1,76.6306-7.7685Z"/><path
      style={spriteStyle}
      d="M9302.371,739.3521c-73.9146-2.1006-130.9106,8.375-165.3794-3.1561-21.1744-7.0837-106.9121-36.61-109.9511-53.1822q-4.5357-24.8584-10.9039-54.0447c-12.7212-55.2374-11.4361-99.8067-15.0708-132.9513-3.6346-33.134,38.3143-103.5634,52.8529-104.6295,16.4609-8.0936,72.7011-34.108,95.5981-43.8158,14.186-6.0145,39.5027-45.2278,45.1036-56.3768,0,0,6.2732-41.441,7.3426-51.1711.3979-3.62-22.5348-17.6728-30.2826-41.8242-11.7984-36.7784-6.5675-83.9-6.5675-83.9,22.7935-30.216,28.4673-26.81,40.5777-38.917,17.5624-16.8362,47.2138-32.164,69.586-37.1532,30.8622-6.8825,86.0077-16.98,136.3-7.5119,62.3846,12.1072,68.9172,20.9084,96.0541,23.7313,41.6918,4.3369,64.5259,37.1815,65.699,96.6156.4268,21.6241,4.5395,75.4536-2.0993,99.9134l-28.0682,36.5242-27.9231,30.5261c3.0238,13.6906,14.6426,30.2934,17.98,33.9667a111.6281,111.6281,0,0,0,14.0827,12.6245,64.7174,64.7174,0,0,1,19.5377,20.13c4.548,7.6289,8.6806,31.4906,12.0174,52.7918,3.3216,21.3013-4.8672,156.4733-4.8672,170.9344,0,14.4718-44.7971,67.6857-49.6383,79.0013-4.8563,11.3051-59.2133,44.888-78.5931,46.4713A1138.31,1138.31,0,0,1,9302.371,739.3521Z"/><path
      style={spriteStyle}
      d="M9943.5127,72.7278l37.1771,55.7656,13.2775,95.0379,7.5148,89.22L10025,405l60.0162,77.1024,100.9639,52.7169,4.4946,135.397-61.4286,25.7367a73.3866,73.3866,0,0,1-25.0938,5.628l-280.1744,12.4754-229.8722.7693L9632.1548,82.022l147.0254-24.05a207.1969,207.1969,0,0,1,82.9606,1.49Z"/><path
      style={spriteStyle}
      d="M3104.9224,1547.6434s-119.6019,8.0614-196.1068,5.9047c-75.89-2.1568-132.482-7.24-169.8-7.24-16.7931-2.1567-29.7109-.3243-38.719-2.481a286.277,286.277,0,0,1-29.3894-8.9086c-9.3295-3.7824-41.6614-30.3783-44.7816-47.3935q-4.6569-25.523-11.1954-55.4893c-13.0613-56.714-11.3758-83.2207-15.1076-117.2512-3.7318-34.02,12.7195-65.0235,45.4076-71.2081,40.1911-32.247,47.0048-26.48,58.27-36.008,20.347-17.21,29.5146-114.3866,33.3407-126.08,2.4169-7.3867,13.8154-42.0728,8.0018-57.0981-15.7878-40.8038,4.5377-85.8736,11.079-111.5539,4.5646-17.92,42.8994-62.9056,49.3443-69.3487,18.0318-17.2863,126.8921-7.2455,157.3768-11.0387,30.466-3.7715,95.3085,5.3846,122.3243,12.7905,32.414,8.8857,85.39,10.84,108.4076,20.2795q34.5081,14.1921,40.12,49.0192a560.3331,560.3331,0,0,1,6.9956,59.1416q2.3214,33.2286,2.3339,63.1841l-56.55,80.0177-49.9756,25.5377c3.1046,14.0566,7.0029,23.8855,13.2246,28.7409q13.0583,8.9249,20.06,20.6676,6.9972,11.7534,12.1284,44.554,5.1156,32.8057,5.1327,96.0008,0,9.7214.4649,21.0685c.3058,7.5648,1.7066,95.53-1.9677,102.1724C3153.9647,1512.3416,3104.9224,1547.6434,3104.9224,1547.6434Z"/><path style={spriteStyle} d="M3567.6917,991.52l96.3742,238.72,94.3348,104.0231,4.0009,106.69-6.5679,76.4572-536.4712,12.1078,4.2529-341.3627L3290.2968,918.17l174.7055-49.3443,50.7444,9.3666S3558.8477,939.5634,3567.6917,991.52Z"/><path
      style={spriteStyle}
      d="M510.55,1523.1813s-86.1655,34.1516-162.67,31.9949q-113.8355-3.2351-169.8-3.2405-25.19-3.2351-38.719-6.481a286.277,286.277,0,0,1-29.3894-8.9086c-9.33-3.7824-41.6614-30.3782-44.7816-47.3935q-4.6569-25.5229-11.1954-55.4893c-13.0613-56.7139-11.3758-83.2206-15.1076-117.2512-3.7318-34.02,9.56-72.546,36.941-94.6615,40.1911-32.2471,78.0017-47.6757,90.6064-52.3895,37.6452-14.0779,123.8681-60.13,83.9437-128.7891-45.659-78.5209,2.2356-161.2538,14.67-173.6847,18.0318-17.2862,89.3418-38.5527,119.8265-42.3459,30.4659-3.7715,112.141,14.92,139.1568,22.3258,32.414,8.8857,35.94,14.7037,58.9581,24.1434q34.5082,14.1921,40.12,49.0192a560.3317,560.3317,0,0,1,6.9955,59.1416q2.3215,33.2285,2.334,63.1841l-49.45,46.18-30.7873,19.4429q4.6569,21.0849,10.7305,33.63,6.0564,12.5664,11.1954,18.2182a114.6139,114.6139,0,0,0,14.4591,12.962q13.0583,8.9248,20.06,20.6676,6.9972,11.7535,12.1283,44.554,5.1157,32.8059,5.1328,96.0008,0,9.7215.4649,21.0686c.3058,7.5647-4.7451,51.6435-8.4195,58.2857C586.5767,1470.0831,510.55,1523.1813,510.55,1523.1813Z"/><path style={spriteStyle} d="M1019.5025,983.1741l59.82,280.7581,137.7893,57.6937,24.2428,120.2381-63.4743,75.8854-522.1963-34.0431,20.6568-616.819,229.9-12.78L954.81,879.8208S1010.6585,931.2176,1019.5025,983.1741Z"/><path
      style={spriteStyle}
      d="M1800.3614,1540.9585s-97.3945,14.7377-173.9422,12.58q-113.8991-3.2369-169.8947-3.2423-25.2038-3.2369-38.7406-6.4846a286.4417,286.4417,0,0,1-29.4059-8.9136c-9.3347-3.7845-41.6846-30.3952-44.8066-47.42q-4.6595-25.537-11.2016-55.52c-13.0686-56.7457-11.3822-83.2672-15.1161-117.3167-3.7338-34.0387,12.7266-65.06,45.433-71.2479,40.2135-32.265,54.6435-58.25,65.9146-67.7834,20.3583-17.2194,62.3309-68.6614,66.159-80.3614,2.4183-7.3908.5087-56.13-5.3081-71.1637-15.7966-40.8266-22.5574-85.9216-16.0125-111.6163,4.5671-17.93,42.9234-62.9406,49.3719-69.3874,18.0418-17.2959,126.963-7.2495,157.4647-11.0448,30.483-3.7737,95.3617,5.3876,122.3927,12.7976,32.432,8.8907,35.96,14.712,58.991,24.157q34.5274,14.2,40.1423,49.0465a560.6392,560.6392,0,0,1,6.9995,59.1746q2.3228,33.2472,2.3353,63.2194l-25.3758,62.8526-36.6106,21.0691c3.1063,14.0645,11.8849,41.7255,18.11,46.5835q13.0655,8.93,20.0712,20.6792,7.0009,11.76,12.1351,44.5788,5.1184,32.8242,5.1357,96.0545,0,9.7269.4651,21.08c.306,7.569,1.7076,66.2273-1.9688,72.8731C1871.7053,1482.9525,1800.3614,1540.9585,1800.3614,1540.9585Z"/><path style={spriteStyle} d="M2298.4176,981.2168l59.8532,280.9148,137.8664,57.726,24.2563,120.3052-63.51,75.9278L1937.14,1529.4948l-8.4831-338.8908,26.4076-325.739,230.0289-12.7868,48.5963,25.7275S2289.5687,929.2312,2298.4176,981.2168Z"/><path
      style={spriteStyle}
      d="M4384.715,1547.6434s-119.6019,2.0614-196.1068-.0953c-75.89-2.1568-132.4819,2.76-169.8,2.76q-25.19-3.2351-38.719-6.481a286.277,286.277,0,0,1-29.3894-8.9086c-9.33-3.7824-41.6614-30.3783-44.7816-47.3935q-4.6569-25.523-11.1954-55.4893c-13.0613-56.714-11.3758-83.2207-15.1076-117.2512-3.7318-34.02-20.1754-58.8388,12.5127-65.0235,40.1911-32.247,62.1526-44.6672,73.4174-54.1952,20.347-17.21,13.5112-102.384,17.3372-114.0774,2.4169-7.3867,15.5312-35.8648,17.3372-57.0981,3.708-43.5942,16.13-99.21,22.6717-124.89,4.5645-17.92,37.5649-53.57,44.01-60.0133,18.0319-17.2862,145.0493-3.2446,175.534-7.0378,30.466-3.7715,95.3085,5.3846,122.3243,12.7905,32.414,8.8857,100.5506-.155,123.5686,9.2847,23.0054,9.4614,48.28,35.0977,52.0115,58.3122,3.1046,17.2862,14.0465,40.4571,15.6066,62.5986,1.5476,22.1524-18.3817,54.4513-18.3817,74.4253l-39.7156,86.531-40.5,11.4878s-34.2554,18.4257-28.0337,23.2811q13.0583,8.9249,20.06,20.6676,6.9972,11.7534,12.1284,44.554,5.1156,32.8057,5.1327,96.0008,0,9.7214.4649,21.0685c.3058,7.5648,10.2312,126.2037,6.5569,132.8458C4442.2819,1543.015,4384.715,1547.6434,4384.715,1547.6434Z"/><path style={spriteStyle} d="M4840.4062,1006.19l110.6912,194.6233,57.3461,164.123,72.9016,83.008-26.2547,69.0267-555.9353,12.5471,4.2529-341.3627,62.2706-106.6674,24.0053-136.6449,148.4136-72.7347,57.4418,6.0844S4831.5622,954.2333,4840.4062,1006.19Z"/><path
      style={spriteStyle}
      d="M5664.8082,1547.66s-119.5178,8.0557-195.969,5.9005q-113.7554-3.2328-169.6805-3.2382-25.1719-3.2328-38.6917-6.4764a286.0682,286.0682,0,0,1-29.3688-8.9024c-9.323-3.78-41.6321-30.3568-44.75-47.36q-4.6538-25.5051-11.1875-55.45c-13.0521-56.6741-11.3678-83.1622-15.097-117.1688-3.7292-33.9958,8.1639-70.8855,32.44-80.5331,40.1629-32.2244,44.9482-66.4679,44.9482-66.4679s-23.7384-74.4459-19.9151-86.1311c2.4152-7.3815,2.1933-35.84,3.9981-57.058,3.7053-43.5635,32.1114-112.467,38.648-138.1293,4.5614-17.9075,80.5944-40.2055,87.0347-46.6442,18.0191-17.274,144.9473-3.2422,175.41-7.0328,30.4446-3.7689,95.2415,5.3808,122.2384,12.7815,32.3911,8.88,111.2993-9.851,134.301-.4179,22.9893,9.4547,75.6648,30.1095,79.394,53.3077,3.1024,17.274,26.4275,47.6647,27.9865,69.7907,1.5466,22.1368-7.9961,70.1723-7.9961,90.1323l-45.3115,59.9711L5739,1131c-6.3461,1.0793-29,28-29,28s14.7616,25.2745,19.4231,33.0938q6.9921,11.7453,12.12,44.5227,5.112,32.7828,5.1291,95.9333,0,9.7146.4646,21.0538c.3056,7.5594,10.224,126.1149,6.5522,132.7524C5722.3347,1543.0347,5664.8082,1547.66,5664.8082,1547.66Z"/><path
      style={spriteStyle}
      d="M6184.3923,1089.5942,6263.7,1183.901l15.9923,166.3058,99.9518,71.5136-10.6489,94.5147-589.8275,13.312,19.4229-324.3891,101.2845-105.2826,16.857-122.0979,141.7614-30.9825,66.6284,40.1087s29.31,46.6578,41.8068,74.7686A117.6,117.6,0,0,0,6184.3923,1089.5942Z"/><path style={spriteStyle} d="M4776.265,2454.9653,4816,2793.7526l58,129,216,148.7536V3115l-413,18-144-24,15-624.2474,44.0862-13.1368a252.4393,252.4393,0,0,1,59.0437-10.175Z"/><path
      style={spriteStyle}
      d="M3906.82,2549.1843,3869,2741.4357s34.6683,62.7265,63.0332,69.1832,124.9553,20.0335,144.7942,25.8814c40.0735,11.8127-36.2839,162.4515-53.5729,169.7613-12.982,5.489-116.4346,50.34-116.4346,66.0985s15.7583,75.64,15.7583,75.64H4278.716l123.262-44.3777c42.4974-15.3,66.0634-44.6534,86.414-97.9436,27.529-72.0871,25.9149-173.9749,25.6163-195.06-.1818-12.8356-18.947-97.5482-28.402-113.3065s-116.6853-138.6731-117.6645-148.1281,9.84-66.2869,0-77.2543c-18.7053-20.8479-82.0326-43.3662-206.5967-43.3662-113.3023,0-141.622,15.4643-176.92,22.3153C3937.03,2460.0779,3906.82,2549.1843,3906.82,2549.1843Z"/><path
      style={spriteStyle}
      d="M9371.0611,2347.7738S9175.6121,2348,9139,2348c-16.4754-2.1159-78.3093,5.2951-96,0,.6-5.2951-9.8053-36.4866-12.8665-53.18-3.0459-16.6934-8.8571-33.2133-13.1335-52.82,12-30,59-47,59-47l42-60,27-69s-21.5663-39.2892-24-43c-1.8306-3.1792-56.1694-15.8208-58-19s-20.7847-14.8735-22-17c-22.3445-24.8535-25.0164-30.3707-36-68-8.5377-28.6127-4.7592-70.5957-5.9867-104.2483-1.2153-33.642.6485-64.3775,7.3224-91.8031C9009,1712,9037.8011,1679.1958,9050,1667c17.6907-16.9592,25.2833-30.9421,55.1913-34.6636a380.29,380.29,0,0,1,93.3608.404c32.3386,3.9661,97.788-14.278,148.4479-4.74,62.8405,12.1958,59.5379,8.9367,87,10,30.5049,1.0633,60.4175,1.7389,83,11,0,0,45,7,78,12,17.0361,2.5812,72,44,72,44,9,16,33,56.4039,33,76l-36,74-58,42-21,21c-18,9-19.9765,24.7809-16,33,3.9612,8.2191,46.4235,35.3232,51,43,4.5765,7.6875,7.6388,35.5431,11,57,3.3459,21.4569-25,128-39,190-11.0482,48.9279-73.0164,62.1626-84,68-10.9867,5.8267-74.5082,27.7235-80,28C9422.5082,2337.2658,9371.0611,2347.7738,9371.0611,2347.7738Z"/><path style={spriteStyle} d="M9930,1941l84,176,205.9794,148.0164v56.428L9653,2324l27-365,76-132,147-38Z"/><path
      style={spriteStyle}
      d="M411.0611,3147.7738S215.6121,3148,179,3148c-16.4754-2.1159-91.3093,5.2951-109,0,.6-5.2951-17.9388-55.3066-21-72-3.0459-16.6934,72-73,72-73l42-60,22-77s-21.5663-39.2892-24-43c-1.8306-3.1792-56.1694-15.8208-58-19s-20.7847-14.8735-22-17c-22.3445-24.8535-25.0164-30.3707-36-68-8.5377-28.6127-4.7592-70.5957-5.9867-104.2483-1.2153-33.642.6485-64.3775,7.3224-91.8031C49,2512,77.8011,2479.1958,90,2467c17.6907-16.9592,25.2833-30.9421,55.1913-34.6636a380.29,380.29,0,0,1,93.3608.404c32.3386,3.9661,97.788-14.278,148.4479-4.74,62.8405,12.1958,59.5379,8.9367,87,10,30.5049,1.0633,26,1,59,6,17.0361,2.5812,64,56,64,56,9,16,39,39,52,62l-13,58-15,26-21,21c-18,9,9.0235,54.7809,13,63,3.9612,8.2191,42.4235,56.3232,47,64,4.5765,7.6875,7.6388,35.5431,11,57,3.3459,21.4569-25,128-39,190-11.0482,48.9279-73.0164,62.1626-84,68-10.9867,5.8267-74.5082,27.7235-80,28C462.5082,3137.2658,411.0611,3147.7738,411.0611,3147.7738Z"/><path style={spriteStyle} d="M970,2741l65,137,224.9794,187.0164v56.428L693,3124l18.6351-375.1482q.3648-15.8446,2.0691-31.6159L728,2585l220-34,9,60Z"/><path
      style={spriteStyle}
      d="M1691.0611,3147.7738S1495.6121,3148,1459,3148c-16.4754-2.1159-91.3093,5.2951-109,0,.6-5.2951-17.9388-55.3066-21-72-3.0459-16.6934,111-69,111-69l42-60,22-77s-55.5663-54.2892-58-58c-1.8306-3.1792-61.1694-4.8208-63-8s-20.7847-14.8735-22-17c-22.3445-24.8535-25.0164-30.3707-36-68-8.5377-28.6127-4.7592-70.5957-5.9867-104.2483-1.2153-33.642.6485-64.3775,7.3224-91.8031C1329,2512,1357.8011,2479.1958,1370,2467c17.6907-16.9592,25.2833-30.9421,55.1913-34.6636a380.29,380.29,0,0,1,93.3608.404c32.3386,3.9661,97.788-14.278,148.4479-4.74,62.8405,12.1958,59.5379,8.9367,87,10,30.5049,1.0633,26,1,59,6,17.0361,2.5812,45,33,45,33,9,16,31,61,31,61l-18,56-21,21c-18,9,9.0235,54.7809,13,63,3.9612,8.2191,70.4235,36.3232,75,44,4.5765,7.6875,9.6388,107.5431,13,129,3.3459,21.4569-25,128-39,190-11.0482,48.9279-73.0164,62.1626-84,68-10.9867,5.8267-74.5082,27.7235-80,28C1742.5082,3137.2658,1691.0611,3147.7738,1691.0611,3147.7738Z"/><path style={spriteStyle} d="M2250,2741l84,176,205.9794,148.0164v56.428L1973,3124l9-411c.3146-13.17,2.7744-48.6121,4.6693-61.6489L2003,2539l216-14,18,86Z"/><path
      style={spriteStyle}
      d="M2971.0611,3147.7738S2775.6121,3148,2739,3148c-16.4754-2.1159-91.3093,5.2951-109,0,.6-5.2951-17.9388-55.3066-21-72-3.0459-16.6934,111-69,111-69l57-51s32-77,31-93-79.5663-47.2892-82-51c-1.8306-3.1792-61.1694-4.8208-63-8s-20.7847-14.8735-22-17c-22.3445-24.8535-25.0164-30.3707-36-68-8.5377-28.6127-4.7592-70.5957-5.9867-104.2483-1.2153-33.642.6485-64.3775,7.3224-91.8031C2609,2512,2637.8011,2479.1958,2650,2467c17.6907-16.9592,25.2833-30.9421,55.1913-34.6636a380.29,380.29,0,0,1,93.3608.404c32.3386,3.9661,97.788-14.278,148.4479-4.74,62.84,12.1958,59.5379,8.9367,87,10,30.5049,1.0633,26,1,59,6,17.0361,2.5812,45,33,45,33,9,16,18,55,18,55l-43,31c-3,9,40.0235,65.7809,44,74,3.9612,8.2191,56.4235,77.3232,61,85,4.5765,7.6875,9.6388,107.5431,13,129,3.3459,21.4569-25,128-39,190-11.0482,48.9279-73.0164,62.1626-84,68-10.9867,5.8267-74.5082,27.7235-80,28C3022.5082,3137.2658,2971.0611,3147.7738,2971.0611,3147.7738Z"/><path style={spriteStyle} d="M3549,2781l65,136,205.9794,148.0164v56.428L3253,3124l9-373c.76-31.0716,1-102,13-169l10-72,27.7675-6.5765c32.0461-7.59,64.8667-5.4235,97.7994-5.4235L3501,2492l16,119Z"/><path style={spriteStyle} d="M6056.265,2454.9653,6096,2793.7526l58,129,216,148.7536V3133l-531-23-28.0476-216,6.2356-61.9348a95.1338,95.1338,0,0,0,.2476-16.1523l-8.3689-119.9139L5807,2479l53.1313-10.455a372.8462,372.8462,0,0,1,53.65-6.5641Z"/><path
      style={spriteStyle}
      d="M5186.82,2549.1843,5155,2709c1.7036,1.3162,62,61,62,61,21,18,119.9885,60.6524,139.8274,66.5,40.0735,11.8127-51.5384,103.19-68.8274,110.5-12.982,5.489-110,56.2417-110,72s19,99,19,99l242,38,230-32c42.4975-15.3,73.0414-65.0311,93.392-118.3213,27.529-72.0871,25.9149-173.9749,25.6163-195.06-.1818-12.8356-63.5533-99.8606-73.0083-115.6189s-45.0207-134.545-46-144-11.2181-68.1026-21.0582-79.07c-18.7053-20.8479-82.0326-43.3662-206.5967-43.3662-113.3023,0-141.622,15.4643-176.92,22.3153C5217.03,2460.0779,5186.82,2549.1843,5186.82,2549.1843Z"/><path style={spriteStyle} d="M7336.265,2454.9653,7376,2793.7526l58,129,216,148.7536V3133l-543-7,49.7831-283.08c.7811-4.4415-4.2816-26.1674-6.7831-29.92l-54-70,4-283,35.8383-4.4415a555.4228,555.4228,0,0,1,84.5377-3.98Z"/><path
      style={spriteStyle}
      d="M6466.82,2549.1843,6470,2652c1.7036,1.3162,22,61,22,61,21,18,119.9885,60.6524,139.8274,66.5,40.0735,11.8127-75.5384,117.19-92.8274,124.5-12.982,5.489-88,72.2417-88,88,0,11.344,13.4736,71.6506,21.0223,104.5477,2.9726,12.9545,49.8474,25.39,62.9777,27.4523l184,32,303-19c42.4975-15.3,49.6493-74.71,70-128,27.5289-72.0871,23.2986-173.9151,23-195-.1818-12.8356-110.545-103.2417-120-119s-45.0207-134.545-46-144-11.2181-68.1026-21.0582-79.07c-18.7053-20.8479-82.0326-43.3662-206.5967-43.3662-113.3023,0-141.622,15.4643-176.92,22.3153C6497.03,2460.0779,6466.82,2549.1843,6466.82,2549.1843Z"/><path style={spriteStyle} d="M8644,2452l33,336,71,115,192,121-10,109-489,14,38.649-303.6916c1.4705-8.324-33.5867-48.4177-39.649-54.3084l-64-62,4-267,41.8657-5.4978a714.8887,714.8887,0,0,1,114.7327-5.7572Z"/><path
      style={spriteStyle}
      d="M7746.82,2549.1843,7750,2652c1.7036,1.3162,22,61,22,61,21,18,119.9885,60.6524,139.8274,66.5,40.0735,11.8127-89.5384,82.19-106.8274,89.5-12.982,5.489-79,76.2417-79,92s31,157,31,157l242,38,352-6c42.4975-15.3,49.6493-74.71,70-128,27.5289-72.0871.2986-190.9151,0-212-.1818-12.8356-211.545-114.2417-221-130s63.9793-90.545,63-100-45.2181-97.1026-55.0582-108.07c-18.7053-20.8479-82.0326-43.3662-206.5967-43.3662-113.3023,0-141.622,15.4643-176.92,22.3153C7777.03,2460.0779,7746.82,2549.1843,7746.82,2549.1843Z"/><path
      style={spriteStyle}
      d="M9964,2455l4.4679,270.752a172.3743,172.3743,0,0,0,12.6877,62.122L10028,2885l181,128,1,120H9779.9992l-24.5909-281.6351c-1.23-14.0892-36.16-46.6185-46.4083-56.3649l-78-82,22.4719-196.0479L9654,2460h0c55.7438-11.9451,118.8472-16.2954,175.7571-12.9325Z"/><path
      style={spriteStyle}
      d="M9026.82,2549.1843,9030,2652c1.7036,1.3162,22,61,22,61,10.5,9,90,33,90,33s19,10,49.8271,33.5c8.845,6.7427-89.5381,82.19-106.8271,89.5-12.982,5.489-79,76.2417-79,92s31,157,31,157l242,38,352-6c42.4975-15.3,49.6493-74.71,70-128,27.5289-72.0871-22.4731-220.732-38-235-37-34-158-53-173-66,0,0,3-71,1-86l47-58c-.9793-9.455-40.2181-94.1026-50.0582-105.07-18.7053-20.8479-82.0326-43.3662-206.5967-43.3662-113.3023,0-141.622,15.4643-176.92,22.3153C9057.03,2460.0779,9026.82,2549.1843,9026.82,2549.1843Z"/><path
      style={spriteStyle}
      d="M10055,3243.1864s16.1685,56.54,17,74,5,70,5,70l24.5869,82.701a178.8582,178.8582,0,0,1,7.2852,57.8006L10122,3805.1864c-.2427,6.3476,19,31,25,36,0,0,42.1021,26.2229,32,32l-312.3551,76.1923a27.6894,27.6894,0,0,1-7.3736.6213l-53.2723-.8136-1.999-47-33-207-41.001-51-83.999-91-40-64,16.6253-233.8245a7.6374,7.6374,0,0,1,8.5048-7.2374s51.42,7.1377,53.87,7.0619Z"/><path
      style={spriteStyle}
      d="M9032,3315.1864l-12,137c1.7036,1.3162,29,225,29,225,10.5,9,18.1729,14.5,49,38,8.8449,6.7427-20,62-20,62-3.8379,1.4842-34,18-54,33-6.2272,4.67,1.82,44.22,5,58,6,26,28,84,28,84l254,14,404-11,9-106,7-58s16.8169-43.054,1-57l-63-89s-72.0488-77.4321-100-98c0,0-73-61-75-69l-30-58c-13-17-13-82-13-82s70-76,64-93c0,0-206.9833-25-237-25-38,0-170.1423,20.3966-203,35C9057,3263.1864,9032,3315.1864,9032,3315.1864Z"/><path style={spriteStyle} d="M3519,4038l53,194,64,65,47,100s-11,103-11,119c0,9-22.6463.03-29-2l-121-43-138-71-151-79-2-260c-.0264-3.59,51.4125-13.863,55-14l201-5Z"/><path
      style={spriteStyle}
      d="M2695,4125l-91,141c1.7036,1.3162,27,66,27,66,10.5,9,34,42,45,50,8.9946,6.5417,20,68,20,68s-31.18,117.22-28,131c6,26,19,116,19,116l226,61s409.4472-5.4291,438-23c6.5-4,51-54,56-62,6.6409-10.6254,22-63,22-63l-33-131-117-85c-17-9-124-79-139-92l9-47,24-54c5.3572-6.8183-4-64-4-64s-17.9522-46.3739-23-52c-18.7052-20.8479-74.4359-43-199-43-44,0-161,14-161,14C2768,4064,2695,4125,2695,4125Z"/><path style={spriteStyle} d="M4799,4038l34,199,102,87,28,102,2,84s-36.2106,8.741-46,9l-143,4-98-94-165-108-1.9478-264.9073c-.0291-3.952,37.9985-7.9419,41.9478-8.0927l206-5Z"/><path style={spriteStyle} d="M6079,4038l34,199,83,60,101,204,2,81-94.4146,19.7412a107.6314,107.6314,0,0,1-31.4468,1.8656L6041,4592l-162-179-102-64,14-294c.16-3.4276,35.5712-6.869,39-7l208-3Z"/><path
      style={spriteStyle}
      d="M5255,4125l-96,125c1.7036,1.3162,19,88,19,88,10.5,9,47,36,58,44,8.9946,6.5417,18,80,18,80-6.2272,4.67-29.18,133.22-26,147,6,26,0,114,0,114l245,35,248-9,104-99V4477l-51-81c-17-9-90-80-105-93l40-48,36-34c5.3572-6.8183-16-84-16-84s-17.9522-46.3739-23-52c-18.7052-20.8479-74.4359-43-199-43-44,0-161,14-161,14C5328,4064,5255,4125,5255,4125Z"/><path style={spriteStyle} d="M7359,4038l34,199,76,114,112,156c5.8225,8.1343,24,39.3788,24,49.3822V4612c0,5.6535,1.653,19.0732-4,19l-305-4-100-150-114-84-10.4771-327.6465C7071.2336,4056.3053,7103,4051,7121,4047l200-5Z"/><path
      style={spriteStyle}
      d="M6535,4125l-106,138c1.7036,1.3162,29,75,29,75,10.5,9,21,54,32,62,8.9946,6.5417,44,62,44,62-6.2272,4.67-29.18,133.22-26,147,6,26,0,114,0,114l245,35,248-9,66-115,17-162-63-89c-40-29-57-67-72-80l40-48,36-34c5.3572-6.8183-16-84-16-84s-17.9522-46.3739-23-52c-18.7052-20.8479-74.4359-43-199-43-44,0-161,14-161,14C6608,4064,6535,4125,6535,4125Z"/><path
      style={spriteStyle}
      d="M515.0515,4840.4208l82.1493,73.0543c11.87,12.8417,25.436,29.1929,30.7744,42.6946l6.4563,10.9155c3.1243,7.7216,9.9967,25.9512,8.5353,30.5573l-13.2042,32.5962L573,5058l-35,47,119.9141,174.0348L624.7325,5483.623c-6.4116,41.4353-37.7972,66.64-60.1812,70.377H406.1864l-206.0628-9.08L89.0752,5524.7366,125,5312,31.7918,5086.96C26.4311,5076.6187,41.8678,5046.2087,49,5037l86.4449-112.1825s126.5355-86.6348,142.8627-84.3967S515.0515,4840.4208,515.0515,4840.4208Z"/><path
      style={spriteStyle}
      d="M936.5048,4828.0843,1084,5224l160,219.7155-2.8058,39.4406L1240,5503l-35.3042,9.5689a106.8976,106.8976,0,0,1-23.9015,3.6454l-33.6613,1.2806L687,5535l-13-376,43-319,42.7176-8.8381a69.5128,69.5128,0,0,1,30.45.5124L819,4834l68.7665-5.27Z"/><path style={spriteStyle} d="M8639,4042l34,195,103,175,109,129,15,114-300,12-107.3907-80.543a79.4228,79.4228,0,0,0-27.8743-13.3823L8410,4559l-58.3535-504.4129C8374.5283,4042.8446,8395,4046,8419,4047h178Z"/><path
      style={spriteStyle}
      d="M7815,4125l-106,138c1.7036,1.3162,29,75,29,75,10.5,9,21,54,32,62,8.9946,6.5417,44,62,44,62-6.2272,4.67-29.18,133.22-26,147,6,26,0,114,0,114l245,35,248-9,66-115,1-159-65-89c-17-9-66-66-81-79l61-61,42-25c5.3572-6.8183-16-84-16-84s-17.9522-46.3739-23-52c-18.7052-20.8479-74.4359-43-199-43-44,0-161,14-161,14C7888,4064,7815,4125,7815,4125Z"/><path style={spriteStyle} d="M9916,4038l37,199,101,201,144,121-4,125-300,16-122,5-35-42-88.1455-62L9648,4047c-.0122-8.0833,14.9169-7.0482,45-7l158.0048-1.4382,34.9971,1.6456Z"/><path
      style={spriteStyle}
      d="M9095,4125l-106,138c1.7036,1.3162,29,75,29,75,10.5,9,21,54,32,62,8.9946,6.5417,44,62,44,62-6.2272,4.67-29.18,133.22-26,147,6,26,0,114,0,114l245,35,248-9,48-119,8-143-58-96s-62-71-77-84l61-61,42-25c5.3572-6.8183-16-84-16-84s-41.9522-77.3739-47-83c0,0-50.4359-12-175-12-44,0-161,14-161,14C9168,4064,9095,4125,9095,4125Z"/><path style={spriteStyle} d="M2240,4848l124,376,151,208-1.5376,16.9132C2512.2256,5462.5186,2505,5507,2505,5507c-11.5259,17.8442-56,15-56,15l-479.3507,1.2494L1959,5432l7-162-19-394,42.233-11.8966a115.8554,115.8554,0,0,1,57.8642-1.28L2072,4864Z"/><path
      style={spriteStyle}
      d="M1755,4848l92.2008,45.4751c11.87,12.8417,25.436,29.1929,30.7744,42.6946l6.4563,10.9155c3.1243,7.7216,17.03,40.3087,15.5685,44.9148l-1,36-71,85,93.0174,171.7272L1892,5465c-5,43-35.6159,78.2627-58,82l-147.8136,7-206.0628-9.08-111.0484-20.1834L1354,5303l19-70-24-185,82-118s110.98-91.8173,127.3076-89.5792S1755,4848,1755,4848Z"/><path
      style={spriteStyle}
      d="M3596,4849l64,346,135,237-5.41,61.0523S3775.2126,5507.9454,3765,5508l-34.0144,3.3049L3227,5514l8-86-8-177V4876l56.6206-12.834a92.1449,92.1449,0,0,1,36.9186-.7814L3363,4866l145-11,37.196.8454a75.2158,75.2158,0,0,0,13.5252-.9147Z"/><path
      style={spriteStyle}
      d="M3035,4848l92.2008,45.4751c11.87,12.8417,25.436,29.1929,30.7744,42.6946l6.4563,10.9155c3.1243,7.7216,17.03,40.3087,15.5685,44.9148l-1,36-34,98,29,157-21,168c-5,43-40.6159,71.2627-63,75l-133,20-196.8764-1.08L2632,5524l-28-237,37-87-12-152,54-123,116-77C2861,4841,3040.072,4848.3081,3035,4848Z"/><path style={spriteStyle} d="M4877,4855l14,163,49,177,134,244-6,71s-33.9313,11.896-42.6167,11.7128L4975,5521l-486-7-15-370,34-275,69.8666-11.0316c16.5742-2.6169,39.91-2.2515,56.1334,2.0316h32l120.0373-6.2877L4840,4857Z"/><path
      style={spriteStyle}
      d="M4315,4848l71,35c11.87,12.8417,39.6368,25.668,44.9752,39.17l6.4563,10.9155c3.1243,7.7216,7.03,58.3087,5.5685,62.9148l-20,63-42,62,31,139-22,184c-5,43,2.3841,78.2627-20,82l-133,20-196.8764-1.08L3912,5524l-28-237,37-87-12-152,43-130,127-70C4141,4841,4320.072,4848.3081,4315,4848Z"/><path
      style={spriteStyle}
      d="M5535.2446,5546.6392s-37.0175-1.0488-111.0556-3.136c-73.4432-2.0872-226.9791-10.9561-237.21-14.606-9.0286-3.66-40.477-201.7779-44.0885-234.7111-3.6114-32.9228,27.0912-86.8686,52.9165-100.2462,10.5943-35.43-5.9388-123.1624-5.9388-123.1624l-6.1456-124.4485c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,68.9182,16.6763,95.782,20.8123,29.7582,4.5815,56.2737,6.1461,78.5494,15.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381,3.76,74.73-1.3464,96.2894l-35.02,39.06s11.0857,32.2937,15.6,39.8662q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,4.9673,84.2963,4.9673,125.0643q0,9.408.45,20.3892.4438,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4531-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761q-16.2561,8.6214-45.1464,10.9812c-4.8253.514-9.9315.902-15.3487,1.1747q-8.1258.3933-16.2516.3881Z"/><path
      style={spriteStyle}
      d="M5535.2446,5546.6392s-37.0175-1.0488-111.0556-3.136c-73.4432-2.0872-226.9791-10.9561-237.21-14.606-9.0286-3.66-40.477-201.7779-44.0885-234.7111-3.6114-32.9228,27.0912-86.8686,52.9165-100.2462,10.5943-35.43-5.9388-123.1624-5.9388-123.1624l-6.1456-124.4485c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,68.9182,16.6763,95.782,20.8123,29.7582,4.5815,56.2737,6.1461,78.5494,15.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381,3.76,74.73-1.3464,96.2894l-35.02,39.06s11.0857,32.2937,15.6,39.8662q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,4.9673,84.2963,4.9673,125.0643q0,9.408.45,20.3892.4438,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4531-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761q-16.2561,8.6214-45.1464,10.9812c-4.8253.514-9.9315.902-15.3487,1.1747q-8.1258.3933-16.2516.3881Z"/><path
      style={spriteStyle}
      d="M6183.1356,4859.7157l24.3634,192.221L6248,5160l111.1448,260.6575L6347,5477l-69.5341,14.5733a55.25,55.25,0,0,1-17.5842,3.9467l-516.1375,29.4316L5727,5437V5165l50.1325-288.8809S5804.9049,4870.8032,5834,4866c14.5821-2.4073,39.484-4.975,53.5915-.5691L5924,4869l173-9,27.1906,3.9986a55.4344,55.4344,0,0,0,13.657.3073Z"/><path
      style={spriteStyle}
      d="M6815.2446,5546.6392s-37.0175-1.0488-111.0556-3.136c-73.4432-2.0872-226.9791-10.9561-237.21-14.606-9.0286-3.66-40.477-201.7779-44.0885-234.7111-3.6114-32.9228,27.0912-86.8686,52.9165-100.2462,10.5943-35.43-5.9388-123.1624-5.9388-123.1624l-6.1456-124.4485c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,88.9182.6763,115.782,4.8123,29.7582,4.5815,56.2737,6.1461,78.5494,15.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381,3.76,74.73-1.3464,96.2894l-21.02,57.06s11.0857,32.2937,15.6,39.8662q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,4.9673,84.2963,4.9673,125.0643q0,9.408.45,20.3892.4438,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4531-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761c-10.8374,5.7476-59.89,7.408-79.1464,8.9812-4.8253.514-9.9315.902-15.3487,1.1747q-8.1258.3933-16.2516.3881Z"/><path
      style={spriteStyle}
      d="M7493,4860l21,177,18,91,113,299-9,55s-10.9095,12.6427-28,15h0l-43.7655,8.8953a35.4677,35.4677,0,0,1-8.61,1.147l-29.0082.28L7038,5530l-2-362,21.1325-291.8809,13.6071-4.2427C7087.152,4866.7589,7120,4875,7143,4872l40-3,218.0552-11.9966,19.8848-1.0939Z"/><path
      style={spriteStyle}
      d="M8095.2446,5546.6392s-37.0175-1.0488-111.0556-3.136C7910.7458,5541.416,7779.2305,5535.65,7769,5532c-9.0287-3.66-11.3885-145.0668-15-178-3.6115-32.9227-9-65,8-92,10.5943-35.43-12.1314-191.2225-12.1314-191.2225l-6.1456-124.4485c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,88.9182.6763,115.782,4.8123,29.7582,4.5815,110.2737-3.8539,132.5494,5.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381-5.24,86.73-10.3464,108.2894l-21.02,57.06s11.0857,32.2937,15.6,39.8662q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,4.9673,84.2963,4.9673,125.0643q0,9.408.45,20.3892.4439,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4531-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761c-10.8374,5.7476-59.89,7.408-79.1464,8.9812-4.8253.514-54.9315-1.098-60.3487-.8253q-8.1258.3933-16.2516.3881Z"/><path style={spriteStyle} d="M8772,4852l22,185,18,91,113,299-8,70-73.9334,15.551a105.85,105.85,0,0,1-30.49,2.4458L8705,5512l-342,20-2-362,36-316,17.5213-3.6672a142.9407,142.9407,0,0,1,51.2017-1.3413L8512,4854l172.9385-.8724L8730,4849Z"/><path
      style={spriteStyle}
      d="M1701,6356a877.4977,877.4977,0,0,1-136,7c-73.4432-2.0872-226.77-8.35-237-12-4.4371-1.7989-12-88-7-101,3.2108-8.3481,65-24,65-24,11-9,15.5926-69.47,10-76-10.7215-12.5178-54.3315-42.9036-55-49-3.6115-32.9227-6-53,11-80,3-48-6.1314-150.2225-6.1314-150.2225l-5.1456-136.4485c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,93.9182-7.3237,120.782-3.1877,29.7582,4.5815,223.2737-5.8539,245.5494,3.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381,1.7926,44.3686-3.3137,65.9284l-22,21-14,24s-1.2861,21.5932,3,38c6.5,24.8824,25.8272,51.7235,28.5477,56.287q6.7716,11.3746,11.7373,43.1174c3.3,21.1653-1.0327,118.2963-1.0327,159.0643q0,9.408.45,20.3892.4438,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4531-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761c-10.8374,5.7476-164.89,5.408-184.1464,6.9812-4.8253.514-54.9315-1.098-60.3487-.8253q-8.1258.3933-16.2516.3881C1725.305,6356.6392,1701,6356,1701,6356Z"/><path
      style={spriteStyle}
      d="M2440,5638l2,247,1,48,78,294v122l-304-7a26.6467,26.6467,0,0,1-4.4217-.1371C2168.8,6337.8983,2129,6349,2081.3551,6346.4361L2078,6347l6.9111-69.2457L2080,6227l3-260,65-323,1.2506-.2962a353.7823,353.7823,0,0,1,150.11-2.814l66.64.11Z"/><path
      style={spriteStyle}
      d="M2981,6356a877.4977,877.4977,0,0,1-136,7c-73.4432-2.0872-226.7695-8.35-237-12-4.4371-1.7989-12-88-7-101,3.2108-8.3481,41-17,41-17,2.9633-2.4245,38.6341-24.1041,56-36,14.8661-10.1835-18-60-18-60s-52-80-58-117c3-48-12.1314-158.2225-12.1314-158.2225l-5.1456-141.4485c20.064-40.2324,67.4361-48.8485,96.277-65.329,28-16,54.0712-15.67,82-17,27.8034-1.324,21.37,4.1979,39.9783-.43,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,93.9182-7.3237,120.782-3.1877,29.7582,4.5815,356.5563-8.2524,378.832.8829,22.2637,9.1563,9.9322,22.3711,13.5437,44.837,3.0045,16.7288-3.4931,53.1786-13.5437,72.163-9,17-47,59-63,64-12.0294,3.7592-51.376,62.8493-51,73,0,0,22.9673,20.2375,34,32,12.6587,13.496,26.0941,23.8487,27.5477,26.287q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,5.9673,119.2963,5.9673,160.0643q0,9.408.45,20.3892.4438,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4531-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761c-10.8374,5.7476-214.89,4.408-234.1464,5.9812-4.8253.514-54.9315-1.098-60.3487-.8253q-8.1258.3933-16.2516.3881C3005.305,6356.6392,2981,6356,2981,6356Z"/><path style={spriteStyle} d="M3739,5645l-3,236v90l65,256v122l-188,1c-27.15-5.96-81.0929-8.4341-108.7194-5.3645L3501,6345l-63-4,5-380,52-323,44.0929-2.0968c18.7809-2.5968,37.8614-6.0784,56.4735-2.4654l43.8441,8.5109Z"/><path
      style={spriteStyle}
      d="M6285.2332,6020.0556,6352,6101c3.5958,4.364,23.09,31.0679,23.2543,36.72L6382,6369l-712-7.019L5691,6266l-16-336L5681,5717.9894,5692,5664s35-22,69-24c.4166-.0245,25.9624.4123,39,0,101.2725-3.2028,229.3314-10,232.5353-10,31.1624,0,125.8444-.6548,125.8444-.6548l80.8149,8.9865,9.4992,196.2219Z"/><path
      style={spriteStyle}
      d="M5361.1232,5641.7347c-11.7583.2-62.9475,3.1363-74.13,7.3427-22.4794,8.4557-71.383,15.267-79.5428,40.9226s-35.6541,68.7249-37.23,96c-.6437,11.1444,10.7795,67,1.7795,137-.9947,7.7365,67.8574,125.5881,73,131,16.6039,17.4735,27,105,27,105l-14,49c-20.0629,24.9012-60.03,58.1929-72.5426,74.3259a37.6531,37.6531,0,0,0-5.9592,11.18L5170,6358s44.2152,10.1445,70.6809,11.047C5327.2729,6372,5330.0687,6361,5411,6361c13,0,79,8,94.8606,8,7.7419,0,35.8248-3.1673,48.1394-7,9.4958-2.9554,91.3984-71.0467,93-82,4.679-32,8.2563-75.9378,9-157,1-109-25-140-25-140s-14.13-60.2283-23-63c-16-5-39-46-39-46-2.347-21,7-110,18-117l33.3488-34.3837a36.85,36.85,0,0,0,10.127-6.94c8.363-8.08,25.5229-24.6608,25.5242-24.6759,1-12-18-51-18-51-14.58-1.545-61.9951-7.5707-65-8C5521.9864,5624.7123,5437.0632,5640.4424,5361.1232,5641.7347Z"/><path style={spriteStyle} d="M8837,5745l74.5145,80.3161L8933,6365l-379-1,1-69-7-42,2-68-42-223-3-323s76.7282-8,81.18-8c31.1625,0,124.65,5.0136,124.65,5.0136l101.3579,6.019L8819,5689Z"/><path
      style={spriteStyle}
      d="M8205,5635c-13.7852,2.6416-152.3164-8.802-256-4-13.4893.6248-82,32-114,49-22.9069,12.1693-75.262,109.7249-76,137-.3015,11.1444-9.7844,36-14,106-.4659,7.7365,9.5912,122.5881,12,128,7.7773,17.4735,21,62,21,62l5,49c-3,26-35.29,86-43,98,0,0-30,21-28.9965,32,.9994,10.9545-1.2169,3.91-2.4142,36.24S7708,6366,7708,6366s30.4,4.1,42.8291,4.047C7762,6370,8158,6367,8158,6367h57c3.6263,0,136-14,152-17,5.2488-.9841,154,3,154,3s-8.4006-225.7032-17-273c-2-11-36-100-36-100s-47.8454-34.2283-52-37c-7.4945-5-16.6155-64-16.6155-64-1.0993-21,12.4631-115,17.6155-122l16.578-40s47.4207-40.98,47.422-41c1.4259-22,0-36,0-36-8.5126-.5126-52.1384-.3864-55.5117-1C8398,5634,8249.8008,5626.415,8205,5635Z"/><path
      style={spriteStyle}
      d="M3975,4125l-91,141c1.7036,1.3162,27,66,27,66,10.5,9,34,42,45,50,8.9946,6.5417,20,68,20,68s-31.18,117.22-28,131c6,26,19,116,19,116l226,61s409.4472-5.4291,438-23c6.5-4,51-54,56-62,6.6409-10.6254,22-63,22-63l-33-131-117-85c-17-9-145-66-160-79l15-69,39-45c5.3572-6.8183-4-64-4-64s-17.9522-46.3739-23-52c-18.7052-20.8479-74.4359-43-199-43-44,0-161,14-161,14C4048,4064,3975,4125,3975,4125Z"/><path
      style={spriteStyle}
      d="M415.2446,6346.6392s-37.0175-1.0488-111.0556-3.136C230.7458,6341.416,62.2305,6354.65,52,6351c-4.4371-1.7989-5.8441-65.8892-8-105-.9953-18.0558,66-20,66-20,11-9,20.5926-50.47,15-57-10.7215-12.5178-52.3315-22.9036-53-29-3.6115-32.9227-7-51,10-78,10.5943-35.43-12.1314-191.2225-12.1314-191.2225L63.723,5746.329c20.064-40.2324,53.9543-43.1712,81.1671-62.22,39.8984-27.9288,47.9523-23.23,75.8811-24.56,27.8034-1.324,26.5989-5.3507,45.2071-9.9783,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,88.9182.6763,115.782,4.8123,29.7582,4.5815,164.2737,4.1461,186.5494,13.2814q33.3954,13.7344,38.8263,47.4385a542.29,542.29,0,0,1,6.77,57.2346c1.4977,21.4381,3.76,74.73-1.3464,96.2894l-21.02,57.06s11.0857,32.2937,15.6,39.8662q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,4.9673,84.2963,4.9673,125.0643q0,9.408.45,20.3892.4439,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106v63.4961q0,27.4531-7.2229,44.3025a50.7342,50.7342,0,0,1-23.4745,25.4761c-10.8374,5.7476-122.89,11.408-142.1464,12.9812-4.8253.514-54.9315-1.098-60.3487-.8253q-8.1258.3933-16.2516.3881Z"/><path style={spriteStyle} d="M1142,5655l-23,150,48,128,78,294v94l-118.0988-3.7237a253.7993,253.7993,0,0,0-47.8053-.03L779,6333l-35-367,11-311,23.4539,3.7263c55.2692,8.7811,104.5461-4.7263,166.5816-2.89L949.333,5655H1021.34l65.66-3Z"/><path
      style={spriteStyle}
      d="M6944.8082,1547.66s-119.5178,8.0557-195.969,5.9005q-113.7554-3.2328-169.6805-3.2382-25.1719-3.2328-38.6917-6.4764a286.0682,286.0682,0,0,1-29.3688-8.9024c-9.323-3.78-41.6321-30.3568-44.75-47.36q-4.6538-25.5051-11.1875-55.45c-13.0521-56.6741-11.3678-83.1622-15.097-117.1688-3.7292-33.9958,8.1639-70.8855,32.44-80.5331,40.1629-32.2244,13.9482-71.4679,13.9482-71.4679s-23.7384-74.4459-19.9151-86.1311c2.4152-7.3815,2.1933-35.84,3.9981-57.058,3.7053-43.5635,32.1114-112.467,38.648-138.1293,4.5614-17.9075,80.5944-40.2055,87.0347-46.6442,18.0191-17.274,175.9473,1.7578,206.41-2.0328,30.4446-3.7689,95.2415,5.3808,122.2384,12.7815,32.3911,8.88,138.2993-9.851,161.301-.4179,22.9893,9.4547,75.6648,30.1095,79.394,53.3077,3.1024,17.274,26.4275,47.6647,27.9865,69.7907,1.5466,22.1368-7.9961,70.1723-7.9961,90.1323l-45.3115,59.9711L7026,1131c-6.3461,1.0793-25,33-25,33s3.7616,20.2745,8.4231,28.0938q6.9921,11.7453,12.12,44.5227,5.112,32.7828,5.1291,95.9333,0,9.7146.4646,21.0538c.3056,7.5594,10.224,126.1149,6.5522,132.7524C7002.3347,1543.0347,6944.8082,1547.66,6944.8082,1547.66Z"/><path
      style={spriteStyle}
      d="M7464.3923,1089.5942,7543.7,1183.901l15.9923,166.3058,99.9518,71.5136-10.6489,94.5147L7070,1530l35.5907-324.842L7211,1097l11-107,115.4936-27.205,66.6284,40.1087s30.31,30.6578,42.8068,58.7686A117.6,117.6,0,0,0,7464.3923,1089.5942Z"/><path
      style={spriteStyle}
      d="M8224.8082,1547.66s-119.5178,8.0557-195.969,5.9005q-113.7554-3.2328-169.6805-3.2382-25.1719-3.2328-38.6917-6.4764a286.0682,286.0682,0,0,1-29.3688-8.9024c-9.323-3.78-41.6321-30.3568-44.75-47.36q-4.6538-25.5051-11.1875-55.45c-13.0521-56.6741-11.3678-83.1622-15.097-117.1688-3.7292-33.9958,8.1639-70.8855,32.44-80.5331,40.1629-32.2244-4.0518-73.4679-4.0518-73.4679s-23.7384-74.4459-19.9151-86.1311c2.4152-7.3815,2.1933-35.84,3.9981-57.058,3.7053-43.5635,32.1114-112.467,38.648-138.1293,4.5614-17.9075,80.5944-40.2055,87.0347-46.6442,18.0191-17.274,143.3189-.21,173.7821-4,30.4445-3.7689,145.87,9.3485,172.8668,16.7492C8237.2579,854.6287,8367.9982,825.567,8391,835c22.9893,9.4547,73.2708,40.8019,77,64,3.1024,17.2741,22.441,47.874,24,70,1.5465,22.1368-6,74.04-6,94l-61,51-114,15c-6.3461,1.0793-26,24-26,24s-.2384,31.2745,4.4231,39.0938q6.9921,11.7453,12.12,44.5227,5.112,32.7828,5.1291,95.9333,0,9.7146.4646,21.0538c.3056,7.5594,10.224,126.1149,6.5522,132.7524C8282.3347,1543.0347,8224.8082,1547.66,8224.8082,1547.66Z"/><path style={spriteStyle} d="M8736.8036,1093.7713l79.3077,94.3067L8836,1364l103,66-10.0047,86.2351L8717,1519l-165,13-192-9,42-314,114-112,5-111,90.4936-11.205L8678,1015s22.503,12.8892,35,41C8717.4826,1066.0832,8737,1094,8736.8036,1093.7713Z"/><path
      style={spriteStyle}
      d="M9504.8082,1547.66s-119.5178,8.0557-195.969,5.9005q-113.7554-3.2328-169.6805-3.2382-25.1719-3.2328-38.6917-6.4764a286.0682,286.0682,0,0,1-29.3688-8.9024c-9.323-3.78-41.6321-30.3568-44.75-47.36q-4.6537-25.5051-11.1875-55.45c-13.0521-56.6741-11.3678-83.1622-15.097-117.1688-3.7292-33.9958,8.1639-70.8855,32.44-80.5331,40.1629-32.2244-4.0518-73.4679-4.0518-73.4679s-23.7384-74.4459-19.9151-86.1311c2.4152-7.3815,2.1933-35.84,3.9981-57.058,3.7053-43.5635,32.1114-112.467,38.648-138.1293,4.5614-17.9075,80.5944-40.2055,87.0347-46.6442,18.0191-17.274,143.3189-.21,173.7821-4,30.4445-3.7689,145.87,9.3485,172.8668,16.7492C9517.2579,854.6287,9683.9982,828.567,9707,838c22.9893,9.4547,73.2708,40.8019,77,64,3.1024,17.2741,22.441,47.874,24,70,1.5465,22.1368-6,74.04-6,94l-61,51-110,18c-6.3461,1.0793-35,27-37,51,0,0-15.8807,28.7613-12.4571,50.6165q5.112,32.7828,5.1291,95.9333,0,9.7146.4646,21.0538c.3056,7.5594,10.224,126.1149,6.5522,132.7524C9562.3347,1543.0347,9504.8082,1547.66,9504.8082,1547.66Z"/><path
      style={spriteStyle}
      d="M10016.8036,1093.7713,10104,1199l12,165,92,70,.9953,82.2351L9997,1519l-145.7781,20.0772a180.2888,180.2888,0,0,1-42.5378.7912L9636,1526l63-287,90-128,85.8568-74.535a28.1256,28.1256,0,0,1,11.7593-6.0823L9925,1021s53,18,68,35C10000.3008,1064.2742,10017,1094,10016.8036,1093.7713Z"/><path
      style={spriteStyle}
      d="M544.8082,2347.66s-119.5178,8.0557-195.969,5.9005q-113.7554-3.2328-169.68-3.2382-25.172-3.2328-38.6917-6.4764a286.0682,286.0682,0,0,1-29.3688-8.9024c-9.323-3.78-41.6321-30.3568-44.75-47.36q-4.6538-25.5051-11.1875-55.45c-13.0521-56.6741-11.3678-83.1622-15.097-117.1688C36.3344,2080.9683,66.7235,2052.6476,91,2043c20-26-22.5478-82.0369-22.5478-82.0369s-23.7384-74.4459-19.9151-86.1311c2.4152-7.3815,2.1933-35.84,3.9981-57.058,3.7053-43.5635,32.1114-112.467,38.648-138.1293,4.5614-17.9075,80.5944-40.2055,87.0347-46.6442,18.0191-17.274,143.3189-.21,173.7821-4.0005,30.4445-3.7689,145.87,9.3485,172.8668,16.7492C557.2579,1654.6287,770.9982,1627.567,794,1637c22.9893,9.4547,109.2708,44.8019,113,68,3.1024,17.2741,24,70,24,70,1.5465,22.1368-89,71.04-89,91l-61,51-80,30c-6.3461,1.0793-30,21-32,45,0,0-15.8807,28.7613-12.4571,50.6165C659.9509,2064.4717,651,2086.9033,651,2129c0,6.4764-4.3212,44.4406-4,52,.3056,7.5594-9.6394,98.7185-13.3112,105.356C602.3347,2343.0347,544.8082,2347.66,544.8082,2347.66Z"/><path
      style={spriteStyle}
      d="M1091,1955l36,62,29,147,92,70,.9953,82.2351L1049,2319l-157.7781,20.0772a180.2888,180.2888,0,0,1-42.5378.7912L676,2326l57-261,92-136,92-68s49.6521,13.0628,54,12l26,16s31,15,46,32C1050.3008,1929.2742,1091.1964,1955.2287,1091,1955Z"/><path
      style={spriteStyle}
      d="M3103.7783,2348.7039s-119.2917,8.0405-195.5982,5.8894q-113.54-3.2267-169.36-3.2321-25.1244-3.2267-38.6186-6.4641a285.5411,285.5411,0,0,1-29.3132-8.8856c-9.3053-3.7725-41.5533-30.2994-44.6655-47.2706q-4.6449-25.4566-11.1663-55.3454c-13.0275-56.5669-11.3463-83.0048-15.0685-116.9471-3.7221-33.9315-11.75-55.2254,12.48-64.8548a149.707,149.707,0,0,0,27.3133,3.8143c8.6326-5.5155,17.8815-44.6053,17.8815-44.6053s-9.52-10.448-22.5572-25.3473c-26.9392-30.7871-15.8946-64.58-19.9-80.4707-6.8316-27.1041-7.0284-83.1328-5.2271-104.3111,3.6984-43.4812,30.0625-144.6458,52.5893-158.4723,26.6034-16.3286,130.5614-5.381,136.99-11.8075,25.2232-5.6536,131.9037,8.7533,162.3093,4.97,30.387-3.7618,94.23,9.7495,122.0071,12.7573,15.2808,1.6547,138.5594-21.1388,161.5176-11.7236,11.4729,4.7184,59.2127,2.7612,95.7723,3.99s160.5838,23.5747,160.5838,23.5747c31.29,12.0071,84.68,36.9481,84.68,36.9481s37.0463,118.3286,37.2447,130.3568c.1317,7.981-72.2416,89.7687-72.2416,89.7687l-161.4595,20.6354L3368,1973l-27,15-10.0825,20.7439C3335.57,2016.5593,3294,2048,3294,2048s-21.7723,41.1425-40,79c-13,27-28.3206,51.4549-28,59,.305,7.5451-29.8443,94.8912-33.5091,101.5161C3161.196,2344.0876,3103.7783,2348.7039,3103.7783,2348.7039Z"/><path style={spriteStyle} d="M3807.5422,2234.3092l2.4665,94.74-399.0479,15.4865c-7.9669.3092-66.0129-3.9049-73.9608-4.5358l-119.0783-9.3744L3298,2110l108.8553-121.1883,170.5208-29.9148,83.89,13.7265,54.5982,191.1868Z"/><path
      style={spriteStyle}
      d="M1824.8082,2347.66s-119.5178,8.0557-195.969,5.9005q-113.7553-3.2328-169.68-3.2382-25.1719-3.2328-38.6917-6.4764a286.0682,286.0682,0,0,1-29.3688-8.9024c-9.323-3.78-41.6321-30.3568-44.75-47.36q-4.6537-25.5051-11.1875-55.45C1322.1085,2175.4588,1322.7292,2123.0066,1319,2089c-3.7292-33.9958,27.7235-36.3524,52-46,20-26-22.5478-82.0369-22.5478-82.0369s-23.7384-74.4459-19.9151-86.1311c2.4152-7.3815,2.1933-35.84,3.9981-57.058,3.7053-43.5635,13.9282-107.1118,20.4648-132.774,4.5614-17.9075,65.56-44.5614,72-51,18.0191-17.2741,176.5368-1.2095,207-5,30.4445-3.7689,145.87,9.3485,172.8668,16.7492,32.3911,8.88,246.1314-18.1822,269.1332-8.7492,22.9893,9.4547,149.2708,20.8019,153,44,3.1024,17.2741,24,70,24,70,1.5465,22.1368-68,86.04-68,106l-90,60-64,30c-6.3461,1.0793-52,31-54,55,0,0-15.8807,28.7613-12.4571,50.6165C1965.9509,2074.4717,1945,2092.9033,1945,2135c0,6.4764-3.3212,58.4406-3,66,.3056,7.5594-20.059,98.6672-22,106C1911,2341,1824.8082,2347.66,1824.8082,2347.66Z"/><path style={spriteStyle} d="M2371,1955l36,62,29,147,92,70,.9953,82.2351L2329,2319l-157.7781,20.0772a180.2888,180.2888,0,0,1-42.5378.7912L1956,2326l57-261,74-110,138-84,52,18s31,15,46,32C2330.3008,1929.2742,2371.1964,1955.2287,2371,1955Z"/><path
      style={spriteStyle}
      d="M4383.7783,2348.7039s-119.2917,8.0405-195.5982,5.8894q-113.54-3.2267-169.36-3.2321-25.1244-3.2267-38.6186-6.4641a285.5411,285.5411,0,0,1-29.3132-8.8856c-9.3053-3.7725-41.5533-30.2994-44.6655-47.2706-3.0966-16.9711-4.8755-26.8079-9.2232-46.7409-13.0274-56.5669-1.2894-52.6093-5.0116-86.5516C3888.2663,2121.5169,3888,2122,3909,2101c12-7,20-27,20-27,8.6326-5.5155,8.6635-63.1974,8.6635-63.1974s-9.52-10.448-22.5572-25.3473c-26.9392-30.7871-15.8946-64.58-19.9-80.4707-6.8316-27.1041-18.0078-89.8063-16.2065-110.9846,3.6984-43.4812,41.0419-137.9723,63.5687-151.7988,26.6034-16.3286,130.5614-5.381,136.99-11.8075,25.2232-5.6536,131.9037,8.7533,162.3093,4.97,30.387-3.7618,94.23,9.7495,122.0071,12.7573,15.2808,1.6547,138.5594-21.1388,161.5176-11.7236,11.4729,4.7184,59.2127,2.7612,95.7723,3.99S4725,1666,4725,1666c31.29,12.007,79,54,79,54s40.8016,82.9719,41,95c.1317,7.981-51,82-51,82l-131,32-32,37-20,9-14,20c-10,23-45,52-45,52s-6.7723,40.1425-25,78c-13,27-21.3206,53.4549-21,61,.305,7.5451-29.8443,94.8912-33.5091,101.5161C4441.196,2344.0876,4383.7783,2348.7039,4383.7783,2348.7039Z"/><path style={spriteStyle} d="M5087.63,2234.3092l2.3787,94.74-384.8418,15.4865c-7.6833.3092-63.6628-3.9049-71.3278-4.5358L4519,2330.6256,4590.4411,2086l105.12-121,155.2684-35,74.2588,32,74.1275,201.81Z"/><path
      style={spriteStyle}
      d="M5663.7783,2348.7039s-119.2917,8.0405-195.5982,5.8894q-113.54-3.2267-169.36-3.2321c-16.75-2.1511-30.1872-2.6874-38.6184-6.4641-8.8847-3.98-40.2021.1029-55.2021.1029-.1119,0-15.6646-39.2879-18.7768-56.2591-3.0966-16.9711-4.8755-26.8079-9.2232-46.7409-13.0274-56.5669-1.2894-52.6093-5.0116-86.5516C5168.2663,2121.5169,5168,2122,5189,2101c12-7,46-44,46-44l-4-42s-22.8567-14.6454-35.8937-29.5447c-26.9392-30.7871-15.8946-64.58-19.9-80.4707-6.8316-27.1041-18.0078-89.8063-16.2065-110.9846,3.6984-43.4812,41.0419-137.9723,63.5687-151.7988,26.6034-16.3286,130.5614-5.381,136.99-11.8075,25.2232-5.6536,131.9037,8.7533,162.3093,4.97,30.387-3.7618,94.23,9.7495,122.0071,12.7573,15.2808,1.6547,138.5594-21.1388,161.5176-11.7236,11.4729,4.7184,59.2127,2.7612,95.7723,3.99S5965,1661,5965,1661c31.29,12.007,49,60,49,60s40.8016,82.9719,41,95c.1317,7.981-51,82-51,82l-61,31-18,21-45,31-14,20c-5.3816,12.3776-17.4242,23.5967-27.4,31.32a47.86,47.86,0,0,0-18.0808,31.0392c-2.3716,16.4878-6.6252,40.3847-13.5192,61.6412-9.2449,28.505-21.3206,53.4549-21,61,.305,7.5451-29.8443,94.8912-33.5091,101.5161C5721.196,2344.0876,5663.7783,2348.7039,5663.7783,2348.7039Z"/><path style={spriteStyle} d="M6367.63,2234.3092l2.3787,94.74-384.8418,15.4865c-5.4921.221-44.0381-7.49-68.3769-12.136a177.506,177.506,0,0,0-36.2241-3.1211L5799,2330.6256,5864.6547,2091l114.7636-141,109.9416-77,107.0484,26,73.2944,213Z"/><path
      style={spriteStyle}
      d="M6928,2345s-103.5134,11.7444-179.82,9.5933q-113.54-3.2267-169.36-3.2321c-16.75-2.1511-30.1872-2.6874-38.6184-6.4641-8.8847-3.98-40.2021.1029-55.2021.1029-.1119,0-15.6646-39.2879-18.7768-56.2591-3.0966-16.9711-4.8755-26.8079-9.2232-46.7409-13.0274-56.5669,3-45,6-65,5.0636-33.7574,9-36,30-57,12-7,33-70,33-70l-15-35s-22.8567-14.6454-35.8937-29.5447c-26.9392-30.7871-15.8946-64.58-19.9-80.4707-6.8316-27.1041-18.0078-89.8063-16.2065-110.9846,3.6984-43.4812,41.0419-137.9723,63.5687-151.7988,26.6034-16.3286,130.5614-5.381,136.99-11.8075,25.2232-5.6536,131.9037,8.7533,162.3093,4.97,30.387-3.7618,94.23,9.7495,122.0071,12.7573,15.2808,1.6547,123.1671-14.5362,146.1254-5.121,11.4729,4.7184,59.6049,2.1586,96.1645,3.3879S7230,1667,7230,1667c31.29,12.007,49,60,49,60s4.8016,69.9719,5,82c.1317,7.981-43,77-43,77l-35,25-39,18-26,18-33,37c-21,7-32,40-32,40s14.2277,55.1425-4,93c-13,27-19.3206,54.4549-19,62,.305,7.5451-33.3352,95.3751-37,102C6984.7051,2337.5715,6928,2345,6928,2345Z"/><path style={spriteStyle} d="M7647.5422,2234.3092l2.4665,94.74-399.0479,15.4865c-5.6948.221-45.6638-7.49-70.901-12.136a190.6714,190.6714,0,0,0-37.5613-3.1211l-84.5768,1.3469L7126,2091l119-141,114-77,111,26,76,213Z"/><path
      style={spriteStyle}
      d="M8208,2345s-103.5134,11.7444-179.82,9.5933q-113.54-3.2267-169.36-3.2321c-16.75-2.1511-30.1872-2.6874-38.6184-6.4641-8.8847-3.98-40.2021.1029-55.2021.1029-.1119,0-15.6646-39.2879-18.7768-56.2591-3.0966-16.9711-4.8755-26.8079-9.2232-46.7409-13.0274-56.5669,3-45,6-65,5.0636-33.7574,17-34,38-55,12-7,33-70,33-70l-23-37s-22.8567-14.6454-35.8937-29.5447c-26.9392-30.7871-15.8946-64.58-19.9-80.4707-6.8316-27.1041-18.0078-89.8063-16.2065-110.9846,3.6984-43.4812,41.0419-137.9723,63.5687-151.7988,26.6034-16.3286,130.5614-5.381,136.99-11.8075,25.2232-5.6536,131.9037,4.7533,162.3093.97,30.387-3.7618,94.23,9.7495,122.0071,12.7573,15.2808,1.6547,93.1671-3.5362,116.1254,5.879,11.4729,4.7184,38.6049-1.8414,75.1645-.6121S8453,1670,8453,1670c31.29,12.007,41,64,41,64s10.8016,69.9719,11,82c.1317,7.981-47,74-47,74l-58,37-47,21c-21,7,3,76,3,76s14.2277,55.1425-4,93c-13,27-19.3206,54.4549-19,62,.305,7.5451-33.3352,95.3751-37,102C8264.7051,2337.5715,8208,2345,8208,2345Z"/><path style={spriteStyle} d="M8928.0413,2245l1.9674,84.0493L8558.8011,2337c-5.5776.221-64.3506.0462-89.0684-4.6a183.0012,183.0012,0,0,0-36.7881-3.1211L8359,2324l47.012-274,84.23-85,47.9914-80,106.7565-28,47.012,17,98.9211,223Z"/><path
      style={spriteStyle}
      d="M8785,3243s16.1685,56.54,17,74,5,70,5,70l24.5869,82.701a178.8582,178.8582,0,0,1,7.2852,57.8006L8852,3805c-.2427,6.3476,19,31,25,36,0,0,42.1021,26.2229,32,32l-312.3551,76.1922a27.6894,27.6894,0,0,1-7.3736.6214L8535.999,3949,8534,3902l-33-207-41.001-51L8376,3553l-40-64,16.6253-233.8245a7.6374,7.6374,0,0,1,8.5048-7.2374s51.42,7.1377,53.87,7.0619Z"/><path
      style={spriteStyle}
      d="M7762,3315l-12,137c1.7036,1.3162,29,225,29,225,10.5,9,18.1729,14.5,49,38,8.8449,6.7427-20,62-20,62-3.8379,1.4841-34,18-54,33-6.2272,4.67,1.82,44.22,5,58,6,26,28,84,28,84l254,14,404-11,9-106,7-58s16.8169-43.054,1-57l-63-89s-72.0488-77.4321-100-98c0,0-73-61-75-69l-30-58c-13-17-13-82-13-82s70-76,64-93c0,0-206.9833-25-237-25-38,0-170.1423,20.3966-203,35C7787,3263,7762,3315,7762,3315Z"/><path style={spriteStyle} d="M1065,3257l-21,214c.3517,21.3137-7.034,84.2553,1,104l38,92,166,146,1,120-313,14-117.0008-14L819,3657c-1.23-14.0892-59.7517-52.2536-70-62l-78-82,8-166-6-92,58,1c55.7438-11.9451,95.09-11.3629,152-8Z"/><path
      style={spriteStyle}
      d="M66.82,3349.1843,70,3452c1.7036,1.3162,10,75,10,75,10.5,9,63,48,63,48s39,30,37,52c-1.0069,11.0762-35.711,64.69-53,72-12.982,5.489-54,72.2417-54,88s34,165,34,165l212,4,364-6c42.4975-15.3,49.6493-74.71,70-128,27.5289-72.0871-34.4731-220.732-50-235-37-34-157-85-172-98,0,0,12-55,16-88l38-76c-.9793-9.455-27.16-71.0326-37-82-18.7052-20.8479-102.0908-14.4362-226.6549-14.4362-113.3023,0-141.622,15.4643-176.92,22.3153C97.03,3260.0779,66.82,3349.1843,66.82,3349.1843Z"/><path style={spriteStyle} d="M2363,3244l-18,219c.3517,21.3137,3.966,76.2553,12,96l46,102,126,152,1,120-313,14-86.0008-10L2099,3657c-1.23-14.0892-59.7517-52.2536-70-62l-78-82,8-166-6-92,58,1c55.7438-11.9451,95.09-11.3629,152-8Z"/><path
      style={spriteStyle}
      d="M1346.82,3349.1843,1350,3452c1.7036,1.3162,10,75,10,75,10.5,9,27,80,27,80s77,51,75,73c-1.0069,11.0762-20.711,54.69-38,62-12.982,5.489-64,72.2417-64,88s27,122,27,122l212,4,378-6c42.4975-15.3,60.6493-81.71,81-135,27.5289-72.0871-64.4731-177.732-80-192-37-34-152-121-167-134,0,0-31-67-27-100l45-58c14-10,8.84-77.0326-1-88-18.7052-20.8479-102.0908-14.4362-226.6549-14.4362-113.3023,0-141.622,15.4643-176.92,22.3153C1377.03,3260.0779,1346.82,3349.1843,1346.82,3349.1843Z"/><path style={spriteStyle} d="M3643,3244l20,211c.3517,21.3137,3.966,76.2553,12,96l44,136,90,126-24,92-288,42-86.0008-10L3379,3657c-1.23-14.0892-59.7517-52.2536-70-62l-78-82,8-166-6-92,58,1c55.7438-11.9451,95.09-11.3629,152-8Z"/><path
      style={spriteStyle}
      d="M2626.82,3349.1843,2630,3452c1.7036,1.3162,0,93,0,93,10.5,9,47,89,47,89s67,24,65,46c-1.0069,11.0762-20.711,54.69-38,62-12.982,5.489-64,72.2417-64,88s27,122,27,122l212,4h424c42.4975-15.3,14.6493-87.71,35-141,27.5289-72.0871-64.4731-177.732-80-192-37-34-152-121-167-134,0,0-31-67-27-100l54-90c14-10-.16-45.0326-10-56-18.7052-20.8479-102.0908-14.4362-226.6549-14.4362-113.3023,0-141.622,15.4643-176.92,22.3153C2657.03,3260.0779,2626.82,3349.1843,2626.82,3349.1843Z"/><path
      style={spriteStyle}
      d="M4923,3244l42,213c.3517,21.3137,5.966,68.2553,14,88l42,144,27,49,9,47,13.3218,22.203a63.9,63.9,0,0,1,7.1494,48.5686L5065,3905l-288,42-86.0008-10L4659,3657c-1.23-14.0892-59.7517-52.2536-70-62l-78-82,8-166-6-92,58,1c55.7438-11.9451,95.09-11.3629,152-8Z"/><path
      style={spriteStyle}
      d="M3906.82,3349.1843,3910,3452c1.7036,1.3162,0,93,0,93,10.5,9,26,107,26,107s73,15,71,37c-1.0069,11.0762-5.711,45.69-23,53-12.982,5.489-64,72.2417-64,88s27,122,27,122l212,4h424c42.4975-15.3,14.6493-87.71,35-141,27.5289-72.0871-64.4731-177.732-80-192-37-34-152-121-167-134,0,0-31-67-27-100l54-90c14-10-.16-45.0326-10-56-18.7052-20.8479-102.0908-14.4362-226.6549-14.4362-113.3023,0-141.622,15.4643-176.92,22.3153C3937.03,3260.0779,3906.82,3349.1843,3906.82,3349.1843Z"/><path
      style={spriteStyle}
      d="M6225,3243l39,201c.3517,21.3137,6.966,77.2553,15,97l7.778,148.1917,2,64L6317,3809l20,14c8.7551,14.5918,24.65,16.2759,20.4712,32.7716L6350,3886l-293,61-86.0008-10L5941,3675c-1.23-14.0892-61.7517-70.2536-72-80l-78-82-6-156,8-102,58,1c55.7438-11.9451,95.09-11.3629,152-8Z"/><path
      style={spriteStyle}
      d="M5186.82,3349.1843,5190,3452c1.7036,1.3162,0,93,0,93,10.5,9,26,107,26,107s37,34,52,63c5.11,9.8787-3,25-19,41-9.9665,9.9665-49,58.2417-49,74s27,122,27,122l212,4h424c42.4975-15.3,14.6493-87.71,35-141,27.5289-72.0871-64.4731-177.732-80-192-37-34-139-132-154-145,0,0-46-84-42-117l72-87c14-10-16.16-20.0326-26-31-18.7052-20.8479-102.0908-14.4362-226.6549-14.4362-113.3023,0-141.622,15.4643-176.92,22.3153C5217.03,3260.0779,5186.82,3349.1843,5186.82,3349.1843Z"/><path
      style={spriteStyle}
      d="M7505,3243l39,201c.3517,21.3137,6.966,77.2553,15,97l7.778,148.1917,2,64L7575,3813s14.1148,20.58,30,29c16.42,8.7032,34.5953,5.3871,32.4712,13.7716L7631,3880l-294,67-86.0008-10L7222,3687c-1.23-14.0892-68.7517-73.2536-79-83l-75-118-3-129,8-102,58,1c55.7438-11.9451,95.09-11.3629,152-8Z"/><path
      style={spriteStyle}
      d="M6486,3333l-16,119c1.7036,1.3162,0,93,0,93,10.5,9,26,107,26,107s37,34,52,63c5.11,9.8787-1,40-17,56-9.9665,9.9665-59,32.2417-59,48s35,133,35,133l212,4h440c28-17,3.75-93.3024,23-147,19-53-68.4731-171.732-84-186-37-34-139-132-154-145,0,0-46-84-42-117l72-99c14-10-30.941-14.5893-45-19-51-16-88.2065-15.6149-207.6548-14.4362-113.2969,1.1179-141.6221,15.4643-176.92,22.3153C6497.03,3260.0779,6486,3333,6486,3333Z"/><path
      style={spriteStyle}
      d="M1047,4042.1864s16.1685,56.54,17,74,5,70,5,70l24.5869,82.701c5.5708,18.7392,30.16,54.5771,29.4131,74.1126l-11,174.1864c-.2427,6.3476,19,31,25,36,0,0,42.1021,26.2229,32,32l-248.3551,20.1923a27.6894,27.6894,0,0,1-7.3736.6213l-53.2723-.8136L843,4539l-32-43.8136-41.001-51-83.999-91-40-64,16.6253-233.8245a7.6374,7.6374,0,0,1,8.5048-7.2374s51.42,7.1377,53.87,7.0619Z"/><path
      style={spriteStyle}
      d="M89,4149.1864,44,4266c1.7036,1.3162,75,131,75,131,10.5,9,24,64,19,118.1864-1.0219,11.0749,3,43.8136-28,66-6.33,4.53,1.82,44.22,5,58,6,26,28,84,28,84l208,43,404-11,9-106,7-58s16.8169-43.054,1-57l-63-89s-72.0488-77.4321-100-98c0,0-18-84-20-92L607,4189c-1-43.1864-13-88-13-88s-39-56-74-60c0,0-171.9833-20.8136-202-20.8136-38,0-153.1423,54.3966-186,69C114,4097.1864,89,4149.1864,89,4149.1864Z"/><path
      style={spriteStyle}
      d="M2297,4047.1864s16.1685,56.54,17,74,5,70,5,70L2358,4269c5.5708,18.7392,29.7471,54.4645,29,74l22,128c-.1288,3.37-2.63,35.6821-5.9277,58.4989-3.1291,21.6527-2.8729,40.9064-24.0723,35.5011l-191.0483-55.8116a34.3688,34.3688,0,0,1-9.624-3.3431C2148.81,4490.36,2058.999,4421,2058.999,4421L1966,4353.1864l-40-64,16.6253-223.8245a7.6374,7.6374,0,0,1,8.5048-7.2374s51.42-2.8623,53.87-2.9381Z"/><path
      style={spriteStyle}
      d="M1387,4168l-63,98c1.7036,1.3162,75,131,75,131,10.5,9,25,43.8136,20,98-1.0219,11.0749-22,33-29,86.1864-1.0157,7.7175,1.82,44.22,5,58,6,26,34,61.8136,34,61.8136l202,65.1864,404-11,53-91,7-58s-10.1831-77.24-26-91.1864l-72-92s-87.0488-89.4321-115-110c0,0-11-49.8136-13-57.8136L1887,4189c-1-43.1864-13-88-13-88s-39-56-74-60c0,0-171.9833-20.8136-202-20.8136-38,0-122.1423,67.21-155,81.8136C1425,4110,1387,4168,1387,4168Z"/><path
      style={spriteStyle}
      d="M4261,6356a877.4977,877.4977,0,0,1-136,7c-73.4432-2.0872-226.7695-8.35-237-12-4.4371-1.7989,0-57,5-70,3.2108-8.3481,29-48,29-48,2.9633-2.4245,61-32.95,61-54,0-49-14-100-14-100s-61-57-67-94c3-48-12.1314-123.2225-12.1314-123.2225L3919,5721c20.064-40.2324,33.1591-49.52,62-66,28-16,54.0712-15.67,82-17,27.8034-1.324,21.37,4.1979,39.9783-.43,31.1884-7.756,39.0348-11.9307,91.4077-4.2655,62.4777,9.1441,93.9182-7.3237,120.782-3.1877,29.7582,4.5815,192.5563-4.2524,214.832,4.8829,22.2637,9.1563,9.9322,22.3711,13.5437,44.837,3.0045,16.7288-16.4931,28.1786-26.5437,47.163-9,17-34,67-50,72-12.0294,3.7592-46.376,63.8493-46,74,0,0,17.9673,36.2375,29,48,12.6587,13.496,26.0941,23.8487,27.5477,26.287q6.7716,11.3746,11.7373,43.1174c3.3,21.1653,5.9673,119.2963,5.9673,160.0643q0,9.408.45,20.3892.4438,10.9812,1.3558,21.952.8877,14.8986,1.8057,29.0106c0,6.7964-9.8637,37.18-18.8637,79.18-3.0129,14.06-62.724,54.5032-81.98,56.0764-4.8253.514-54.9315-1.098-60.3487-.8253q-8.1258.3933-16.2516.3881C4285.305,6356.6392,4261,6356,4261,6356Z"/><path style={spriteStyle} d="M4979,5644l4,243,32,112,78,206-12,144-128,10c-27.15-5.96-140.0929-3.4341-167.7194-.3645L4778,6359l-243-17,9-380,52-323,223.0929-3.0968c18.7809-2.5968,37.8614-6.0784,56.4735-2.4654l43.8441,8.5109Z"/><path
      style={spriteStyle}
      d="M7592.0072,6020.0556,7640.3006,6101c2.6008,4.364,16.7009,31.0679,16.82,36.72L7662,6369l-515-7.019L7162.19,6266l-11.573-336,4.34-212.0106L7162.9129,5664s25.316-22,49.9087-24c.3014-.0245,18.779.4123,28.2093,0,73.2519-3.2028,165.8787-10,168.1962-10,22.54,0,91.0251-.6548,91.0251-.6548l58.4545,8.9865,6.8709,196.2219Z"/><path
      style={spriteStyle}
      d="M6709.1365,5641.7347c-15.9427.2-85.3481,3.1363-100.51,7.3427-30.4788,8.4557-70.5625,14.267-81.626,39.9226s-37.8639,72.7249-40,100c-.8728,11.1444,4.2027,56-8,126-1.3487,7.7365,35.0274,122.5881,42,128,22.5126,17.4735,42,102,42,102l-10,75c-27.2025,24.9012-86.0773,45.1929-103.0419,61.3259a35.9043,35.9043,0,0,0-8.08,11.18L6428,6366s81.95,2.1445,117.8335,3.047C6663.24,6372,6667.0309,6361,6776.7624,6361c17.6262,0,107.113,8,128.6178,8,10.4969,0,48.5734-3.1673,65.27-7,12.875-2.9554,162.35-3,162.35-25,0-109.0084-18-354-18-354s-33.1071-69.2283-45.133-72c-21.6938-5-52.8786-46-52.8786-46-3.1821-21,9.491-110,24.4055-117l19.2164-25.3837a53.3688,53.3688,0,0,0,13.7307-6.94c11.3391-8.08,34.6056-24.6608,34.6073-24.6759,1.3559-12-24.4055-51-24.4055-51-19.7683-1.545-84.0567-7.5707-88.1309-8C6927.2446,5624.7123,6812.1005,5640.4424,6709.1365,5641.7347Z"/><path
      style={spriteStyle}
      d="M9408.291,5546.6392s-40.4159-1.0488-121.251-3.136c-80.1856-2.0872-247.7943,12.1467-258.964,8.4968-4.8162-1.7884-19.9812-63.8277-8.7344-99,11.7726-36.8167,76.2592-50.1572,74.2427-67-1.6912-14.12,5.4718-54.3729-6.5509-71-16.0092-22.14-53.1805-37.58-42.58-53,11.5669-35.43-13.2451-191.2225-13.2451-191.2225l-6.71-124.4485c21.906-40.2324,58.9075-43.1712,88.6186-62.22,43.5612-27.9288,52.3545-23.23,82.8473-24.56,30.3558-1.324,29.0408-5.3507,49.3573-9.9783,34.0516-7.756,42.6183-11.9307,99.7993-4.2655,68.2135,9.1441,97.0813.6763,126.4113,4.8123,32.49,4.5815,120.3973-3.8539,144.718,5.2814q36.4614,13.7344,42.3907,47.4385a498.7252,498.7252,0,0,1,7.3915,57.2346c1.6352,21.4381-5.7211,86.73-11.2961,108.2894l-22.9494,57.06s12.1034,32.2937,17.0322,39.8662q7.3932,11.3746,12.8148,43.1174c3.6034,21.1653,5.4233,84.2963,5.4233,125.0643,0,6.272.1516,13.0684.4912,20.3892q.4847,10.9812,1.48,21.952.9693,14.8986,1.9715,29.0106v63.4961q0,27.4531-7.886,44.3025-7.9026,16.85-25.63,25.4761c-11.8324,5.7476-65.3884,7.408-86.4123,8.9812-5.2684.514-59.9745-1.098-65.889-.8253q-8.8719.3933-17.7436.3881Z"/><path style={spriteStyle} d="M10089,4856l-15,181,18,91,113,299-8,70-73.9334,15.551a105.85,105.85,0,0,1-30.49,2.4458L9985,5512l-246,21-47-358,36-316,17.5213-3.6672a142.9407,142.9407,0,0,1,51.2017-1.3413L9843,4859l121.9385-5.8724L10010,4849Z"/><path style={spriteStyle} d="M10159,5910l41,102,13,353-308-10,1-69-7-42,2-68-42-223-3-323s47.5482-1,52-1h110l106,5,18,50Z"/><path
      style={spriteStyle}
      d="M9485,5635c-13.7852,2.6416-152.3164-8.802-256-4-13.4893.6248-105,7-137,24-22.9069,12.1693-70.262,120.7249-71,148-.3015,11.1444-6.7844,48-11,118-.4659,7.7365,43.5912,111.5881,46,117,7.7773,17.4735,17,77,17,77l1,53c-3,26-46.29,80-54,92,0,0-30,21-28.9965,32,.9994,10.9545-1.2169,3.91-2.4142,36.24S8988,6366,8988,6366s30.4,4.1,42.8291,4.047C9042,6370,9438,6367,9438,6367h57c3.6263,0,136-14,152-17,5.2488-.9841,154,3,154,3s62.5994-234.7032,54-282c-2-11-36-100-36-100s-47.8454-34.2283-52-37c-7.4945-5-16.6155-64-16.6155-64-1.0993-21,12.4631-115,17.6155-122l16.578-40s47.4207-40.98,47.422-41c1.4259-22,0-36,0-36-8.5126-.5126-52.1384-.3864-55.5117-1C9749,5625,9529.8008,5626.415,9485,5635Z"/><path style={spriteStyle} d="M1182,6764l55,98,16,303-263-9-15-64,4-48,10-67,6-222,17-329h96l65,3,9,55Z"/><path
      style={spriteStyle}
      d="M525,6435c-13.7852,2.6416-152.3164-8.802-256-4-13.4893.6248-105,7-137,24-22.9069,12.1693-95.262,109.7249-96,137-.3015,11.1444-8.7844,85-13,155-.4659,7.7365,102.5912,90.5881,105,96,7.7773,17.4735,12,84,12,84l-8,71c-3,26-61.29,62-69,74,0,0-33,9-31.9965,20,.9994,10.9545-1.2169,3.91-2.4142,36.24S28,7166,28,7166s35.5707-1.9475,48-2c11.1709-.0472,402,3,402,3h57c3.6263,0,136-14,152-17,5.2488-.9841,221,7,221,7s78.5994-242.7032,70-290c-2-11-36-100-36-100s-47.8454-34.2283-52-37c-7.4945-5-16.6155-64-16.6155-64-1.0993-21,22.4631-79,27.6155-86l48-41s41.9987-75.98,42-76c1.4259-22-36-36-36-36-8.5126-.5126-52.1384-.3864-55.5117-1C872,6421,569.8008,6426.415,525,6435Z"/><path style={spriteStyle} d="M2465,6882l58,88,10,195-216-7,5-76,4-48,10-67-22-245,18-296h132l9,55Z"/><path
      style={spriteStyle}
      d="M1816.2759,6435c-14.0948,2.6416-155.7377-8.802-261.75-4-13.7923.6248-107.3585,7-140.0773,24-23.4215,12.1693-97.4018,109.7249-98.1564,137-.3083,11.1444-8.9816,85-13.292,155-.4763,7.7365,104.8956,90.5881,107.3585,96,7.9521,17.4735,12.27,84,12.27,84l-8.18,71c-3.0674,26-62.6668,62-70.55,74,0,0-33.7412,9-32.7152,20,1.0219,10.9545-1.2442,3.91-2.4685,36.24s-.6025,37.76-.6025,37.76,36.37-1.9475,49.0782-2c11.4218-.0472,411.03,3,411.03,3h58.28c3.7078,0,139.0548-14,155.4142-17,5.3667-.9841,257.66,4,257.66,4s48.6686-239.7032,39.876-287c-2.0449-11-15.3369-111-15.3369-111s-53.01-36.2283-57.2578-39c-7.6629-5-34.3706-51-34.3706-51-1.124-21,41.3719-59,46.64-66L2289,6551s13.9665-91.98,13.9678-92c1.4579-22-47.0333-32-47.0333-32-8.7038-.5126-53.3095-.3864-56.7586-1C2171.07,6421,1862.083,6426.415,1816.2759,6435Z"/>
    </g>
    </svg>
  )
}

export default Sprite