import React from 'react'
import {Link} from 'gatsby'

import homeIcon from '../img/home-icon.svg'
import infoIcon from '../img/info-icon.svg'

const Navbar = (props) => (
  <nav>
    <div className={`nav-wrapper ${props.visible ? '' : 'hidden'}`}>
      <div className={`sticky-nav ${props.isFirstLoad ? 'first-load' : ''}`}>
        <Link className="nav-link animate-hover" activeClassName="active" to="/">
          <img className="nav-link-icon" alt="home-icon" src={homeIcon}/>
          <span className="nav-link-text">Work</span>
        </Link>

        <Link className="nav-link animate-hover" activeClassName="active" to="/about">
          <img className="nav-link-icon" alt="info-icon" src={infoIcon}/>
          <span className="nav-link-text">About</span>
        </Link>

        <a className="nav-link animate-hover instagram-link" href="https://www.instagram.com/studiolouise.co/" target="_blank">
          Instagram
        </a>
      </div>
    </div>

    <Link to="/" id="logo" title="Logo" className={`animate-hover ${props.isFirstLoad ? 'first-load' : ''}`}>
      <div id="nav_logo">
        <span className="logo-studio">Studio </span>
        <span className="logo-louise">LOUISE</span>
      </div>
    </Link>
  </nav>
)

export default Navbar
